import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { DEVICE, FOOTER_HEIGHT, HEADER_HEIGHT } from "../../../utilities/constants";

export const ShareContainer = styled.div<{ bgColor?: string }>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #7aba56;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-left: 40px;
  cursor: pointer;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 16px;
  color: #000000;
`;

export const ProfileIcon = styled.img`
  margin-right: 12px;
  width: 60px;
  height: 60px;
`;
export const PatientName = styled.div`
  font-size: 24px;
  margin-top: 24px;
  color: #0099cc;
  margin-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  white-space: nowrap;
`;

export const PatientGender = styled.div`
  font-size: 16px;
  color: #808080;
  margin-top: 35px;
  margin-left: 30px;
`;

export const PatientDob = styled.div`
  font-size: 16px;
  color: #808080;
  margin-top: 20px;
  margin-left: 30px;
`;

export const SideNavbar = styled.div`
  width: 300px;
  background-color: #fff;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  overflow: hidden;
  border-radius: 1rem;
  padding: 0.5rem;
  margin-left: 1rem;
`;

export const ArrowBackImage = styled.img`
  margin-right: 10px;
`;

export const PhoneNumber = styled.span``;

export const PatientEmail = styled.div `
  font-size: 18px;
  color: #0099cc;
  margin-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  white-space: nowrap;
`;