import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL, HEADER_HEIGHT, FOOTER_HEIGHT } from "../utilities/constants";
import styled from "styled-components";
import { Modal, Grid } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store/store";
import AddRecords from "../components/common/AddRecordsModal/AddRecords";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import AddIcons from "../assets/add-white-icon.svg";
import SucessIcons from "../assets/success-icon.svg";
import CloseIcons from "../assets/close-icon.svg";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { GlobalWorkerOptions, PDFDateString } from "pdfjs-dist";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ScanDeleteSure from "../components/common/Dialog/ScanDeleteSure";
import PatientReportPdf from "./PatientReportPdf";
import Breadcrumb from "../components/common/Breadcrumb/Breadcrumb";
import { ReportObj } from "../components/common/ShareReport/ShareReport.type";
import RecordItem from "../components/common/RecordItem/RecordItem";
import {
  getFileInfo,
  deleteStudy,
} from "../apis/report";
import {
  getRecordsAction,
  getThumbnailURL,
} from "../store/actions/records.action";
import ChangeStatusDialog from "../components/common/Dialog/ChangeStatusDialog";
import { toast } from "react-toastify";
import { resetState } from "../store/slices/records.slice";
import { resetThumbnail } from "../store/slices/thumbanils.slice";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
`;
export const Container = styled.div`
  flex: 1;
  margin-left: 24px;
  padding: 0 24px;
  // background-color: #FFFFFF;
  border-radius: 12px;
  // box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  overflow-y: auto;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
`;

export const ListWrapper = styled.div`
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 20px 0;
`;
export const ListSection = styled.div`
  width: 98%;
  height: 50px;
  // background-color: #ebf7fb;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
export const ListDateTitle = styled.div`
  display: flex;
  flex: 0.4;
  font-weight: bold;
  color: #0099cc;
  font-size: 20px;
`;

export const CloaseIcon = styled.img`
  width: 40px;
  margin-right: -20px;
`;
export const SucessIcon = styled.img`
  margin-right: 20px;
`;
export const CloseButton = styled.button`
  background: none;
  border: none;
  margin-left: auto;
  cursor: pointer;
`;
export const AlertToast = styled.div`
  width: 70%;
  background-color: #d4e8ca;
  border-radius: 12px;
  height: 48px;
  align-items: center;
  display: flex;
  padding: 0 20px;
`;
export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledModalContent = styled.div`
  margin: 20px;
  background-color: #fff;
  outline: none;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  padding: 5px;
`;
export const CardsContainer = styled.div`
  // display: grid;
  // grid-gap:15;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // margin: 20px 0;

  display: grid;
  gap: 12px;
  padding: 24px 15px;
  background-color: rgb(255, 255, 255);
  grid-template-columns: repeat(6, 1fr);
  .report-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    border: none;
    flex: 1 1 calc(20% - 12px);
    max-width: 150px;
    height: auto;
  }
`;

export const ViewAllCard = styled.div`
  .date {
    margin-top: 4px;
    background: #f5fbfc;
    width: 40%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
  .dec {
    margin-top: 10px;
    background: #f5fbfc;
    width: 80%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
  .title {
    background: #f5fbfc;
    width: 80%;
    margin-bottom: 10px;
    height: 18px;
    display: block;
    border-radius: 4px;
  }
`;
export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  max-width: 110px;
  height: auto;
`;

const SelectedCard = styled.div`
  width: 48%;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  border: 1px solid #0099cc;
  margin-bottom: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
`;

const ListItemPersonLogo = styled.div`
  font-size: 13px;
`;
const ListItemPersonDetail = styled.div`
  align-items: center;
  font-size: 13px;
  margin-left: 8px;
`;
const ListItemValue = styled.div`
  font-size: 16px;
`;
const Name = styled.div`
  font-size: 16px;
  color: #0099cc;
  margin-bottom: 4px;
  font-weight: bold;
`;

const SeeMoreText = styled.span`
  font-size: 12px;
  color: #0099cc;
`;

const ListItemDate = styled.div`
  color: #808080;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 10px;
`;

type ListItemBoldProps = {
  breakLongWords?: boolean;
};

export const ListItemBold = styled.span<ListItemBoldProps>`
  font-size: 16px;
  margin-top: 10px;
  color: #000000;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) =>
    props.breakLongWords &&
    `
    overflow-wrap: break-word;
    word-break: break-all;
  `}
`;
export const ThumbImage = styled.img`
  width: 80%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 24px;
  color: #0099cc;
  font-weight: 600;
`;
export const AddButton = styled.button`
  min-width: 148px;
  height: 44px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

export const ViewButton = styled.button`
  width: 96px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0099cd;
  outline: none;
  border-style: hidden;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
  background-color: transparent;
`;

export const SideNavbar = styled.div`
  width: 250px;
  // background-color: #EBF7FB;
  background-color: #fff;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  overflow: hidden;
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    width: 100%;
    min-height: 18px;
  }
  .menubtn {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
  }
`;

export const MenuIconsDiv = styled.div`
  position: absolute;
  z-index: 99;
  right: 0px;
  top: 4px;
`;
export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #7aba56;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-left: 40px;
  cursor: pointer;
`;

export const CardBox = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const ThumbText = styled.div`
  position: absolute;
  bottom: 0;
  background: #30aed7;
  display: flex;
  justify-content: space-between;
  right: 0;
  color: #fff;
  left: 0;

  span {
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .format {
    background: #0099cc;
    color: #fff;
    font-size: 13px;
    min-width: 26px;
  }
`;

export const Dropdownlist = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  min-width: 120px;
  cursor: pointer;
`;

export const ThumbImages = styled.div`
  position: relative;
  background: #ebf7fa;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 175px;
  align-items: center;
`;

export const EmptyListMessage = styled.div`
  margin: 1rem;
  color: red;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const ListItemTop = styled.div`
  color: #404040;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 5px;
`;
const SeeMoreWrapper = styled.div`
  margin: 2rem 0;
  background-color: #ebf7fb;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 90px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const breadCrubLinks = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Patients",
    href: "/patients",
  },
  {
    label: "Health Data",
  },
];
interface BreadcrumbLink {
  label: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
const Records = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [showAddRecordsModal, setShowAddRecordsModal] = useState(false);
  const [isAddRecordLoading, setIsAddRecordLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [shareSuccess, setShareSuccess] = useState<any>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isShowDeleteInstance, setShowDeleteInstance] = useState(false);
  const [isShowDownload, setShowDownload] = useState(false);
  const [isDownLoading, setDownlading] = useState(false);
  const [downloadingData, setDownloadingData] = useState({});
  const [downloadingImageData, setDownloadingImageData] = useState({});
  const [scansAuthToken, setScansAuthToken] = useState("");
  const [isShowhangeStatus, setShowChangeStatus] = useState(false);
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const dispatch = useAppDispatch();
  const selectedPatientId = useSelector(
    (state: any) => state?.patient?.selectedPatientId
  );
  const accordionData = useSelector((state: any) => state?.record?.records);
  const [isPDf, setIsPdf] = useState(false);
  const [tempAccordionData, setTempAccrodionData] = useState({});
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);

  const studyDownloadRef: any = useRef<HTMLDivElement>();
  const [storeData, setStoreData] = useState<ReportObj | null>(null);
  const thumbnails = useSelector((state: any) => state?.thumbnail?.thumbnails);
  useEffect(() => {
    if(accordionData && Object.keys(accordionData)?.length && (selectedPatientId !== accordionData?.patient_id)) {
      dispatch(resetState());
      dispatch(resetThumbnail());
      fetchReportsData();
    }else if(accordionData && Object.keys(accordionData)?.length && (selectedPatientId === accordionData?.patient_id)){
      fetchReportsData(true);
    }else {
      fetchReportsData();
    }
  }, [selectedPatientId]);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const fetchReportsData = async (isLoading= false) => {
    const patientId = getSearchParam("name");
    !isLoading && setIsLoading(true);
    try {
      if (patientId) {
        await fetchByType({ selectedPatientId: patientId, type: "scans", limit: 5});
        await fetchByType({ selectedPatientId: patientId, type: "report", limit: 5});
      }
    } catch (error) {
      console.error("Error fetching data report and scan data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchByType = async ({
    selectedPatientId,
    type,
    limit,
  }: {
    selectedPatientId: string;
    type: string;
    limit: number;
  }) => {
    try {
      const action: any = await dispatch(
        getRecordsAction({
          patient_id: selectedPatientId,
          report_type: type,
          limit: limit,
        })
      );
      if (action?.payload?.data?.status === "success") {
        setTempAccrodionData((prev) => ({
          ...prev,
          [type]: action?.payload?.data?.data,
        }));
      } else {
        console.log("something went wrong");
      }
    } catch (err) {
      console.log("error occured while fetching data.", err);
    }
  };
  const extractIdAndFilePath = () => {
    const result: any[] = [];
    Object.keys(accordionData)?.length &&
      Object.keys(accordionData)?.forEach((category) => {
        if (category === "scans" || category === "patient_id") return;
        accordionData[category]?.data.length &&
          accordionData[category].data.forEach((item: any) => {
            if (item.id && item.file_path) {
              result.push({
                id: item.id,
                file_path: item.file_path,
                record_type: category,
              });
            }
          });
      });
    return result;
  };
  const fetchThumbnailImages = async () => {
    const data = extractIdAndFilePath();
    const filePaths = data
      .filter((item) => !(thumbnails && thumbnails[item.id]))
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnailURL({
          file_objects: filePaths,
        })
      ).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };

  useEffect(() => {
    if (tempAccordionData && Object.keys(tempAccordionData).length === 2) {
      fetchThumbnailImages();
    }
  }, [tempAccordionData]);

  const handleScanClick = (fhirId: string) => {
    navigate("scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  const onSendRecords = (
    payload_scans: any,
    payload_report: any,
    token: string,
    mediaArray: any,
    uppyResponse: string
  ) => {
    const requests = [];
    setIsAddRecordLoading(true);

    if (uppyResponse) {
      requests.push(
        axios.post(`${API_URL}/add_data/scans/translodit/faster-send`, payload_scans, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
    }

    if (mediaArray.length > 0) {
      requests.push(
        axios.post(`${API_URL}/add_data/report/add`, payload_report, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
    }

    Promise.all(requests)
      .then((responses) => {
        responses.forEach((response) => {
          if (response && response.data && response.data.status === "success") {
            console.log(response.data.message);
          } else {
            alert("Something went wrong!");
          }
        });
        setTimeout(() => {
          setIsAddRecordLoading(false);
          setShowAddRecordsModal(false);
          fetchReportsData();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);

        setTimeout(() => {
          setIsAddRecordLoading(false);
          setShowAddRecordsModal(false);
        }, 3000);
      });
  };

  const fetchUrlForFile = async (item: any) => {
    setOpen(true);
    setIsLoading(true);
    setFileUrl(null);
    setFileType(item?.content_type);
    const payload = {
      file_path: item?.file_path,
    };
    try {
      const response = await getFileInfo(payload);
      if (response && response?.data?.status === "success") {
        setFileUrl(response.data?.data);
      } else {
        console.log(response.status);
      }
    } catch (err) {
      console.log("error occured while fetching file info", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(-1);
  };

  const onShareScans = () => {
    navigate("share", {
      state: {
        data: storeData,
      },
    });
  };

  const onChangeStatus = () => {
    setShowChangeStatus(true);
  };

  const onStatusAvailable = (status: string) => {
    console.log("status");
    if (status === "available") {
      setIsStatusChangeLoading(true);
      const patientId = getSearchParam("name") || "";
      const token = cookies["access_token"];

      const Url = `${API_URL}/add_data/status/update`;
      let data = {
        record_type: storeData?.record_type,
        id: storeData?.id,
        status: "AVAILABLE",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(Url, data, config)
        .then((response) => {
          if (response && response.data && response.data.status === "success") {
            storeData?.record_type &&
              (storeData?.record_type === "scan"
                ? fetchByType({ selectedPatientId, type: "scans", limit: 5 })
                : fetchByType({
                    selectedPatientId,
                    type: storeData.record_type,
                    limit: 5,
                  }));
            setIsStatusChangeLoading(false);
            setShowChangeStatus(false);
          } else {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
            alert("Something went wrong!");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.message || "Something went wrong!",
            {
              position: "bottom-right",
              autoClose: 1000,
            }
          );
          setAnchorEl(null);
          setSelectedDoctor(null);
          setShowChangeStatus(false);
          setIsStatusChangeLoading(false);
        });
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };

  const onDeleteStudy = () => {
    setAnchorEl(null);
    setShowDeleteInstance(true);
  };

  const onDeleteStudyById = async () => {
    if (!storeData?.id) return;
    setIsDeleteLoading(true);
    try {
      const response = await deleteStudy(storeData?.id);
      if (response && response?.data && response?.data?.status === "success") {
        await fetchByType({ selectedPatientId, type: "scans", limit: 5 });
        setIsDeleteLoading(false);
        setShowDeleteInstance(false);
        toast.success(`Successfully deleted scan ${storeData?.patient_name}`, {
          position: "bottom-right",
          autoClose: 500,
        });
      } else {
        setShowDeleteInstance(false);
        alert("Something went wrong!");
      }
    } catch (err) {
      console.log("Error occured while deleting study", err);
      setAnchorEl(null);
      setSelectedDoctor(null);
    }
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function displayProtectedImage(imageUrl: any, token: any) {
    const responses = await fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Image/jpeg",
      },
    });
    let dataaaa = await responses.blob();
    return dataaaa;
  }

  const onDownload = async () => {
    setShowDownload(true);
    fetchDataForDownload();
  };

  const fetchDataForDownload = () => {
    setDownlading(true);
    const token = cookies["access_token"];
    const Url = `${API_URL}/list_data/study/pamphlet?study_fhir_id=${storeData?.id}`;
    const scansImagesUrl = `${API_URL}/list_data/frame_urls?study_fhir_id=${storeData?.id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          setDownloadingData(response?.data?.data);

          axios.get(scansImagesUrl, config).then(async (responseData) => {
            if (
              responseData &&
              responseData.data &&
              responseData.data.status === "success"
            ) {
              setScansAuthToken(responseData.data.data.auth_token);
              const frames = await Object.keys(
                responseData.data.data.frame_urls
              )
                ?.slice(0, 2)
                .map((frame: any) => responseData.data.data.frame_urls[frame]);

              const fetchFrameImages = async () => {
                const framesPromise = frames.map(async (frameArray) => {
                  const fetchProtectedImage = frameArray.map(
                    async (itemUrl: any) => {
                      let url = await displayProtectedImage(
                        `${itemUrl}`,
                        responseData.data.data.auth_token
                      );
                      let base64 = await blobToBase64(url);
                      return base64;
                    }
                  );
                  const fetchProtectedImageArr = await Promise.all(
                    fetchProtectedImage
                  );
                  return fetchProtectedImageArr;
                });
                return await Promise.all(framesPromise);
              };

              fetchFrameImages().then((fetchProtectedImageArr: any) => {
                setDownloadingImageData(fetchProtectedImageArr);
                setIsPdf(true);
                setTimeout(() => {
                  studyDownloadRef.current.onDownload();
                  setTimeout(() => {
                    setShowDownload(false);
                  }, 2000);
                }, 3000);
              });
            } else {
              setIsPdf(true);
              setTimeout(() => {
                studyDownloadRef.current.onDownload();
                setShowDownload(false);
                setAnchorEl(null);
              }, 3000);
            }
          });
        } else {
          setShowDownload(false);
          setDownlading(false);
          setAnchorEl(null);
          setDownloadingData({});
          setIsPdf(false);

          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowDownload(false);
        setIsPdf(false);

        setDownlading(false);
        setAnchorEl(null);
        setDownloadingData({});
        // setIsReportsLoading(false);
      });
  };

  const menuItemsWithStatusChangde = [
    { label: "Share", onClick: () => onShareScans() },
    { label: "Change Status", onClick: () => onChangeStatus() },
    ...(storeData?.record_type !== "report"
      ? [
          { label: "Info Page", onClick: () => onDownload() },
          { label: "Delete", onClick: () => onDeleteStudy() },
        ]
      : []),
  ];

  const menuItems = [
    { label: "Share", onClick: () => onShareScans() },
    ...(storeData?.record_type !== "report"
      ? [
          { label: "Info Page", onClick: () => onDownload() },
          { label: "Delete", onClick: () => onDeleteStudy() },
        ]
      : []),
  ];

  return (
    <MainContainer>
        <Container>
          <Breadcrumb links={breadCrubLinks} />
          <>
            <HeaderWrapper>
              <Header>Health Data</Header>
              <div style={{ display: "flex", gap: "10px" }}>
                <AddButton onClick={() => setShowAddRecordsModal(true)}>
                  <img src={AddIcons} style={{ width: "20px" }} />
                  Add New
                </AddButton>
              </div>
            </HeaderWrapper>
            {/* scans   */}

            <ListWrapper>
              <ListSection>
                <ListDateTitle>
                  Scans{" "}
                  {accordionData && Object.keys(accordionData)?.length && accordionData["scans"]?.total
                    ? `(${accordionData["scans"].total})`
                    : ""}
                </ListDateTitle>
              </ListSection>
              {isLoading ? (
                <SimpleLoader />
              ) : (
                <Grid container gap={2} mt={3}>
                  <>
                    {accordionData && Object.keys(accordionData)?.length && accordionData["scans"]?.data?.length > 0 ? (
                      <>
                        {accordionData["scans"].data
                          ?.slice(0, 5)
                          .map((row: any, index: number) => (
                            <RecordItem
                              reportType="scan"
                              fetchUrlForFile={() => handleScanClick(row?.id)}
                              setStoreData={setStoreData}
                              menuItems={
                                row?.status === "RESTRICTED"
                                  ? menuItemsWithStatusChangde
                                  : menuItems
                              }
                              row={row}
                              index={index}
                              thumbnailData={thumbnails}
                            />
                          ))}
                        {accordionData && accordionData["scans"]?.total > 5 && (
                          <>
                            <ListItemWrapper key="see-more">
                              <SeeMoreWrapper
                                onClick={() =>
                                  navigate(
                                    `/allreports?name=${getSearchParam(
                                      "name"
                                    )}&type=scans`
                                  )
                                }
                              >
                                <SeeMoreText>See More</SeeMoreText>
                                <KeyboardArrowRightIcon
                                  sx={{ color: "#0099cc" }}
                                />
                              </SeeMoreWrapper>
                            </ListItemWrapper>
                          </>
                        )}
                      </>
                    ) : (
                      <EmptyListMessage>No records found</EmptyListMessage>
                    )}
                  </>
                </Grid>
              )}
            </ListWrapper>

            <ListWrapper>
              <ListSection>
                <ListDateTitle>
                  Reports {accordionData &&  Object.keys(accordionData)?.length && accordionData["report"]?.total ? `(${accordionData["report"]?.total})` : ""}
                </ListDateTitle>
                {shareSuccess && (
                  <AlertToast>
                    <SucessIcon src={SucessIcons} />
                    Success! Your records are sent.
                    <CloseButton onClick={() => setShareSuccess(false)}>
                      <CloaseIcon src={CloseIcons} />
                    </CloseButton>
                  </AlertToast>
                )}
              </ListSection>
              {isLoading ? (
                <SimpleLoader />
              ) : (
                <Grid container gap={2} mt={3}>
                  {accordionData &&  Object.keys(accordionData)?.length &&
                  accordionData["report"]?.data?.length > 0 ? (
                    <>
                      {accordionData["report"].data.map(
                        (row: any, index: any) => (
                          <RecordItem
                            reportType="report"
                            fetchUrlForFile={() => fetchUrlForFile(row)}
                            setStoreData={setStoreData}
                            menuItems={
                              row?.status === "RESTRICTED"
                                ? menuItemsWithStatusChangde
                                : menuItems
                            }
                            row={row}
                            index={index}
                            thumbnailData={thumbnails}
                          />
                        )
                      )}
                      {accordionData && accordionData["report"].total > 6 ? (
                        <>
                          <ListItemWrapper key="see-more">
                            <SeeMoreWrapper
                              onClick={() =>
                                navigate(
                                  `/allreports?name=${getSearchParam(
                                    "name"
                                  )}&type=reports`
                                )
                              }
                            >
                              <SeeMoreText>See More</SeeMoreText>
                              <KeyboardArrowRightIcon
                                sx={{ color: "#0099cc" }}
                              />
                            </SeeMoreWrapper>
                          </ListItemWrapper>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <EmptyListMessage>No records found</EmptyListMessage>
                  )}
                </Grid>
              )}
            </ListWrapper>

            {/* PACS */}
            <AddRecords
              open={showAddRecordsModal}
              handleClose={() => setShowAddRecordsModal(false)}
              isAddRecordLoading={isAddRecordLoading}
              handleSave={onSendRecords}
            />
            {isPDf && (
              <div style={{ marginTop: "400px" }}>
                <PatientReportPdf
                  ref={studyDownloadRef}
                  data={downloadingData}
                  downloadingImageData={downloadingImageData}
                  setPdf={setIsPdf}
                  scansAuthToken={scansAuthToken}
                />
                `{" "}
              </div>
            )}
          </>
        </Container>

      <StyledModal open={open}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {fileUrl && fileType === "application/pdf" ? (
            <Worker workerUrl={workerUrl}>
              <Viewer fileUrl={fileUrl} />
            </Worker>
          ) : (
            fileUrl && (
              <img
                height={"90%"}
                width={"90%"}
                src={fileUrl}
                style={{
                  objectFit: "contain",
                  padding: "20px",
                }}
              />
            )
          )}
          {!fileUrl && <SimpleLoader />}
        </StyledModalContent>
      </StyledModal>

      {isShowDeleteInstance ? (
        <ScanDeleteSure
          open={isShowDeleteInstance}
          onDelete={() => onDeleteStudyById()}
          isLoading={isDeleteLoading}
          testId={"test"}
          title={"Are you sure you want to delete ?"}
          discription={"This action cannot be undone once deleted."}
          closeHandler={() => {
            setShowDeleteInstance(false);
          }}
        />
      ) : (
        ""
      )}

      {isShowhangeStatus ? (
        <ChangeStatusDialog
          open={isShowhangeStatus}
          onConfirm={(status: string) => onStatusAvailable(status)}
          testId={"test"}
          title={"Change file status"}
          discription={
            "Are you sure you want to change the status of the file?"
          }
          closeHandler={() => {
            setShowChangeStatus(false);
          }}
          isLoding={issStatusChangeLoading}
        />
      ) : (
        ""
      )}

      {isShowDownload ? (
        <ScanDeleteSure
          open={isShowDownload}
          isLoaderPopup={true}
          isLoading={isDownLoading}
          testId={"test"}
          title={""}
          discription={""}
          closeHandler={() => {
            setShowDownload(false);
          }}
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default Records;
