import { FC } from "react";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

export interface Option {
  label: string;
  value: string;
}

interface Props {
  handleChange: (event: SelectChangeEvent<string>) => void;
  selectedStatus: string;
  dropdownOptions: Option[];
  disabled?: boolean;
}
const StatusDropdown: FC<Props> = ({
  handleChange,
  selectedStatus,
  dropdownOptions,
  disabled=false,
}) => {
  return (
    <>
      <FormControl>
        <Select
          value={selectedStatus}
          onChange={handleChange}
          disabled={disabled}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 0,
            width: "215px",
            height: "45px",
            color: "#FFF",
            backgroundColor: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            borderColor: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            },
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem
              key={`dropdown-${option.value}`}
              value={option.value}
              sx={{ width: "215px" }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ width: "215px", whiteSpace: "normal", fontSize: "13px" }}>
          {selectedStatus !== "RESTRICTED"
            ? "(File will be available for the patient and others to view)"
            : "(Only available for clinicians or radiologists to view)"}
        </Box>
      </FormControl>
    </>
  );
};

export default StatusDropdown;
