import { Box, Tooltip } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

interface ExtraStudyInfoProps {
  recordInfo: any;
}

const InfoName = styled.div`
  font-weight: 600;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25ch;
`;

const ExtraStudyInfo: FC<ExtraStudyInfoProps> = ({ recordInfo }) => {
  const uniqueModality = Array.from(
    new Set(recordInfo?.series.map((item: any) => item?.modality))
  );
  return (
    <>
      <Box mt={1}>
        <div>UID</div>
        <Tooltip title={recordInfo?.study_id} arrow>
          <InfoName>{recordInfo?.study_id || "-"}</InfoName>
        </Tooltip>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box>
          <div>Modality</div>
          <InfoName>{uniqueModality.join(",") || "-"}</InfoName>
        </Box>
        <Box>
          <div>Series</div>
          <InfoName>{recordInfo?.series.length || "-"}</InfoName>
        </Box>
      </Box>
      <Box mt={1}>
        <div>Description</div>
        <InfoName>{recordInfo?.description || "-"}</InfoName>
      </Box>
    </>
  );
};

export default ExtraStudyInfo;
