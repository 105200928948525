import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { API_ENDPOINT, COLOR_PRIMARY, COLOR_PRIMARY_LIGHT, DEVICE, TEXT_COLOR_PRIMARY } from "../../../utilities/constants";
import SimpleLoader from "../Loaders/SimpleLoader";
import { setShowStatus } from "../../../store/slices/statusSlice";
import patientReportIcon from "../../../assets/patient-report-icon.svg";
import doctorReportIcon from "../../../assets/doctor-report-icon.svg";
import labUserIcon from "../../../assets/lab-profile-listing.svg";
import { VerticalSep } from "../../styled/Gaps";
import closeIcon from "../../../assets/close-icon.png"

const UploadReportDiv = styled.div`
  min-height: 270px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #808080;
  @media ${DEVICE.laptopL} {
    width: 100%;
  }
`;

const MainHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  text-align: left;
  width: 100%;
  font-weight: 500;
  margin-bottom: 10px;
  @media ${DEVICE.laptop} {
    font-size: 20px;
  }
`;

const ReportUploadActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
`;

const SendReportAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

const SelectionDiv = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 0 0;
  `;

const SelectionDivDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
`;

const SelectionImage = styled.img`
  width: 30px;
  height: 30px;
`;

const SelectionLabImage = styled.img`
  width: 40px;
  height: 40px;
`;

const SelectedNumber = styled.div`
  font-size: 15px;
  color: #000000;
`;

const SelectedName = styled.div`
  font-size: 12px;
  color: #a6b8bb;
  margin-left: 5px;
`;

const RightActions = styled.div`
  display: flex;
  color: #a6b8bb;
  margin-left: auto;
`;


export interface SingleReportDetailsProps {
  open: boolean;
  reportId: string | null;
  closeHandler: () => void;
}

const SingleReportDetails = ({
  open,
  closeHandler,
  reportId,
}: SingleReportDetailsProps) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [singleReportData, setSingleReportData] = useState<any>()

  useEffect(() => {
    fetchSingleReportData()
  }, []);

  const fetchSingleReportData = () => {
    setIsLoading(true);
    const token = cookies["access_token"];
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${API_ENDPOINT.REPORT}/${searchParams.get("reportId")}`

    axios
      .get(url, config)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data
        ) {
          setSingleReportData(response.data.data[0]);
        } else {
          dispatch(
            setShowStatus({
              message: "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 2))
        dispatch(
          setShowStatus({
            message: "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle style={{ justifyContent: "flex-start", color: "#0099CC" }}>
        {singleReportData?.file_name}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
      <IconButton
            edge="end"
            color="inherit"
            onClick={closeHandler}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <img height={40} width={40} src={closeIcon} style={{ }} />
          </IconButton>
          <VerticalSep size={5} />
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}
            >
              <SimpleLoader
                size={20}
              />
            </div>
          ) : (
            <>
              <div style={{ width: "100%", marginTop: 0 }}>
                <ReportUploadActions>
                  {singleReportData?.patient && <>
                    <div
                      style={{
                        textAlign: "left",
                        color: TEXT_COLOR_PRIMARY,
                        marginBottom: "5px",
                      }}
                    >
                      Patient
                    </div>
                    <SelectionDiv>
                      <div style={{
                        backgroundColor: COLOR_PRIMARY_LIGHT,
                        padding: 5,
                      }}>
                        <SelectionImage src={patientReportIcon} />
                      </div>
                      <SelectionDivDetails>
                        <SelectedNumber>
                          {singleReportData?.patient?.contacts[0]?.value || "NA"}
                        </SelectedNumber>
                        <SelectedName>
                          {singleReportData?.patient?.name || "NA"}
                        </SelectedName>
                      </SelectionDivDetails>
                    </SelectionDiv>
                  </>}
                  <VerticalSep size={20} />
                  {singleReportData?.doctors.length > 0 && <>
                    <div
                      style={{
                        textAlign: "left",
                        color: TEXT_COLOR_PRIMARY,
                        marginBottom: "5px",
                      }}
                    >
                      Doctors
                    </div>
                    {singleReportData?.doctors?.map((docItem: any, i: any) => {
                      return (
                        <div>
                          <SelectionDiv>
                            <div style={{
                              backgroundColor: "#EFF7EA",
                              padding: 5,
                            }}>
                              <SelectionImage src={doctorReportIcon} />
                            </div>
                            <SelectionDivDetails>
                              <SelectedNumber>
                                {docItem?.contacts[0]?.value || "NA"}
                              </SelectedNumber>
                              <SelectedName>
                                {docItem?.name || "NA"}
                              </SelectedName>
                            </SelectionDivDetails>
                          </SelectionDiv>
                        </div>
                      )
                    })}
                  </>}
                  {singleReportData?.performers && <>
                    <div
                      style={{
                        textAlign: "left",
                        color: TEXT_COLOR_PRIMARY,
                        marginBottom: "5px",
                        marginTop: "20px",
                      }}
                    >
                      Lab User
                    </div>
                    <SelectionDiv>
                      <div style={{
                        padding: 5,
                      }}>
                        <SelectionLabImage src={labUserIcon} />
                      </div>
                      <SelectionDivDetails>
                        {singleReportData?.performers?.map((performerItem: any) => {
                          return (
                            <>
                              <SelectedNumber>
                                {performerItem?.contacts[1]?.value || "NA"}
                              </SelectedNumber>
                              <SelectedName>
                                {performerItem?.contacts[0]?.value || "NA"}
                              </SelectedName>
                            </>
                          )
                        })}
                      </SelectionDivDetails>
                    </SelectionDiv>
                  </>}
                </ReportUploadActions>
              </div>
            </>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default SingleReportDetails;
