import axios from "axios";
import { createAxiosConfig } from "../utilities/helper";
import { API_URL, API_URL_UPLOAD_REPORTS, API_ENDPOINT } from "../utilities/constants";

export const getReportsList = async ({
  patient_id,
  report_type,
  limit,
  page_token
}: {
  patient_id: string;
  report_type: string;
  limit: number;
  page_token?: string;
}): Promise<any> => {
  try {
    const reportsListUrl = page_token
      ? `${API_ENDPOINT.RECORD}${report_type}?patient_id=${patient_id}&limit=${limit}&page_token=${page_token}`
      : `${API_ENDPOINT.RECORD}${report_type}?patient_id=${patient_id}&limit=${limit}`;
    const response = await axios.get(reportsListUrl, createAxiosConfig());
    return {...response, record_type : report_type, patient_id};
  } catch (error) {
    return error;
  }
};

export const getScansList = async (id: string | null): Promise<any> => {
  try {
    const url = `${API_URL}/list_data/scans/consented?patient_id=${id}&limit=6`;
    const response = await axios.get(url, createAxiosConfig());
    return response;
  } catch (error) {
    return error;
  }
};

export const getPatientDetails = async (id: string | null): Promise<any> => {
  try {
    const url = `${API_URL}/patient/${id}/profile`;
    const response = await axios.get(url, createAxiosConfig());
    return response;
  } catch (error) {
    return error;
  }
};

export const getThumbnails = async ({
  file_objects,
}: {
  file_objects: {
    fhir_id: string | null;
    file_path: string | null;
  }[];
}): Promise<any> => {
  try {
    const url = `${API_ENDPOINT.THUMBNAIL}`;
    const response = await axios.post(
      url,
      { file_objects: file_objects },
      createAxiosConfig()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getFileInfo = async (payload: {
  file_path: string;
}): Promise<any> => {
  try {
    const url = `${API_URL}/patient/health-data/file`;
    const response = await axios.post(url, payload, createAxiosConfig());
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteStudy = async (id: string): Promise<any> => {
  try {
    const url = `${API_URL}/report/imagingstudy/${id}`
    const response = await axios.delete(url, createAxiosConfig());
    return response;
  } catch (error) {
    return error;
  }
};

export const getInfoDetails = async (id: string): Promise<any> => {
  try {
    const url = `${API_URL}/list_data/study?study_fhir_id=${id}`;
    const response = await axios.get(url, createAxiosConfig());
    return response;
  } catch (error) {
    return error;
  }
};