import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UserDropdown from "../components/common/UserDropdown/UserDropdown";
import NoData from "../components/common/UserMessages/NoData";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import BackArrow from "../../src/assets/blue-back-arrow.svg"
import { useSelector, useDispatch } from "react-redux";
import { setShowStatus } from "../store/slices/statusSlice";
import {
  API_URL,
  COLOR_GREY,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  DEVICE,
  COOKIE_EXPIRY_DAYS,
} from "../utilities/constants";
import { Box, Checkbox } from "@mui/material";

const Container = styled.div`
    background-color: ${COLOR_SECONDARY};
    border-radius: 12px;
    flex-grow: 1;
    padding: 30px;
    min-height: 100%;
    box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
    @media ${DEVICE.mobileL} {
        padding: 20px 15px;
    }
`;

const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
`;

const BackArrowImg = styled.img`
    margin-right: 5px;
    cursor: pointer;
`;

const SectionHeader = styled.div`
    color: ${COLOR_PRIMARY};
    font-size: 28px;
`;

const PacsLogsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ServerContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 48px;
`

const LogsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 48px;
`

const ServerInfoHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #0099CC;
    color: #FFFFFF;
    font-size: 20px;
    padding: 12px 40px;
`

const ServerInfoContent = styled.div`
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #F5FBFC;
    padding: 18px 40px;
    margin-bottom: 1rem;
`

const LogsHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #0099CC;
    color: #FFFFFF;
    font-size: 20px;
    padding: 12px 40px;
`

const LogsContent = styled.div`
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #F5FBFC;
    padding: 18px 48px;
`

const IPAddress = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const AppEntity = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const Port = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const LogsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const LogsDate = styled.div`
    font-size: 16px;
    color: #404040;
`

const LogsName = styled.div`
    font-size: 16px;
    color: #404040;
`

const LogsStatus = styled.div`
    font-size: 16px;
    color: #404040;
`

const IPText = styled.div`
    font-size: 16px;
    color: #404040;
    min-width: 152px;
`

const CopyButton = styled.div`
    font-size: 16px;
    color: #404040;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
    cursor: pointer;
`

const LogsSize = styled.div`
    font-size: 14px;
    color: #0099CC;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
`

const IPNum = styled.div`
    font-size: 14px;
    color: #0099CC;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
`

const PacsLogs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [pacsData, setPacsData] = useState<any>({});
    const [adapterData, setAdapterData] = useState<any>({});
    const [checked, setChecked] = useState<boolean>(false);


    useEffect(() => {
        fetchPacsInfo();
        getAdapterInfo()
        const cookieValue = cookies["direct_link"];
        if (cookieValue !== undefined) {
          setChecked(JSON.parse(cookieValue));
        }
    }, []);

    const fetchPacsInfo = () => {
        setIsLoading(true)
        const token = cookies["access_token"];
        const url = `${API_URL}/pacs_info`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
            if (response?.data?.status === "success") {
                setPacsData(response?.data?.data);
            }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const getAdapterInfo = () => {
        setIsLoading(true)
        const token = cookies["access_token"];
        const url = `${API_URL}/adapter`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
            if (response?.data?.status === "success") {
                console.log("response",response);
                
                setAdapterData(response?.data?.result);
            }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCopyToClipboard = (text : string) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            dispatch(
                setShowStatus({
                  message: "Copied to clipboard",
                  severity: "success",
                  autoHide: "yes",
                })
            );
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
    };

    const handleDirectLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        let maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
        setChecked(isChecked);
        setCookie('direct_link', JSON.stringify(isChecked), {
            path: "/",
            maxAge: maxAge,
        });
    }

    return(
        <>
            <Box>
                <Header>Configurations Details</Header>
            </Box>
            <VerticalSep size={28} />
            <Container>
                <PacsLogsContainer>
                    {!isLoading ? (
                        <ServerContainer>
                            <ServerInfoHeader>
                                Server Information
                            </ServerInfoHeader>
                            <ServerInfoContent>
                                <IPAddress>
                                    <IPText>IP Address</IPText>
                                    <IPNum>{pacsData?.adapter_ip}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_ip)}>Copy</CopyButton>
                                </IPAddress>
                                <AppEntity>
                                    <IPText>Application Entity No.</IPText>
                                    <IPNum>{pacsData?.adapter_ae_title}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_ae_title)}>Copy</CopyButton>
                                </AppEntity>
                                <Port> 
                                    <IPText>Port</IPText>
                                    <IPNum>{pacsData?.adapter_port}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_port)}>Copy</CopyButton>
                                </Port>
                            </ServerInfoContent>
                            {adapterData && adapterData?.configuration_value ?
                            <>         
                              <ServerInfoHeader>
                                Adapter Details
                            </ServerInfoHeader>
                            <ServerInfoContent>
                                <IPAddress>
                                    <IPText>{adapterData?.configuration_value}</IPText>
                                    <IPNum>{adapterData?.configuration_parameter}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(adapterData?.configuration_parameter)}>Copy</CopyButton>
                                </IPAddress>
                               
                            </ServerInfoContent>
                            </>:
                            <>
                             <ServerInfoHeader>
                             Adapter Details
                         </ServerInfoHeader>
                         <ServerInfoContent>
                             <IPAddress>
                                 <IPText>{"Details Not Configured"}</IPText>
                             </IPAddress>
                            
                         </ServerInfoContent>
                         </>
                            
                        }
                        <>
                        <ServerInfoHeader>
                            Settings
                        </ServerInfoHeader>
                        <ServerInfoContent>
                            <Box display="flex" alignItems="center" pt={1} pb={1}>
                                <div><Checkbox checked={checked} onChange={handleDirectLinkChange}/></div>
                                <Box ml={1}>Allow Direct Link</Box> 
                            </Box>
                        </ServerInfoContent>
                        </>

                            
                        </ServerContainer>
                        ) : <SimpleLoader />
                    }
                    {/* <LogsContainer>
                        <LogsHeader>
                            Logs
                        </LogsHeader>
                        <LogsContent>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                        </LogsContent>
                    </LogsContainer> */}
                </PacsLogsContainer>
            </Container>
        </>
    )

}

export default PacsLogs;