import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import DropdownThreeDotMenu from "../../common/DropdownThreeDot/Dropdown";
import {
  formatTimestamp,
  removeSpecialCharacters,
} from "../../../utilities/helper";
import { FC } from "react";
import { ReportObj, ThumbnailData } from "../ShareReport/ShareReport.type";

const CardHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 2px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    width: 100%;
    min-height: 18px;
  }
  .menubtn {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
  }
`;
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 2px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 12px;
  color: #808080;
`;

interface MenuItems {
  label: string;
  onClick: () => void;
}

interface Props {
  row: ReportObj;
  fetchUrlForFile: (row: string) => void;
  menuItems: MenuItems[];
  setStoreData: (row: ReportObj | null) => void;
  reportType: string;
  index: number;
  thumbnailData: ThumbnailData;
}

const getFileType = (contentType: string): string => {
  if (!contentType) return "";

  const fileTypes: { [key: string]: string } = {
    "application/pdf": "PDF",
    "image/jpeg": "JPEG",
    "image/jpg": "JPG",
    "image/png": "PNG",
  };

  return fileTypes[contentType] || "";
};

const RecordItem: FC<Props> = ({
  row,
  fetchUrlForFile,
  menuItems,
  setStoreData,
  reportType,
  index,
  thumbnailData,
}) => {
  const imageSrc: string =
    (thumbnailData && row?.id && thumbnailData[row.id]) || row?.icon || "";
  return (
    <Grid
      item
      sx={{ maxWidth: 122, cursor: "pointer" }}
      key={`${reportType}-${index}-${row?.id}`}
      onClick={() => row?.id && fetchUrlForFile(row.id)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ paddingBottom: "2px" }}
      >
        <CardHeader
          title={
            (row?.patient_name && removeSpecialCharacters(row.patient_name)) ||
            (row?.name && removeSpecialCharacters(row.name))
          }
        >
          <span>
            {" "}
            {(row?.patient_name && removeSpecialCharacters(row.patient_name)) ||
              (row?.name && removeSpecialCharacters(row.name))}
          </span>
        </CardHeader>
        <DropdownThreeDotMenu
          menuItems={menuItems}
          data={row}
          setStoreData={setStoreData}
          recordType={reportType}
        />
      </Box>
      <Card
        sx={{
          backgroundColor: "#ebf7fb",
          boxShadow: "none",
          borderRadius: "8px",
        }}
      >
          <CardMedia
            component="img"
            height="120"
            image={imageSrc}
            alt={"records-thumbnail"}
            sx={{
              objectFit: "contain",
              padding: "10px 5px",
              boxSizing: "border-box",
            }}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              if (row?.icon) {
                target.src = row.icon;
              }
            }}
          />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              backgroundColor: "#2faed7;",
              color: "white",
              width: "100%",
              padding: "6px 8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "80px",
              fontSize: "12px",
            }}
            title={row?.test_type || row?.modality}
          >
            {row?.test_type || row?.modality}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor: "#0099cc",
              color: "white",
              padding: "6px 8px",
              fontSize: "12px",
              width: "30%",
            }}
          >
            {(reportType === "scan" && "DCM" ) ||
              (row?.content_type && getFileType(row.content_type))}
          </Box>
        </Box>
      </Card>
      <div>
        <ListItem>
          {" "}
          {(row?.study_date && formatTimestamp(row.study_date)) ||
            (row?.upload_date && formatTimestamp(row.upload_date))}
        </ListItem>
      </div>
      {row?.status === "RESTRICTED" && (
        <Box
          sx={{
            backgroundColor: "#CC8552",
            color: "#FFF",
            justifyContent: "start",
            cursor: "auto",
            padding: "0.5rem",
            paddingLeft: "0.8rem",
            borderRadius: "8px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Restricted
        </Box>
      )}
    </Grid>
  );
};

export default RecordItem;
