import { useState, MouseEvent } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ExistingRecipient from "./ExistingRecipient";
import MobileEmailVerification from "./MobileEmailVerification";
import SingleReport from "./SingleReport";
import { useEffect } from "react";
import { patientSelector } from "../../../store/slices/patient.slice";
import { useLocation , useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import { getRecipientInfo } from "../../../apis/reciept";
import { RecipientInfoType } from "./ShareReport.type";
import SimpleLoader from "../Loaders/SimpleLoader";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  font-size: 24px;
  color: #000;
  margin-bottom: 28px;
`;

const ShareReport = ({ recordName = "Share" }) => {
  const [recipientInfo, setRecipientInfo] = useState<RecipientInfoType[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedPatientId } = useSelector(patientSelector);
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();

  const handleBackNavigation = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(-1);
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  const fetchRecipietRecord = async () => {
    setLoading(true);
    let payload = {
      patient_id: selectedPatientId,
      report_type: data?.record_type === 'scan' ? 'scans' :  data?.record_type ,
      resource_id: data?.id,
    };
    try {
      const response = await getRecipientInfo(payload)
      if (response && response?.data?.status === "success") {
        const responseData = response?.data?.result|| [];
        setRecipientInfo(responseData);
      } else {
        console.error("Failed to fetch recipient:");
      }
    } catch (error) {
      console.error("Failed to fetch Recipient:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRecipietRecord();
  }, []);
  return (
    <Grid ml={6} mt={0}>
      <Breadcrumb links={breadCrubLinks} />
      <TopHeader>
        <div>{recordName}</div>
      </TopHeader>

      <Grid container display="flex" gap={3} width={"100%"}>
        <Grid item>
          <SingleReport
            name={data?.name || data?.patient_name || data?.file_name}
            icon={
              data?.thumbnail ||
              (data?.series && data.series[0]?.thumbnail) ||
              data?.icon
            }
            test_type={data?.record_type}
            upload_date={data?.upload_date || data?.last_updated_date}
          />
        </Grid>

        <Grid item sm={9}>
          <MobileEmailVerification
            reportId={data?.id}
            recipientInfo={recipientInfo}
            setRecipientInfo={setRecipientInfo}
            recordType={data?.record_type}
            recordInfo={data}
          />
          <Box mt={3} mb={3} sx={{ border: "1px solid #D7EFF7" }} />
          <Typography mb={3}>Existing recipients</Typography>
          {!loading ? (
            <Grid container spacing={2}>
              {recipientInfo.length ? (
                recipientInfo.map(
                  (profile: RecipientInfoType, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      key={`recipient-info-${index}`}
                    >
                      <ExistingRecipient
                        name={profile?.name}
                        mobile={profile?.phone_number}
                        email={profile?.email}
                      />
                    </Grid>
                  )
                )
              ) : (
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#808080",
                    textAlign: "center",
                    width: "100%",
                  }}
                  mt={3}
                >
                  No recipients found.
                </Typography>
              )}
            </Grid>
          ) : (
            <SimpleLoader />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareReport;
