import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import deleteIcon from "../../src/assets/delete-icon-scan.svg";
import deleteIconGrey from "../../src/assets/delete-icon-scan-grey.svg";

import ViewIcons from "../../src/assets/viewIcons-blue.svg";
import styled from "styled-components";

const rows = [
  {
    Instance: "1",
    InstanceId: "223123.232131234.324.23423",
    Modality: "CET",
    view: true,
    NoFrames: "342342",
    Action: "34",
  },
];
const ViewBtn = styled.button`
  border: 1px solid #30aed7;
  border-radius: 4px;
  padding: 8px 4px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #30aed7;
  font-weight: 500;
background:#fff;
  min-width: 100px;
  font-size: 14px;
  img {
    width: 15px;
  }
`;
export default function SubinstanceTable(props: any) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{width:"20%"}}>Instance No</TableCell>
            <TableCell align="left" style={{width:"15%"}}>Instance Id</TableCell>
            {/* <TableCell align="left" style={{width:"15%"}}> {!props?.isStudyList ? "Modality" :''}</TableCell> */}
            <TableCell align="left" style={{width:"15%"}}></TableCell>
            <TableCell align="left" style={{width:"15%"}}></TableCell>
            <TableCell align="left" style={{width:"15%"}}>No. of Frames</TableCell>
            <TableCell align="left" style={{width:"15%"}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={
                    props?.seletedInstences[props.data[0]?.series_id]?.length ==
                    props?.data?.length
                  }
                  onClick={() =>
                    props?.onAllSelectSeriesInstance(
                      (props?.data?.length && props.data[0].series_id) || ""
                    )
                  }
                />
                {props?.seletedInstences[props.data[0]?.series_id]?.length ? (
                  <img
                    src={deleteIcon}
                    style={{ cursor: "pointer", marginRight: "5px" }}
                    onClick={() =>
                      props?.onDeleteSeriesInstences(
                        (props?.data?.length && props.data[0].series_id) || "",
                        (props?.data?.length && props.data[0].study_id) || ""
                      )
                    }
                  />
                ) : (
                  <img src={deleteIconGrey} style={{ marginRight: "5px" }} />
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data &&
            props.data.map((row: any,index:number) => {
              {
                console.log(
                  props?.seletedInstences[row?.series_id]?.includes(
                    row?.instance_id
                  )
                );
              }

              return (
                <TableRow
                  key={row?.instance_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">
                    <span className="table-count"> {row?.instance_number}</span>
                  </TableCell>
                  <TableCell scope="row">{row?.instance_id}</TableCell>
                  <TableCell align="left">{! props?.isStudyList ? row?.modality :''}</TableCell>
                  <TableCell align="left">
                    <ViewBtn
                    onClick={() => props.openInNewTab ? props.openInNewTab(row?.viewer_url?.basic_viewer):props.fetchViewerUrls(row,"basic","series")}
                    disabled={ props.openInNewTab && !row?.viewer_url?.basic_viewer ?true :false}
                    >
                      <img
                        src={ViewIcons}
                        style={{ cursor: "pointer", marginRight: "5px" }}
                      />
                      View
                    </ViewBtn>
                  </TableCell>

                  <TableCell align="left">{row?.frames_count ||  " - "}</TableCell>

                  <TableCell align="left">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={
                          props?.seletedInstences[row?.series_id]?.includes(
                            row?.instance_id
                          )
                            ? true
                            : false
                        }
                        onClick={() => props?.onSelectSeriesInstance(row)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
