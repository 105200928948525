import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png"
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null 
  closeHandler: () => void;
  onDelete?: () => void;
  isLoading:boolean;
  isLoaderPopup?:boolean

}

const ScanDeleteSure = ({
  open,
  closeHandler,
  title,
  discription,
  onDelete,
  isLoading,
  isLoaderPopup
}: EditTestProps) => {
  const handleSave = () => {
    onDelete ? onDelete() : console.log('')
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ padding: "100px" }}
    >
      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "20px 30px 0",
          fontWeight:"600"
        }}
      >
        {title}
        <img onClick={closeHandler} height={50} width={50} style={{cursor:"pointer" ,  position: "absolute",right:" 0",top: "2px"}} src={closeIcon}  />
      </DialogTitle>
      <DialogContent
        style={{ justifyContent: "flex-start", margin: "0px 30px" }}
      >
        <div style={{marginTop:isLoaderPopup?"59px":""}}>
          {!isLoading ? (
            discription
          ) : (
            <SimpleLoader text={isLoaderPopup ?"Downloading...":"Deleting in progress"} height="100%" size={20} />
          )}
        </div>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "20px 30px",
        }}
      >
        {!isLoaderPopup && <>
        <Button disabled={isLoading} onClick={handleSave} sx={{ color: "#FFF" ,minWidth:"150px",borderRadius:"8px",height:"44px"}} variant="contained">
          Delete
        </Button>
        <Button disabled={isLoading}  onClick={closeHandler} sx={{minWidth:"150px",borderRadius:"8px",height:"44px"}}  variant="outlined">
          Cancel
        </Button>
        </>
}
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default ScanDeleteSure;
