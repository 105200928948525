import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Checkbox } from "@mui/material";
import deleteIcon from "../../src/assets/delete-icon-scan.svg";
import ViewIcons from "../../src/assets/viewIcon-light.svg";
import styled from "styled-components";

const rows = [
 {
  SeriesId: "34.3242.34.2345.2354252.8",
  Description: "CR-chest",
  Modality: "CET",
  view: true,
  Instances: "12",
 },
 {
  SeriesId: "34.3242.34.2345.2354252.8",
  Description: "CR-chest",
  Modality: "CET",
  view: true,
  Instances: "12",
 },{
  SeriesId: "34.3242.34.2345.2354252.8",
  Description: "CR-chest",
  Modality: "CET",
  view: true,
  Instances: "12",
 }

];
const ViewBtn = styled.button`
border: 1px solid #30aed7;
border-radius: 4px;
padding: 8px 4px;
justify-content: center;
display: flex;
align-items: center;
color:#30aed7;
font-weight:600;
background:#fff;
  min-width: 100px;
`
export default function ReviewedSubinstanceTable(props:any) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Series Id</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Modality</TableCell>
            <TableCell align="left"></TableCell>
            {/* <TableCell align="left">Instances</TableCell> */}
           
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data.map((row:any) => (
            <TableRow
              key={row?.series_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
               <TableCell align="left" sx={{maxWidth:"150px", wordBreak:"break-all"}}>
                {row?.series_id}
              </TableCell>
              <TableCell>
                {row?.name}
              </TableCell>
              <TableCell align="left">{row.modality}</TableCell>
              <TableCell align="left"><ViewBtn 
                              onClick={() => props.openInNewTab ? props?.openInNewTab(row?.desktop_viewer_url):props.fetchViewerUrls(row,"basic","series")}
              disabled={ props.openInNewTab && !row?.desktop_viewer_url ?true :false}

              ><img
                    src={ViewIcons}
                    style={{ cursor: "pointer", marginRight: "5px" ,width:"18px"}}
                  />View</ViewBtn></TableCell>
              {/* <TableCell align="left">{row.instances}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}