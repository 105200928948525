import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps {
  options: Option[];
  onChange: (selectedValue: string) => void;
  selectedValue: string;
  label: string;
}

const SelectField: React.FC<SelectFieldProps> = ({ options, onChange, selectedValue, label }) => {
    
  const handleChange = (event: any) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        // disableUnderline={true}
        // inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline" : {
              borderColor : "#fff",
           },
          "&:hover > .MuiOutlinedInput-notchedOutline" : {
              borderColor : "#fff"
           },
         }}
        labelId="select-label"
        id="select"
        value={selectedValue}
        label={label}
        onChange={(e) => handleChange(e)}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
