import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import { VerticalSep } from "../components/styled/Gaps";
import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  DEVICE,
} from "../utilities/constants";
import ReviewModal from "../components/common/ReviewModal/ReviewModal";
import DownArrow from "../../src/assets/down-arrow-circle.svg";
import UpArrow from "../../src/assets/up-arrow-circle.svg";
import ViewIconsBlue from "../../src/assets/viewIcons-blue.svg";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import ScanDeleteSure from "../components/common/Dialog/ScanDeleteSure";
import deleteIcon from "../../src/assets/delete-icon-scan.svg";
import deleteIconGrey from "../../src/assets/delete-icon-scan-grey.svg";
import ReviewedSubinstanceTable from "./ReviewedSubInstance";
import SubinstanceTable from "./SubInstance";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PatientReportPdf from "./PatientReportPdf";
import InfoDialog from "../components/common/Dialog/InfoDialog";
// const ArrowImageInstance = styled.img`
//   width: 12px;
//   height: 12px;
//   padding: 8px;
//   cursor: pointer;
// `;
const Container = styled.div`
  .MuiCheckbox-colorPrimary svg {
    fill: #0099cc;
  }
  // background-color: ${COLOR_SECONDARY};
  border-radius: 12px;
  flex-grow: 1;
  padding: 30px;
  min-height: 600px;
  // box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 400px;
  align-items: center;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ViewerButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 120px;
  height: 33px;
  border: none;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

const AddButton = styled.button`
  background-color: #31afd7;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 120px;
  max-width: 400px;
  height: 33px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  margin-top: 2%;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

const SectionHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: left;
  }
`;
const ViewBtn = styled.button`
  border: 1px solid #30aed7;
  border-radius: 4px;
  padding: 8px 4px;
  justify-content: center;
  cursor: pointer;
  display: flex;
  min-width: 100px;
  background: transparent;
  align-items: center;
  color: #30aed7;
  font-weight: 600;
  max-width: 90px;
`;

const AddTempWrapper = styled.div`
  // margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const ReviewButtonContainer = styled.div`
  // margin-left: 10px;
  display: flex;
  gap: 4px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const ButtonBase = styled.button<{ active: boolean }>`
  border-radius: ${({ active }) =>
    active ? "8px 8px 0px 0px" : "8px 8px 8px 8px"};
  font-size: 16px;
  width: 200px;
  height: 46px;
  // border: ${({ active }) => (active ? "none" : "1px solid #7ABA56")};
  // background-color: ${({ active }) => (active ? "#7ABA56" : "#FFFFFF")};
  // color: ${({ active }) => (active ? "#FFFFFF" : "#7ABA56")};

  border: ${({ active }) => (active ? "none" : "1px solid #fff")};
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#d7eff7")};
  color: ${({ active }) => (active ? "#30aed7" : "#000")};
  cursor: pointer;
`;

const ViewerButtonMenu = styled.button`
  background-color: #d7eff7;
  border: none;
  padding: 3px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  min-width: 130px;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  &:hover {
    img {
      transform: rotate(180deg);
    }
    .custom-modal {
      display: block;
    }
  }
  .custom-modal {
    background: #fff;
    width: 100%;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;
const ViewerButtonBase = styled.button`
  border-radius: 8px;
  font-size: 16px;
  width: 145px;
  height: 46px;
  border: none;
  background-color: #7aba56;
  color: #ffffff;
  cursor: pointer;
`;

const ListAddTempWrapper = styled.div`
  // margin-top: 20px;
`;

const ListReviewButtonContainer = styled.div`
  // display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  background: #ebf7fa;
  width: auto;
  display: inline-flex;
  padding: 5px 8px;
  border-radius: 29px;
`;
const ReviewedShowsubinstance = styled.div`
  width: 100%;
  padding: 10px 10px 30px;

  table td .MuiCheckbox-colorPrimary svg {
    fill: #0099cc;
  }
  .MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
  }
  table tr {
    vertical-align: top;
  }
  table {
    border: none;
  }
  table td {
    border-bottom: 1px solid #d5eef5;
    padding: 10px 10px;
    background: #f5fbfc;
    font-size: 14px;
  }
  thead th {
    padding: 10px 10px;
    color: #fff;
    background: #0099cc;
    font-weight: 600;
  }
`;

const ListButtonBase = styled.button<{ active: boolean }>`
  border-radius: 25px;
  font-size: 16px;
  width: auto;
  height: 35px;
  // border: ${({ active }) => (active ? "none" : "1px solid #2FAED7")};
  // background-color: ${({ active }) => (active ? "#2FAED7" : "#FFFFFF")};
  // color: ${({ active }) => (active ? "#fff" : "#2FAED7")};
  padding: 4px 40px;
  border: none;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "transparent")};
  color: ${({ active }) => (active ? "#2FAED7" : "#000")};
  cursor: pointer;
`;

const PacsButton = styled.button`
  background-color: #0099cc;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  width: 172px;
  height: 46px;
  border: none;
  cursor: pointer;
`;

const ParentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #d7eff7;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 20px 0;
  gap: 5px;
  width: 100%;
`;

const ScansThumbnail = styled.img`
  width: 80px;
  height: 96px;
`;

const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  min-width: 200px;
  max-width: 200px;
  word-wrap: break-word;
`;

const PatientName = styled.div`
  font-size: 20px;
  color: #000000;
  word-break: break-all;
`;

const PatientGender = styled.div`
  font-size: 14px;
  color: #808080;
`;

const ThumbnailBox = styled.div`
  background: #d7eff7;
  padding: 10px 6px;
  border-radius: 4px;
  img {
    width: auto;
    height: 54px;
  }
    width: 55px;
`;
const StudyDate = styled.div`
  font-size: 14px;
  color: #808080;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
`;

const UidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
// const ModalityContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   width:10%;
// `;

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SendPatientContainer = styled.div``;
const ModalityContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 10%;
`;

const InstancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 135px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 20%;
  margin-left: 25px;
`;

const SubTableDropdown = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #0099cc;
  padding-left: 11px;
  color: #0099cc;
  justify-content: space-between;
  gap: 15px;
  overflow: hidden;
  align-items: center;
  padding: 6px 8px;
  position: relative;
  padding-right: 43px;
  font-size: 14px;

  span {
    background-color: #0099cc;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    bottom: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;
// const TableDropdown = styled.div`
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   border-radius: 5px;
//   background: #30aed7;
//   padding-left: 11px;
//   color: #fff;
//   justify-content: space-between;
//   gap: 15px;
//   overflow: hidden;
//   align-items: center;
//   padding: 6px 8px;
//   position: relative;
//   padding-right: 43px;
//   span {
//     background-color: #0099cc;
//     cursor: pointer;
//     position: absolute;
//     right: 0;
//     top: 0;
//     display: flex;
//     bottom: 0;
//     height: 100%;
//     align-items: center;
//     justify-content: center;
//   }
// `;
const ReviewButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  gap: 10px;
`;
const ViewerButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const UidText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const SeriesText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const SendPatientValue = styled.div`
  border-radius: 5px;
  padding: 6px 0px;
  font-size: 14px;
  color: #0099cc;
  min-width: 30px;
  min-height: 20px;
`;
const SendPatientText = styled.div`
  font-size: 14px;
  color: #000;
`;
const ModalityText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const InstancesText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const ReviewButton = styled.button`
  background-color: #7bba55;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 100px;
  // height: 40px;
  height: 33px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
`;

const UIDValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  max-width: 220px;
  min-width: auto;
  min-height: 20px;
  word-wrap: break-word;
  word-break: break-all;
`;

const SeriesValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 20px;
  min-height: 20px;
`;

const ModalityValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 30px;
  min-height: 20px;
  word-break: break-all;
`;

const InstancesValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 20px;
  min-height: 20px;
`;

const DescriptionValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 140px;
  min-height: 20px;
`;
const SentValue = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 140px;
  min-height: 20px;
`;
const SeriesList = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 32px #2477b314;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #d7eff7;
  border-radius: 8px 8px;
  background-color: #f5fbfc;
  .table-border-Row {
    border-bottom: 1px solid #d7f0f7;
    width: calc(100% - 30px);
    margin: 0 auto 20px;
  }
`;
const SeriesHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
  background-color: #30aed7;
  border-radius: 8px 8px 0px 0px;
`;
const SeriesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 20px;
  border-radius: 0px 0px 8px 8px;
  // border: 1px solid #d7eff7;
  // border-bottom: 1px solid #d7eff7;
  min-height: 15px;
  border-right: 1px solid #d7eff7;
`;
const DescriptionColumn = styled.div<{ isWhite?: boolean }>`
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  font-size: 18px;
  width: 15%;
`;
const ModalityColumn = styled.div<{ isWhite?: boolean }>`
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  font-size: 18px;
  width: 15%;
  max-width: 150;
  padding: 0 15px;
`;
const ViewerColumn = styled.div<{ isWhite?: boolean }>`
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  width: 15%;
  font-size: 18px;
  .button-box {
    height: 65px;
    padding-bottom: 6px;
  }
`;

// export const Box = styled.div``;
// export const Dropdownlist = styled.button`
//   padding: 10px;
//   border: none;
//   text-align: left;
//   cursor: pointer;
//   border-bottom: 1px solid #d7f0f7;
//   min-width: 120px;
//   background: transparent;
// `;

const Showsubinstance = styled.div`
  width: 95%;
  margin: 0 auto 30px;
  table td .MuiCheckbox-colorPrimary svg {
    fill: #0099cc;
  }
  table {
    border: 1px solid #d7f0f7;
  }
  table td {
    max-width: 150px;
    word-break: break-all;
    padding: 7px 0.3%;
    border-bottom: 1px solid #d7f0f7;
    @media ${DEVICE.desktopL || DEVICE.laptopL} {
      padding: 7px 0.6%;
    }
  }
  thead th {
    padding: 5px 0.2%;
    color: #30aed7;
    background: #d7eff7;
    font-weight: 600;
    @media ${DEVICE.desktopL || DEVICE.laptopL} {
      padding: 5px 0.6%;
    }
  }
  .table-count {
    background: #ebf7fa;
    border-radius: 5px;
    padding: 6px 12px;
    color: #30aed7;
    border: 1px solid #d7eff7;
    display: inline-block;
  }
  .MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
  }
`;

// const ViewerButtonMenu = styled.button`
//   background-color: #d7eff7;
//   border: none;
//   padding: 3px 15px;
//   border-radius: 5px;
//   display: flex;
//   justify-content: space-between;
//   gap: 4px;
//   align-items: center;
//   min-width: 130px;
//   position: relative;
//   transition: 0.3s;
//   img {
//     mix-blend-mode: exclusion;
//     width: 9px;
//   }
//   &:hover {
//     img {
//       transform: rotate(180deg);
//     }
//     .custom-modal {
//       display: block;
//     }
//   }
//   .custom-modal {
//     background: #fff;
//     width: 100%;
//     top: 100%;
//     box-shadow: 5px 3px 10px #ddd;
//     left: 0;
//     border-radius: 7px;
//     display: none;
//     position: absolute;
//     overflow: hidden;
//     border: 1px solid #d7f0f7;
//     min-width: 130px;
//   }
// `;

const InstancesColumnBox = styled.div<{ isWhite?: boolean }>`
  font-size: 18px;
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  padding: 0 15px;
  width: 15%;
`;
const InstancesColumn = styled.div<{ isWhite?: boolean }>`
  font-size: 18px;
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  padding: 0 15px;
  width: 15%;
`;
const SeriesColumn = styled.div<{ isWhite?: boolean }>`
  font-size: 18px;
  color: ${({ isWhite }) => (isWhite ? "#FFFFFF" : "#000")};
  width: 20%;
  padding: 0 15px;
`;
const DescriptionSeriesValue = styled.div`
  font-size: 18px;
  color: #000000;
  min-width: 20%;
  border-right: 1px solid #d7eff7;
  padding-bottom: 7px;
  min-height: 24px;
`;
const ModalitySeriesValue = styled.div`
  font-size: 18px;
  color: #000000;
  min-width: 20%;
  padding-left: 15px;
  border-right: 1px solid #d7eff7;
  padding-bottom: 7px;
  min-height: 24px;
`;
const InstancesSeriesValue = styled.div`
  font-size: 18px;
  color: #000000;
  min-width: 20%;
  padding-left: 15px;
  min-height: 24px;
  padding-bottom: 7px;
`;
const TableDropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #30aed7;
  padding-left: 11px;
  color: #fff;
  justify-content: space-between;
  gap: 15px;
  font-size: 14px;
  overflow: hidden;
  align-items: center;
  padding: 7px 5px;
  position: relative;
  padding-right: 43px;
  span {
    background-color: #0099cc;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    bottom: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ReviewedRowContainer = styled.div`
  border-bottom: 1px solid #d7f0f7;
`;
const DescriptionSeriesValueNew = styled.div`
  font-size: 18px;
  color: #000000;
  // width: 20%;
  height: 65px;
  margin-top: 5px;
  padding-bottom: 6px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  .MuiCheckbox-root.MuiCheckbox-root {
    padding: 0px 3px !important;

    @media ${DEVICE.desktopL || DEVICE.laptopL}: {
      padding: 0px
    },
  }
`;

const InstancesSeriesValueCheckbox = styled.div`
  font-size: 18px;
  color: #000000;
  min-width: 25%;
  padding-left: 10px;
  height: 65px;
  margin-top: 5px;

  //   height: 82px;
  padding-bottom: 7px;
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

export const Box = styled.div``;
export const Dropdownlist = styled.button`
  padding: 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #d7f0f7;
  min-width: 120px;
  background: transparent;
`;
const SeriesRowValue = styled.div`
  font-size: 18px;
  color: #000000;
  min-width: 25%;
  padding-left: 15px;
  border-right: 1px solid #d7eff7;
  padding-bottom: 7px;
  min-height: 24px;
`;
const ArrowImage = styled.img`
  width: 12px;
  padding: 8px;
  cursor: pointer;
`;
const ArrowImageInstance = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
`;

const EmptyListMessage = styled.div`
  margin-top: 90px;
  padding: 20px;
  color: red;
  text-align: center;
`;

interface IExpandedItems {
  [key: string]: boolean;
}

const NewScans = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isScansLoading, setIsScansLoading] = useState<boolean>(true);
  const [isSeriesLoading, setIsSeriesLoading] = useState<boolean>(true);

  const [isLoadingDiacom, setIsLoadingDiacom] = useState<boolean>(false);
  const [scansData, setScansData] = useState<any>({});
  const [seriesScanData, setSeriesScansData] = useState<any[]>([]);
  const [seriesScansDataById, setSeriesScansDataById] = useState<any[]>([]);

  const [reviewedScanData, setReviewedScansData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  const [selectedScan, setSelectedScan] = useState<any>(null);
  const [activeButton, setActiveButton] = useState<
    "notReviewed" | "reviewedAndSent"
  >("notReviewed");
  const [activeList, setActiveList] = useState<"seriesList" | "studyList">(
    "studyList"
  );
  const [expandedItems, setExpandedItems] = useState<IExpandedItems>({});
  const [expandedSeriesData, setExpandedSeriesData] =
  useState<any>({});

  const [reviewedInstancesItems, setReviewedInstancesItems] =
    useState<IExpandedItems>({});
  const [expandedInstencesstdyList, setExpandedInstencesstdyList] =
    useState<IExpandedItems>({});
  const [expandedInstencesstdyListdata, setExpandedInstencesstdyListData] =
    useState<any>({});

  const [isSeriesReview, setSeriesReview] = useState(false);
  const [isShowDeleteScan, setShowDeleteScan] = useState(false);
  const [seletAllDelete, setSelectAllDelete] = useState<any>({});
  const [slectedStudyId, setSlectedStudyId] = useState<any>("");
  const [slectedSeriesId, setSlectedSeriesId] = useState<any>("");
  const [slectedRowId, setSlectedRowId] = useState<any>(null);
  const [isStudyDelete, setIsStudyDelete] = useState<boolean>(false);
  const [isSeriesDelete, setIsSeriesDelete] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState("");
  const [isDiacomLoadSuccess, setIsDiacomLoadSuccess] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState<any>('');

  const [isInstanceDeleteLoading, setInstanceDeleteLoading] =
    useState<boolean>(false);

  const [isShowDownload, setShowDownload] = useState(false);
  const [isDownLoading, setDownlading] = useState(false);
  const [downloadingData, setDownloadingData] = useState({});
  const [isPDf, setIsPdf] = useState(false);
  const [downloadingImageData, setDownloadingImageData] = useState({});
  const [scansAuthToken, setScansAuthToken] = useState("");

  const studyDownloadRef: any = useRef<HTMLDivElement>();

  const [viewerMenu, setViewerMenu] = useState<any>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setViewerMenu(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setViewerMenu(null);
  };
  const [subinstance, setSubinstance] = useState<boolean>(false);
  // const [subinstance, setSubinstance] = useState<boolean>(false);

  const onUnload = (event:any) => {
    localStorage.removeItem("isNewAlreadyLoaded")
    localStorage.removeItem("scansNewData") 
  localStorage.removeItem("reviewedScansNewData")
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
  }, []);

  const onRefreshData =()=>{
    if(activeButton == "reviewedAndSent"){
      setIsScansLoading(true);
      Promise.all([
        fetchReviewedData(),
      ]).finally(() => {
        setIsScansLoading(false);
        localStorage.setItem("isNewAlreadyLoaded",JSON.stringify(true))
      });
    }else{
      setIsLoading(true);
      Promise.all([
        fetchNotReviewedData(),
      ]).finally(() => {
        setIsLoading(false);
        localStorage.setItem("isNewAlreadyLoaded",JSON.stringify(true))
      });
    }
  }


  useEffect(() => {
    const isNewAlreadyLoaded = localStorage.getItem("isNewAlreadyLoaded")
    if(isNewAlreadyLoaded == null || (isNewAlreadyLoaded !== null  && !JSON.parse(isNewAlreadyLoaded))){
    setIsLoading(true);
    Promise.all([
      fetchNotReviewedData(),
      fetchReviewedData()
    ]).finally(() => {
      setIsLoading(false);
      setIsScansLoading(false);
      localStorage.setItem("isNewAlreadyLoaded",JSON.stringify(true))
    });
  }else{
   const scansLocalData:any = localStorage.getItem("scansNewData") ||  []
   const reviewedScansLoaclData:any = localStorage.getItem("reviewedScansNewData") ||  []
    setIsLoading(false);
    setIsScansLoading(false);
    setScansData(scansLocalData !== null && scansLocalData.length && scansLocalData ? JSON.parse(scansLocalData):[])
    setReviewedScansData(reviewedScansLoaclData !== null && reviewedScansLoaclData.length && reviewedScansLoaclData ? JSON.parse(reviewedScansLoaclData):[])
  }
  }, []);

  const toggleExpanded = (seriesId: string) => {

if(!expandedItems[seriesId]){
  fetchSeriesDataById(seriesId)
}
setExpandedItems((prevState) => ({
      ...prevState,
      [seriesId]: !prevState[seriesId],
    }));
  };




  const ReviewedInstancesToggle = (studyId: string) => {
    if(!reviewedInstancesItems[studyId]){
      fetchSeriesDataById(studyId)
    }
    setReviewedInstancesItems((prevState) => ({
      ...prevState,
      [studyId]: !prevState[studyId],
    }));
  };
  // const toggleInstanceExpanded=()=>{
  //   setSubinstance(!subinstance)
  // }

  const toggleInstanceExpanded = (seriesId: string, study_id: string) => {
    setExpandedInstencesstdyList((prevState) => ({
      ...prevState,
      [seriesId]: !prevState[seriesId],
    }));

    !expandedInstencesstdyList[seriesId] &&
      fetchReviewedSeriesInstances(seriesId, study_id);
  };

  const formattedDate = (dateString: string) => {
    if (!dateString) {
      return "";
    }
    return moment(dateString).format("DD-MMM-YYYY hh:mm a");
  };

  const openInNewTab = (url: any) => {
    if (url) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  const fetchViewerUrls = async (rowData:any,type:string,listType:string) => {
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/study?country_code=${countryData.code}`;
    const studyUrl =`${API_URL}/unsent/study/${rowData?.study_id}/viewer?country_code=${countryData.code}&store_id=${rowData?.store_id}`
   const seriesUrl =  `${API_URL}/unsent/study/${rowData?.study_id}/series/${rowData?.study_id}/viewer?country_code=${countryData.code}&store_id=${rowData?.store_id}`
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(listType == "series"?seriesUrl :studyUrl, config);
      
      if (response?.data?.status === "success") {
        const result = response?.data?.result;
      if(type == "basic"){
        openInNewTab(result?.basic_viewer)
      }else{
        openInNewTab(result?.tmtv)

      }
      }
    } catch (error) {
      console.error(error);
    }
  };


  const fetchNotReviewedData = async () => {
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/study/filter?country_code=${countryData.code}&sent=false`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      
      if (response?.data?.status === "success") {
        const result = response?.data?.result;
      console.log(
        "result",result
      );

        // const scansArray = Object.values(result);
        setScansData(result);
        localStorage.setItem("scansNewData",JSON.stringify(result))
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSeriesData = async (series_id:any='') => {
    // setIsSeriesLoading(true)
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]

    const url = `${API_URL}/unsent/study/${series_id}/series?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response?.data?.status === "success") {
        setSeriesScansData(response?.data?.result);
        // localStorage.setItem("seriesScansData",JSON.stringify(response?.data?.result))
        // setIsSeriesLoading(false)
      }
    } catch (error) {
      console.error(error);
      // setIsSeriesLoading(false)
    }
  };

  const fetchSeriesDataById = async (series_id:any='') => {
    // setIsSeriesLoading(true)
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]

    const url = `${API_URL}/unsent/study/${series_id}/series?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response?.data?.status === "success") {
        console.log("response@@@@@",response);
        // setSeriesScansDataById(response?.data?.result);
setTimeout(() => {
  setExpandedSeriesData((prevState: any) => ({
    ...prevState,
    [series_id]: response?.data?.result || [],
  }));
  // localStorage.setItem("seriesScansData",JSON.stringify(response?.data?.result))
}, 2000);
        
        // setIsSeriesLoading(false)
      }
    } catch (error) {
      console.error(error);
      // setIsSeriesLoading(false)
    }
  };

  const fetchReviewedData = async () => {
    setIsScansLoading(true);
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/study/filter?country_code=${countryData.code}&sent=true`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response?.data?.status === "success") {
        setReviewedScansData(response?.data?.result);
        localStorage.setItem("reviewedScansNewData",JSON.stringify(response?.data?.result))
        setIsScansLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsScansLoading(false);
    }
  };

  const onSendReport = () => {
    setShowReviewModal(false);
  };

  const openReviewModal = (scanData: any) => {
    let dataToSend = scanData;
    if (activeList === "studyList") {
      let data = scanData;
      data["thumbnail"] = "https://heme-content-files.s3.ap-south-1.amazonaws.com/dicom_mocks/study_thumbnail.png";
      data["study_url"] = scanData?.study_url;
      data["dicom_path"] = scanData?.dicom_path;

      dataToSend = data;
    }
    setSelectedScan(dataToSend);
    setSeriesReview(activeList === "seriesList" ? true : false);
    setShowReviewModal(true);
  };

  const fetchReviewedSeriesInstances = async (
    series_id: string,
    study_id: string
  ) => {
    //  setIsLoading(true);
   const token = cookies["access_token"];
   const countryData = cookies["countryData"]

    const url = `${API_URL}/unsent/study/${study_id}/series/${series_id}/instances?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response?.data?.status === "success") {
        if (response?.data) {
          setExpandedInstencesstdyListData((prevState: any) => ({
            ...prevState,
            [series_id]: response?.data?.result || [],
          }));
        } else {
          setExpandedInstencesstdyListData((prevState: any) => ({
            ...prevState,
            [series_id]: [],
          }));
        }

        //setReviewedScansData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onLoadDiacomTestData = async () => {
    setUploadError("");
    setIsLoadingDiacom(true);
    const token = cookies["access_token"];
    const url = `${API_URL}/report/upload/test_data`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(url, {}, config);
      if (response?.data?.status === "success") {
        if (response?.data) {
          console.log("response", response);
          
      setIsLoadingDiacom(false);
          setIsDiacomLoadSuccess(true);
          //  fetchNotReviewedData()
          //  fetchReviewedData()
          //  fetchSeriesData()
          setTimeout(() => {
            setIsDiacomLoadSuccess(false);
          }, 3000);
        }
      }
    } catch (error) {
      console.error(error);
      setUploadError("Something went wrong, try again");
      setIsDiacomLoadSuccess(false);

      setIsLoadingDiacom(false);
    }
  };

  const [seletedInstences, setSeletedInstences] = useState<any>({});

  const onSelectSeriesInstance = (instance: any) => {
    let seletedInstencesCopy = { ...seletedInstences };
    if (
      seletedInstencesCopy[`${instance.series_id}`] &&
      Array.isArray(seletedInstencesCopy[`${instance.series_id}`])
    ) {
      if (
        seletedInstencesCopy[`${instance.series_id}`].includes(
          instance.instance_id
        )
      ) {
        let index = seletedInstencesCopy[`${instance.series_id}`].findIndex(
          (x: any) => x == instance.instance_id
        );
        seletedInstencesCopy[`${instance.series_id}`].splice(index, 1);
      } else {
        seletedInstencesCopy[`${instance.series_id}`].push(
          instance.instance_id
        );
      }
    } else {
      seletedInstencesCopy[`${instance.series_id}`] = [];
      seletedInstencesCopy[`${instance.series_id}`].push(instance.instance_id);
    }
    setSeletedInstences(seletedInstencesCopy);
  };

  const onAllSelectSeriesInstance = (series_id: any) => {
    let seletedInstencesCopy = { ...seletedInstences };
    if (
      seletedInstences[series_id] &&
      seletedInstences[series_id].length ===
        expandedInstencesstdyListdata[series_id].length
    ) {
      seletedInstencesCopy[`${series_id}`] = [];
    } else {
      seletedInstencesCopy[`${series_id}`] =
        expandedInstencesstdyListdata[series_id].length > 0
          ? expandedInstencesstdyListdata[series_id].map(
              (item: any) => item.instance_id
            )
          : [];
    }
    setSeletedInstences(seletedInstencesCopy);
  };
  const [seriesIdInstanceDelete, setSeriesIdInstanceDelete] = useState("");
  const [isShowDeleteInstance, setShowDeleteInstance] = useState(false);
  const [studyIdInstanceDelete, setStudyIdInstanceDelete] = useState("");

  const onDeleteSeriesInstences = (series_id: string, study_id: any) => {
    let storeId = expandedSeriesData[study_id].length && expandedSeriesData[study_id][0]["store_id"]
    setSeriesIdInstanceDelete(series_id);
    setStudyIdInstanceDelete(study_id);
    setSelectedStoreId(storeId || '')
    setShowDeleteInstance(true);
  };

  const onDeleteConfirmInstances = () => {
    if (seriesIdInstanceDelete && studyIdInstanceDelete) {
      seletedInstences[seriesIdInstanceDelete]?.length &&
        seletedInstences[seriesIdInstanceDelete].map((item: string) => {
          callDeleteSeriesInstencesApi(
            seriesIdInstanceDelete,
            studyIdInstanceDelete,
            item
          );
        });
    }
  };

  const callDeleteSeriesInstencesApi = async (
    series_id: string,
    study_id: any,
    instanceId: string
  ) => {
    setInstanceDeleteLoading(true);
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/store/${selectedStoreId}/study/${study_id}/series/${series_id}/instances/${instanceId}?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);
      if (response?.data?.status === "success") {
        setIsStudyDelete(false);
        fetchNotReviewedData();
        fetchSeriesDataById(series_id);

        setSlectedRowId("");
        setSlectedStudyId("");
        setSelectAllDelete({});
        fetchReviewedSeriesInstances(series_id, study_id);
        setInstanceDeleteLoading(false);
        setShowDeleteInstance(false);
      }
    } catch (error) {
      setIsStudyDelete(false);
      setShowDeleteInstance(false);
      setInstanceDeleteLoading(false);
      console.error(error);
    }
  };

  const onSelectRowSeries = (rowNumber: number, seletedSeries: any) => {
    let seletAllDeleteCopy = { ...seletAllDelete };
    if (
      seletAllDeleteCopy[`${rowNumber}`] &&
      Array.isArray(seletAllDeleteCopy[`${rowNumber}`])
    ) {
      if (
        seletAllDeleteCopy[`${rowNumber}`].includes(seletedSeries.series_id)
      ) {
        let index = seletAllDeleteCopy[`${rowNumber}`].findIndex(
          (x: any) => x == seletedSeries.series_id
        );
        seletAllDeleteCopy[`${rowNumber}`].splice(index, 1);
      } else {
        seletAllDeleteCopy[`${rowNumber}`].push(seletedSeries.series_id);
      }
    } else {
      seletAllDeleteCopy[`${rowNumber}`] = [];
      seletAllDeleteCopy[`${rowNumber}`].push(seletedSeries.series_id);
    }
    setSelectAllDelete(seletAllDeleteCopy);
  };

  const checkAllSeries = (rowNumber: number, row: any) => {
    let seletAllDeleteCopy = { ...seletAllDelete };
    if (
      seletAllDelete[rowNumber] &&
      seletAllDelete[rowNumber].length === row.length
    ) {
      seletAllDeleteCopy[`${rowNumber}`] = [];
    } else {
      seletAllDeleteCopy[`${rowNumber}`] =
        row.length > 0
          ? row.map((item: any) => item.series_id)
          : [];
    }
    setSelectAllDelete(seletAllDeleteCopy);
  };

  const deleteMultipleSeries = (parentscanId: any, rowNumber: any,storeId?:string) => {
    setSlectedRowId(rowNumber);
    setSlectedStudyId(parentscanId);
    setSelectedStoreId(storeId)
    setIsSeriesDelete(false);
    setIsStudyDelete(false);
    setShowDeleteScan(true);
  };

  const onDeleteScans = async () => {
    if (isStudyDelete) {
      onDeleteStudy();
    } else if (isSeriesDelete) {
      onDeleteSeries();
    } else {
      let selectedDataIds = seletAllDelete[slectedRowId];
      let study_id = slectedStudyId; //seletAllDelete[rowNumber][0].study_id
      if (selectedDataIds.length) {
        let deleteAll = selectedDataIds.map((id: string) => {
          return callDeleteScanseriesApi(study_id, id);
        });
      }
    }
  };

  const deleteReviewedStudyConfirm = () => {
    setIsReviewedDeleteLoading(true);
    const token = cookies["access_token"];

    const countryData = cookies["countryData"]
    const Url = `${API_URL}/unsent/store/${selectedStoreId}/study/${slectedReviewedStudyId}?country_code=${countryData.code}`;
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          console.log("response?.data?.data reviewed", response);
          fetchReviewedData();
          setIsReviewedDeleteLoading(false);
          setShowReviewedDeleteStudy(false);
        } else {
          setIsReviewedDeleteLoading(false);
          setShowReviewedDeleteStudy(false);
          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsReviewedDeleteLoading(false);
        setShowReviewedDeleteStudy(false);
        // setIsReportsLoading(false);
      });
  };

  const onDeleteStudy = () => {
    callDeleteScanStudysApi(slectedStudyId);
  };
  const onDeleteSeries = () => {
    callDeleteScanseriesApi(slectedStudyId, slectedSeriesId);
  };

  const deleteStudy = (parentscanId: any, rowNumber: any,storeId?:string) => {
    setSlectedRowId(rowNumber);
    setSlectedStudyId(parentscanId);
    setSelectedStoreId(storeId)
    setIsStudyDelete(true);
    setShowDeleteScan(true);
  };

  const [slectedReviewedStudyId, setSlectedReviewedStudyId] = useState("");
  const [isShowReviewedDeleteStudy, setShowReviewedDeleteStudy] =
    useState(false);
  const [isReviewedDeleteLoading, setIsReviewedDeleteLoading] = useState(false);

  const deleteReviewedStudy = (studyId: string,storeId?:string) => {
    setSlectedReviewedStudyId(studyId);
    setShowReviewedDeleteStudy(true);
    setSelectedStoreId(storeId)
  };

  const callDeleteScanseriesApi = async (study_id: any, series_id: any) => {
    setIsDeleteLoading(true);
    const token = cookies["access_token"];
    const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/store/${selectedStoreId}/study/${study_id}/series/${series_id}?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);
      if (response?.data?.status === "success") {
        setIsSeriesDelete(false);
        setIsDeleteLoading(false);
        fetchSeriesDataById(study_id);
        fetchNotReviewedData();
        setSelectAllDelete({});
        setShowDeleteScan(false);
      }
    } catch (error) {
      setIsSeriesDelete(false);
      setIsDeleteLoading(false);
      setShowDeleteScan(false);
      console.error(error);
    }
  };

  const callDeleteScanStudysApi = async (study_id: any) => {
    setIsDeleteLoading(true);
   const token = cookies["access_token"];
   const countryData = cookies["countryData"]
    const url = `${API_URL}/unsent/store/${selectedStoreId}/study/${study_id}?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);
      if (response?.data?.status === "success") {
        setIsStudyDelete(false);
        setIsDeleteLoading(false);
        fetchNotReviewedData();
        setShowDeleteScan(false);
        setSlectedRowId("");
        setSlectedStudyId("");
        setSelectAllDelete({});
      }
    } catch (error) {
      setIsStudyDelete(false);
      setShowDeleteScan(false);
      setIsDeleteLoading(false);
      console.error(error);
    }
  };

  const onSeriesDelete = (study_id: any, rowNumber: any, seriesId: any) => {
    setSlectedRowId(rowNumber);
    setSlectedStudyId(study_id);
    setSlectedSeriesId(seriesId);
    setIsSeriesDelete(true);
    setShowDeleteScan(true);
  };

  const onDownload = async (downloadStudyId: string) => {
    setShowDownload(true);
    fetchDataForDownload(downloadStudyId);
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function displayProtectedImage(imageUrl: any, token: any) {
    const responses = await fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Image/jpeg",
      },
    });
    let dataaaa = await responses.blob();
    return dataaaa;
  }

  const fetchDataForDownload = (downloadStudyId: string) => {
    setDownlading(true);
    const token = cookies["access_token"];
    const Url = `${API_URL}/list_data/study/pamphlet?study_fhir_id=${downloadStudyId}`;
    const scansImagesUrl = `${API_URL}/list_data/frame_urls?study_fhir_id=${downloadStudyId}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          setDownloadingData(response?.data?.data);

          axios.get(scansImagesUrl, config).then(async (responseData) => {
            if (
              responseData &&
              responseData.data &&
              responseData.data.status === "success"
            ) {
              setScansAuthToken(responseData.data.data.auth_token);

              const frames = await Object.keys(
                responseData.data.data.frame_urls
              )
                ?.slice(0, 2)
                .map((frame: any) => responseData.data.data.frame_urls[frame]);
             const fetchFrameImages = async ()=> {
                   const  framesPromise = frames.map(async(frameArray)=>{
                    const fetchProtectedImage = frameArray.map(
                      async (itemUrl: any) => {

                        let url = await displayProtectedImage(
                          `${itemUrl}`,
                          responseData.data.data.auth_token
                        );
                        let base64 = await blobToBase64(url);
                        return base64
                      }
                   )
                   const fetchProtectedImageArr = await Promise.all(
                    fetchProtectedImage
                  );
                  return fetchProtectedImageArr;
                  })
                  return await Promise.all(framesPromise)
              }

              fetchFrameImages().then((fetchProtectedImageArr:any)=>{
                setDownloadingImageData(fetchProtectedImageArr);
                  setIsPdf(true);
                  setTimeout(() => {
                    studyDownloadRef.current.onDownload();
                    setTimeout(() => {
                    setShowDownload(false);
                    }, 2000);
                  }, 3000);

              })
            } else {
              setIsPdf(true);
              setTimeout(() => {
                studyDownloadRef.current.onDownload();
                setShowDownload(false);
              }, 3000);
            }
          });
        } else {
          setShowDownload(false);
          setDownlading(false);
          setDownloadingData({});
          setIsPdf(false);

          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowDownload(false);
        setIsPdf(false);

        setDownlading(false);
        setDownloadingData({});
      });
  };

  const notReviewed = () => {
    let dataToDisplay = [];
    if (activeList === "seriesList") {
      dataToDisplay = seriesScanData;
    } else if (activeList === "studyList") {
      dataToDisplay = Object.values(scansData).flat();
    }
    return dataToDisplay && dataToDisplay.length > 0 ? (
      dataToDisplay.map((row, index) => (
        <div>
          <ParentListContainer>
            <ListContainer key={index}>
              <ThumbnailContainer>
                <ThumbnailBox>
                  <ScansThumbnail
                    src={
                      activeList === "seriesList"
                        ? "https://heme-content-files.s3.ap-south-1.amazonaws.com/dicom_mocks/series_thumbnail.png"
                        : "https://heme-content-files.s3.ap-south-1.amazonaws.com/dicom_mocks/study_thumbnail.png"
                    }
                  />
                </ThumbnailBox>
                <PatientInfo>
                  <PatientName>{row?.patient_name?.split(" ")[0]}</PatientName>
                  <PatientName>
                    {row?.patient_name?.split(" ").slice(1).join(" ")}
                  </PatientName>
                  <PatientGender>
                    {row?.patient_gender === "F"
                      ? "Female"
                      : row?.patient_gender === "M"
                      ? "Male"
                      : ""}
                    {row?.patient_age ? `, ${row?.patient_age}` : ""}
                  </PatientGender>
                  <StudyDate>
                    {/* {activeList === "seriesList"
                      ? formattedDate(row.series_date)
                      : formattedDate(row.series[0]?.series_date)} */}
                  </StudyDate>
                </PatientInfo>
              </ThumbnailContainer>
              <UidContainer>
                <UidText>Patient attached?</UidText>
                <UIDValue>
                {row?.is_sent ? "Yes Available" : "No"}
                </UIDValue>
              </UidContainer>
              <UidContainer>
                <UidText>UID</UidText>
                <UIDValue>
                  {activeList === "seriesList"
                    ? row?.series_id
                    : row?.study_id}
                </UIDValue>
              </UidContainer>
              {activeList === "seriesList" ? (
                ""
              ) : (
                <></>
                // <ModalityContainer>
                //   <UidText>Modality</UidText>
                //   <UIDValue>CET</UIDValue>
                // </ModalityContainer>
              )}

              {activeList === "seriesList" && (
                <>
                  <DescriptionContainer>
                    <DescriptionText>Description</DescriptionText>
                    {/* <DescriptionValue>{row?.description}</DescriptionValue> */}
                    <DescriptionValue>{row?.description}</DescriptionValue>
                  </DescriptionContainer>
                  <ModalityContainer>
                    <ModalityText>Modality</ModalityText>
                    <ModalityValue>{row?.modality_code}</ModalityValue>
                  </ModalityContainer>
                </>
              )}

              {activeList === "seriesList" && (
                <>
                  <ReviewButtonWrapper>
                    <ViewerButtonMenu>
                      Viewer
                      <ArrowImageInstance src={DownArrow} />
                      <div className="custom-modal">
                        <Dropdownlist
                          // onClick={() =>
                          //   // fetchViewerUrls(row,"basic")
                          // }
                          // disabled={!row.viewer_url?.basic_viewer}
                        >
                          Basic Viewer
                        </Dropdownlist>
                        <Dropdownlist
                          // onClick={() => fetchViewerUrls(row,"tmtv")}
                          // disabled={!row.viewer_url?.tmtv}
                        >
                          TMTV
                        </Dropdownlist>
                      </div>
                    </ViewerButtonMenu>
                  </ReviewButtonWrapper>
                  <ReviewButtonWrapper>
                    <ReviewButton onClick={() => openReviewModal(row)}>
                      Review
                    </ReviewButton>
                  </ReviewButtonWrapper>
                  <InstancesContainer>
                    {/* <InstancesValue>{row?.instance_count}</InstancesValue> */}
                    <ReviewButtonWrapper>
                      <TableDropdown
                        onClick={() =>
                          toggleInstanceExpanded(row?.series_id, row?.study_id)
                        }
                      >
                        {row?.instance_count} Instances
                        <span>
                          <ArrowImage
                            src={
                              expandedInstencesstdyList[row?.series_id]
                                ? UpArrow
                                : DownArrow
                            }
                          />
                        </span>
                      </TableDropdown>
                    </ReviewButtonWrapper>
                  </InstancesContainer>
                  <ReviewButtonWrapper>
                    <img
                      onClick={() =>
                        onSeriesDelete(row.study_id, index, row.series_id)
                      }
                      src={deleteIcon}
                      style={{ cursor: "pointer", marginRight: "5px" }}
                    />
                  </ReviewButtonWrapper>
                </>
              )}

              {activeList === "studyList" && (
                <>
                  <ReviewButtonWrapper>
                    <ViewerButtonMenu>
                      Viewer
                      <ArrowImageInstance src={DownArrow} />
                      <div className="custom-modal">
                        <Dropdownlist
                          onClick={() =>
                            fetchViewerUrls(row,"basic",'study')
                          }
                          // disabled={!row.viewer_url?.basic_viewer}
                        >
                          Basic Viewer
                        </Dropdownlist>
                        <Dropdownlist
                          onClick={() => fetchViewerUrls(row,"tmtv","study")
                          }
                          // disabled={!row.viewer_url?.tmtv}
                        >
                          TMTV
                        </Dropdownlist>
                      </div>
                    </ViewerButtonMenu>
                  </ReviewButtonWrapper>
                </>
              )}
              {activeList === "studyList" && (
                <>
                  <ReviewButtonWrapper>
                  
                    <ReviewButton onClick={() => openReviewModal(row)}>
                      Review
                    </ReviewButton>
                  </ReviewButtonWrapper>
                  <ReviewButtonWrapper>
                    <TableDropdown
                      onClick={() => toggleExpanded(row?.study_id)}
                    >
                      {row?.series_count|| 0} series
                      <span>
                        <ArrowImage
                          src={
                            expandedItems[row?.study_id]
                              ? UpArrow
                              : DownArrow
                          }
                        />
                      </span>
                    </TableDropdown>
                  </ReviewButtonWrapper>

            <ReviewButtonWrapper>
              {/* {row?.is_sent &&
             <Tooltip title="Dicom info pdf">
                <IconButton
                  aria-label="dicom"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    marginTop: "-2px",
                  }}
                  onClick={() => onDownload(row?.fhir_resource_id)}
                >
                  <FileDownloadIcon
                    sx={{ color: "#30aed7", fontSize: "26px" }}
                  />
                </IconButton>
              </Tooltip>} */}
            </ReviewButtonWrapper>
                  
                  <ReviewButtonWrapper>
                    <img
                      onClick={() =>
                        deleteStudy(row.study_id, index,row?.store_id)
                      }
                      src={deleteIcon}
                      style={{ cursor: "pointer", marginRight: "5px" }}
                    />
                  </ReviewButtonWrapper>
                </>
              )}
            </ListContainer>
            <>
            {console.log("expandedItems[row.study_id] && expandedSeriesData[row.series_id]",expandedItems[row.study_id] ,expandedSeriesData[row.study_id])
            }
              {activeList === "studyList" &&
                row.series_count &&
                row.series_count > 0 &&
                expandedItems[row.study_id] && (
                  <SeriesList>
                    <SeriesHeader>
                      <SeriesColumn isWhite={true}>Series</SeriesColumn>
                      <DescriptionColumn isWhite={true}>
                        Description
                      </DescriptionColumn>
                      <ModalityColumn isWhite={true}> Modality</ModalityColumn>
                      <ViewerColumn isWhite={true}></ViewerColumn>
                      <InstancesColumnBox isWhite={true}>
                        Instances
                      </InstancesColumnBox>
                      {/* <InstancesColumn>Instances</InstancesColumn> */}
                      <InstancesColumn isWhite={true}>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            checked={
                              seletAllDelete[index]?.length == expandedSeriesData[row.study_id]?.length
                            }
                            sx={{
                              padding: 0,

                              [`@media ${DEVICE.desktopL || DEVICE.laptopL}`]: {
                                padding: "0px 6px",
                              },
                            }}
                            onChange={() => checkAllSeries(index, expandedSeriesData[row.study_id])}
                          />
                          {seletAllDelete[index]?.length > 0 ? (
                            <img
                              onClick={() =>
                                deleteMultipleSeries(
                                  row.study_id,
                                  index,
                                  row.store_id
                                )
                              }
                              src={deleteIcon}
                              style={{ cursor: "pointer", marginRight: "5px" }}
                            />
                          ) : (
                            <img
                              src={deleteIconGrey}
                              style={{ marginRight: "5px" }}
                            />
                          )}
                        </span>
                      </InstancesColumn>
                    </SeriesHeader>
                    {expandedSeriesData[row.study_id] ?
                   <> { expandedSeriesData[row.study_id]?.length ? <>
                    {expandedSeriesData[row.study_id]?.map((seriesItem: any, indexs: any) => {
                  return<div>

                      <SeriesRow>
                        <SeriesColumn>
                          <DescriptionSeriesValueNew key={indexs}>
                            {seriesItem.series_id || " "}
                          </DescriptionSeriesValueNew>
                        </SeriesColumn>
                        <DescriptionColumn>
                          <DescriptionSeriesValueNew key={indexs}>
                            {seriesItem.description}
                          </DescriptionSeriesValueNew>
                        </DescriptionColumn>
                        <ModalityColumn>
                          <DescriptionSeriesValueNew key={indexs}>
                            {seriesItem.modality}
                          </DescriptionSeriesValueNew>
                        </ModalityColumn>
                        <ViewerColumn>
                          <div className="button-box">
                            <ViewBtn
                              key={indexs}
                              onClick={() =>
                                fetchViewerUrls(seriesItem,"basic","series")
                              }
                            >
                              <img
                                src={ViewIconsBlue}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  width: "16px",
                                }}
                              />
                              View
                            </ViewBtn>
                            {/* <ViewerButton
                              
                             
                            >
                              Viewer
                            </ViewerButton> */}
                          </div>
                        </ViewerColumn>
                        {/* <InstancesColumn>
                              {row.series.map((seriesItem: any, index: any) => (
                                  <InstancesSeriesValue key={index}>
                                       {seriesItem.instance_count}}
                                      </InstancesSeriesValue>
                              ))}
                          </InstancesColumn> */}
                        <InstancesColumnBox>
                          <SubTableDropdown
                            onClick={() =>
                              toggleInstanceExpanded(
                                seriesItem?.series_id,
                                seriesItem?.study_id
                              )
                            }
                          >
                            Instances
                            <span>
                              <ArrowImage
                                src={
                                  expandedInstencesstdyList[
                                    seriesItem?.series_id
                                  ]
                                    ? UpArrow
                                    : DownArrow
                                }
                              />
                            </span>
                          </SubTableDropdown>
                        </InstancesColumnBox>

                        <InstancesColumn>
                          <DescriptionSeriesValueNew key={indexs}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={
                                  seletAllDelete[index]?.includes(
                                    seriesItem.series_id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  onSelectRowSeries(index, seriesItem)
                                }
                              />
                            </div>
                          </DescriptionSeriesValueNew>
                        </InstancesColumn>
                      </SeriesRow>
                      {expandedInstencesstdyList[seriesItem.series_id] && (
                        <Showsubinstance>
                          {expandedInstencesstdyListdata[
                            seriesItem?.series_id
                          ] ? (
                            expandedInstencesstdyListdata[
                              seriesItem?.series_id
                            ].length ? (
                              <SubinstanceTable
                                data={
                                  expandedInstencesstdyListdata[
                                    seriesItem?.series_id
                                  ] || []
                                }
                                onAllSelectSeriesInstance={
                                  onAllSelectSeriesInstance
                                }
                                onSelectSeriesInstance={
                                  onSelectSeriesInstance
                                }
                                seletedInstences={seletedInstences}
                                onDeleteSeriesInstences={
                                  onDeleteSeriesInstences
                                }
                                fetchViewerUrls={fetchViewerUrls}
                                isStudyList={activeList === "studyList"}
                              />
                            ) : (
                              <div style={{ textAlign: "center" }}>
                                No Record Found
                              </div>
                            )
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <SimpleLoader
                                text={""}
                                height="100%"
                                size={30}
                              />
                            </div>
                          )}
                        </Showsubinstance>
                      )}
                      {/* <div className="table-border-Row"></div> */}
                    </div>
                      })}
                  </> :
                      <div style={{ textAlign: "center" ,height:"150px"}}>
                        No Record Found
                      </div>}
                    </>:
                    <div style={{ textAlign: "center",height:"150px" }}>
                                <SimpleLoader
                                  text={""}
                                  height="100%"
                                  size={30}
                                />
                    </div>}
                  </SeriesList>
                )}
            </>

            {activeList === "seriesList" ? (
              expandedInstencesstdyList[row.series_id] && (
                <Showsubinstance>
                  {/* <SubinstanceTable data = {expandedInstencesstdyListdata[row?.series_id] || [] }/> */}
                  {expandedInstencesstdyListdata[row?.series_id] ? (
                    expandedInstencesstdyListdata[row?.series_id]?.length ? (
                      <SubinstanceTable
                        data={
                          expandedInstencesstdyListdata[row?.series_id] || []
                        }
                        onAllSelectSeriesInstance={onAllSelectSeriesInstance}
                        onSelectSeriesInstance={onSelectSeriesInstance}
                        seletedInstences={seletedInstences}
                        onDeleteSeriesInstences={onDeleteSeriesInstences}
                        openInNewTab={openInNewTab}
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>No Record Data</div>
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <SimpleLoader text={""} height="100%" size={30} />
                    </div>
                  )}
                </Showsubinstance>
              )
            ) : (
              <span className=""></span>
            )}
          </ParentListContainer>
        </div>
      ))
    ) : (
      <EmptyListMessage>No scans found</EmptyListMessage>
    );
  };

  const reviewedAndSent = () => {
    return reviewedScanData && reviewedScanData.length > 0 ? (
      <>
        {reviewedScanData?.map((row, index) => (
          <ReviewedRowContainer>
            <ListContainer key={index + 1}>
              <ThumbnailContainer>
                <ThumbnailBox>
                  <ScansThumbnail src={"https://heme-content-files.s3.ap-south-1.amazonaws.com/dicom_mocks/study_thumbnail.png"} />
                </ThumbnailBox>
                <PatientInfo>
                  <PatientName>{row?.patient_name?.split(" ")[0]}</PatientName>
                  <PatientName>
                    {row?.patient_name?.split(" ").slice(1).join(" ")}
                  </PatientName>
                  <PatientGender>{row?.patient_gender}</PatientGender>
                  {/* <StudyDate>{formattedDate(row?.study_date)}</StudyDate> */}
                </PatientInfo>
              </ThumbnailContainer>
              <UidContainer>
                <UidText>Patient attached?</UidText>
                <UIDValue>
                {row?.is_sent ? "Yes Available" : "No"}
                </UIDValue>
              </UidContainer>
              <UidContainer>
                <UidText>UID</UidText>
                <UIDValue>{row?.study_id}</UIDValue>
              </UidContainer>
              {/* <SeriesContainer>
                        <SeriesText>Series</SeriesText>
                        <SeriesValue>{row?.series_number}</SeriesValue>
                    </SeriesContainer> */}
              {/* <ModalityContainer>
            <ModalityText>Modality</ModalityText>
            <ModalityValue>{row?.modality}</ModalityValue>
            
          </ModalityContainer> */}
              {/* <SendPatientContainer>
                <SendPatientText>Sent to Doctor</SendPatientText>
                <SendPatientValue>Dr Shriniwas Rao</SendPatientValue>
              </SendPatientContainer> */}
              {/* <SendPatientContainer>
                <SendPatientText>Sent to Patient</SendPatientText>
                <SendPatientValue>-</SendPatientValue>
              </SendPatientContainer> */}
              <SendPatientContainer>
                <SendPatientText>Sent on</SendPatientText>
                <StudyDate>
                  {formattedDate(row?.updated_timestamp) || "-"}
                </StudyDate>
              </SendPatientContainer>

              {/* <DescriptionContainer>
                        <DescriptionText>Description</DescriptionText>
                        <DescriptionValue>{row?.description}</DescriptionValue>
                    </DescriptionContainer> */}
              {/* <DescriptionContainer>
            <DescriptionText>Sent</DescriptionText>
            <SentValue>{formattedDate(row?.date)}</SentValue>
          </DescriptionContainer> */}
              {/* <ReviewButton
                onClick={() => openInNewTab(row?.desktop_viewer_url)}
                disabled={!row?.desktop_viewer_url}
              >
                Viewer
              </ReviewButton> */}

              {true && (
                <ViewerButtonWrapper>
                  <ViewerButtonMenu onClick={handlePopoverOpen}>
                    Viewer
                    <ArrowImageInstance
                      src={viewerMenu ? UpArrow : DownArrow}
                    />
                    <div className="custom-modal">
                      <Dropdownlist
                        onClick={() => fetchViewerUrls(row,"basic","study")}

                      >
                        Basic Viewer
                      </Dropdownlist>
                      <Dropdownlist
                        onClick={() => fetchViewerUrls(row,"tmtv","study")}
                      >
                        TMTV
                      </Dropdownlist>
                    </div>
                  </ViewerButtonMenu>
                </ViewerButtonWrapper>
              )}
              <InstancesContainer>
                {/* <InstancesText>Instances</InstancesText>
            <InstancesValue>{row?.instances}</InstancesValue> */}
                <TableDropdown
                  onClick={() => ReviewedInstancesToggle(row?.study_id)}
                >
                  {row?.series_count || 0} Series
                  <span>
                    <ArrowImageInstance
                      src={
                        reviewedInstancesItems[row.study_id]
                          ? UpArrow
                          : DownArrow
                      }
                    />
                  </span>
                </TableDropdown>
              </InstancesContainer>

              <Tooltip title="Dicom info pdf">
                <IconButton
                  aria-label="dicom"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    marginTop: "-2px",
                  }}
                  onClick={() => onDownload(row.fhir_resource_id)}
                >
                  <FileDownloadIcon
                    sx={{ color: "#30aed7", fontSize: "26px" }}
                  />
                </IconButton>
              </Tooltip>

              <img
                onClick={() => deleteReviewedStudy(row.study_id,row.store_id)}
                src={deleteIcon}
                style={{ cursor: "pointer", marginRight: "5px" }}
              />
            </ListContainer>
            {reviewedInstancesItems[row.study_id] &&
            <>
           { expandedSeriesData[row.study_id] ? (
              <ReviewedShowsubinstance>
                {expandedSeriesData[row.study_id]?.length ?
                <ReviewedSubinstanceTable
                data={expandedSeriesData[row.study_id] || []}
                fetchViewerUrls={fetchViewerUrls}

              />:
              <div style={{ textAlign: "center" ,height:"150px"}}>
              No Record Found
            </div>
              }
              </ReviewedShowsubinstance>
            )
            :
          <div style={{ textAlign: "center",height:"150px" }}>
                      <SimpleLoader
                        text={""}
                        height="100%"
                        size={30}
                      />
          </div>
          }
          </>
            }
          </ReviewedRowContainer>
        ))}
      </>
    ) : (
      <EmptyListMessage>No scans found</EmptyListMessage>
    );
  };

  return (
    <>
      <Container>

        {uploadError && (
          <span
            style={{
              color: "red",
              fontSize: "13px",
              margin: "-14px 0",
              float: "right",
            }}
          >
            {uploadError}
          </span>
        )}
        <HeaderRow>
          {/* <SectionHeader>Scans</SectionHeader> */}
          <ReviewButtonContainer>
            <AddTempWrapper>
              <ButtonBase
                active={activeButton === "notReviewed"}
                onClick={() => {
                  setActiveButton("notReviewed");
                  setExpandedInstencesstdyList({});
                  setExpandedInstencesstdyListData({});
                  setSeletedInstences({});
                }}
              >
                Not Reviewed
              </ButtonBase>
            </AddTempWrapper>
            <AddTempWrapper>
              <ButtonBase
                active={activeButton === "reviewedAndSent"}
                onClick={() => {
                  setActiveButton("reviewedAndSent");
                  setExpandedInstencesstdyList({});
                  setExpandedInstencesstdyListData({});
                  setSeletedInstences({});
                }}
              >
                Reviewed & Sent
              </ButtonBase> 
            </AddTempWrapper>
          </ReviewButtonContainer>
         <div style={{display:'flex'}}>
         <AddTempWrapper>
             <div style={{ display: "flex", flexDirection: "column" }}>
               <AddButton onClick={onRefreshData} disabled ={isLoading || isScansLoading}>
                 {false ? (
                   <CircularProgress size={20} color="inherit" />
                 ) : (
                   "Refresh"
                 )}
               </AddButton>
             </div>
           </AddTempWrapper>
           {activeButton === "notReviewed" && (<>
            <AddTempWrapper>
              <div style={{ display: "flex", flexDirection: "column",marginLeft:"10px" }}>
                <AddButton onClick={onLoadDiacomTestData}>
                  {isLoadingDiacom ? (
                    // <SimpleLoader height="100%" size={20} />
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Load Test Data"
                  )}
                </AddButton>
              </div>
            </AddTempWrapper>
            </>
          )}
         </div>
        </HeaderRow>
        <div style={{ backgroundColor: "#fff", padding: "5px 30px 30px" }}>
          {/* <VerticalSep size={10} /> */}
          {activeButton === "notReviewed" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <ListReviewButtonContainer>
                <ListAddTempWrapper>
                  <ListButtonBase
                    active={activeList === "studyList"}
                    onClick={() => {
                      setSelectAllDelete({});
                      fetchNotReviewedData();
                      setActiveList("studyList");
                    }}
                  >
                    {`Study List ${
                      Object.values(scansData).flat()?.length
                        ? "(" + Object.values(scansData).flat()?.length + ")"
                        : ""
                    }`}
                  </ListButtonBase>
                </ListAddTempWrapper>
                <ListAddTempWrapper>
                  <ListButtonBase
                    active={activeList === "seriesList"}
                    onClick={() => {
                      setSelectAllDelete({});
                      setActiveList("seriesList");
                      fetchSeriesData();
                    }}
                  >
                    {`Series List ${
                      seriesScanData.length
                        ? "(" + seriesScanData.length + ")"
                        : ""
                    }`}
                  </ListButtonBase>
                </ListAddTempWrapper>
              </ListReviewButtonContainer> */}

              {/* <ListReviewButtonContainer>
                <ListAddTempWrapper>
                  <ListButtonBase
                    active={activeButton === "notReviewed"}
                    onClick={() => {
                      setSelectAllDelete({});
                      fetchNotReviewedData();
                      setActiveButton("notReviewed");
                    }}
                  >
                    {`Not Reviewed ${
                      Object.values(scansData).flat()?.length
                        ? "(" + Object.values(scansData).flat()?.length + ")"
                        : ""
                    }`}
                  </ListButtonBase>
                </ListAddTempWrapper>
                <ListAddTempWrapper>
                  <ListButtonBase
                    active={activeButton !== "notReviewed"}
                    onClick={() => {
                      setSelectAllDelete({});
                      setActiveButton("reviewedAndSent");
                      fetchReviewedData();
                    }}
                  >
                    {`Reviewed ${
                      seriesScanData.length
                        ? "(" + seriesScanData.length + ")"
                        : ""
                    }`}
                  </ListButtonBase>
                </ListAddTempWrapper>
              </ListReviewButtonContainer> */}
            </div>
          )}
          {activeButton === "notReviewed" ? (
            !isLoading ? (
              notReviewed()
            ) : (
              <SimpleLoader />
            )
          ) : !isScansLoading ? (
            reviewedAndSent()
          ) : (
            <SimpleLoader />
          )
          }
        </div>
      </Container>
      <VerticalSep size={20} />
      <ReviewModal
        open={showReviewModal}
        handleClose={() => setShowReviewModal(false)}
        handleSave={onSendReport}
        scanData={selectedScan}
        isSeriesReview={isSeriesReview}
      />
      {isShowDeleteScan ? (
        <ScanDeleteSure
          open={isShowDeleteScan}
          onDelete={() => onDeleteScans()}
          isLoading={isDeleteLoading}
          testId={"test"}
          title={"Are you sure you want to delete ?"}
          discription={"This action cannot be undone once deleted."}
          closeHandler={() => {
            setShowDeleteScan(false);
          }}
        />
      ) : (
        ""
      )}

      {isShowDeleteInstance ? (
        <ScanDeleteSure
          open={isShowDeleteInstance}
          onDelete={() => onDeleteConfirmInstances()}
          isLoading={isInstanceDeleteLoading}
          testId={"test"}
          title={"Are you sure you want to delete ?"}
          discription={"This action cannot be undone once deleted."}
          closeHandler={() => {
            setShowDeleteInstance(false);
          }}
        />
      ) : (
        ""
      )}
      {isShowReviewedDeleteStudy ? (
        <ScanDeleteSure
          open={isShowReviewedDeleteStudy}
          onDelete={() => deleteReviewedStudyConfirm()}
          isLoading={isReviewedDeleteLoading}
          testId={"testww"}
          title={"Are you sure you want to delete ?"}
          discription={"This action cannot be undone once deleted."}
          closeHandler={() => {
            setShowReviewedDeleteStudy(false);
          }}
        />
      ) : (
        ""
      )}

      {isShowDownload ? (
        <ScanDeleteSure
          open={isShowDownload}
          isLoaderPopup={true}
          isLoading={isDownLoading}
          testId={"test"}
          title={""}
          discription={""}
          closeHandler={() => {
            setShowDownload(false);
          }}
        />
      ) : (
        ""
      )}

{isDiacomLoadSuccess ? (
        <InfoDialog
          open={isDiacomLoadSuccess}
          testId={"test"}
          title={""}
          discription={
            "Your request has been accepted. Please refresh after sometime to see the loaded files."
          }
          closeHandler={() => {
            setIsDiacomLoadSuccess(false);
          }}
        />
      ) : (
        ""
      )}


      {isPDf && (
        <div style={{ marginTop: "400px" }}>
          <PatientReportPdf
            ref={studyDownloadRef}
            data={downloadingData}
            setPdf={setIsPdf}
            downloadingImageData={downloadingImageData}
            scansAuthToken={scansAuthToken}
          />
        </div>
      )}
    </>
  );
};

export default NewScans;
