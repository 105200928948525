import { Box } from "@mui/material";
import { FC } from "react";
import {
  ListItem,
  ListItemWrapper,
  ThumbImage,
  CardHeader,
  ThumbImages,
  ThumbText,
} from "../../../pages/Records";
import DropdownThreeDotMenu from "../../common/DropdownThreeDot/Dropdown";
import {
  formatTimestamp,
  removeSpecialCharacters,
} from "../../../utilities/helper";

interface SingleReportProps {
  name?: string;
  icon?: string;
  test_type?: string;
  upload_date?: string;
}
const SingleReport: FC<SingleReportProps> = ({
  name,
  icon,
  test_type,
  upload_date,
}) => {
  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "1rem",
        border: "0.5rem solid #D7EFF7",
      }}
      display="flex"
      justifyContent="center"
    >
      <ListItemWrapper style={{ cursor: "auto", maxWidth: "140px" }}>
        <Box display="flex" justifyContent="space-between">
          <CardHeader>
            <span>{name && removeSpecialCharacters(name)}</span>
          </CardHeader>
          <DropdownThreeDotMenu menuItems={[]} />
        </Box>
        <ThumbImages>
          {icon && <ThumbImage src={icon} alt="records-thumbnail" />}
          <ThumbText>
            <span>{test_type}</span>
            <span className="format">{test_type === "report" ? "PDF" : "dcm"}</span>
          </ThumbText>
        </ThumbImages>
        <div>
          <ListItem>{upload_date && formatTimestamp(upload_date)}</ListItem>
        </div>
      </ListItemWrapper>
    </Box>
  );
};

export default SingleReport;
