import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import MetaInfo from "../MetaInfo/MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import { removeSpecialCharacters } from "../../../utilities/helper";
import "./InfoSection.css";
import styled from "styled-components";
import { useLocation , useNavigate } from "react-router-dom";
import axios from "axios";
import { getInfoDetails } from "../../../apis/report";
import SimpleLoader from "../Loaders/SimpleLoader";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const InfoSection = () => {
  const location = useLocation();
  const { studyFhirId } = location.state || {};
  const [recordInfo, setRecordInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchStudyInfo = async () => {
    setLoading(true);
    try {
      const response = await getInfoDetails(studyFhirId);
      if (response?.data?.status === "success") {
        setRecordInfo(response.data.data);
      }
    } catch (error) {
      console.log("Error occured while fetching info details of study");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyInfo();
  }, []);

  const handleBackNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(-1);
  };


  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Patients",
      href: "/patients",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  return (
    <>
      {!loading && recordInfo ? (
        <Box ml={5}>
          <Breadcrumb links={breadCrubLinks} />
          <TopHeader>Studies</TopHeader>
          <Box display="flex" mt={2} alignItems="stretch">
            <Grid item display="flex">
              <Paper
                className="box-wrapper"
                sx={{
                  background: "#EBF7FB",
                  borderRadius: { xs: "1rem 1rem 0 0", md: "1rem 0 0 1rem" },
                  paddingBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  className="info-header"
                  sx={{ borderTopLeftRadius: "1rem", paddingBottom: 3 }}
                >
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    {removeSpecialCharacters(
                      recordInfo.study_info.patient_name
                    )}
                  </Typography>
                </Box>
                <Box>
                  <FileInfoSection recordInfo={recordInfo.study_info} />
                  <Box
                    display="flex"
                    m={2}
                    p={2}
                    flexDirection="column"
                    sx={{ background: "#FFF", borderRadius: 4 }}
                  >
                    <Box className="series-text" mt={3} ml={2}>
                      Series({recordInfo.study_info.series.length})
                    </Box>
                    <Box display="flex">
                      <DisplaySeries recordInfo={recordInfo.study_info} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item display="flex">
              <MetaInfo recordInfo={recordInfo} />
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box display="flex" sx={{ background: "#FFF", m: 4 }}>
          <SimpleLoader />
        </Box>
      )}
    </>
  );
};

export default InfoSection;
