import React, { useState, useEffect } from "react";
import IBackArrow from "../../../assets/back-arrow.svg";
import IProfileImage from "../../../assets/profile-icon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { API_URL } from "../../../utilities/constants";
import axios from "axios";
import {
  SideNavbar,
  TopHeader,
  PatientName,
  ProfileWrapper,
  PatientGender,
  PatientDob,
  ProfileIcon,
  PhoneNumber,
  ArrowBackImage,
  PatientEmail,
} from "./style.components";
import { getPatientDetails } from "../../../apis/report";
import moment from "moment";

const SideNav = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  let [searchParams, setSearchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id") || "";

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const [selectedPatientId, setSelectedPatientId] = useState(
    getSearchParam("name") || ""
  );
  const [basic_details, setBasic_details] = useState<{
    name: string;
    phone_number: string;
    gender: string;
    dob: string;
    email: string;
  } | null>(null);

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
    } else {
      setSelectedPatientId(getSearchParam("name") || "");
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    if (selectedPatientId) {
      fetchPatientData();
    }
  }, [selectedPatientId]);

  const fetchPatientData = async () => {
    try {
      const response = await getPatientDetails(getSearchParam("name"));
      if (
        response.data &&
        response.data?.status == "success" &&
        response.data?.data
      ) {
        setBasic_details(response.data.data);
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SideNavbar>
      <TopHeader onClick={() => navigate("/home")}>
        <ArrowBackImage src={IBackArrow} alt="back-arrow" />
        Home
      </TopHeader>
      <ProfileWrapper>
        <ProfileIcon src={IProfileImage} alt="Profile Icon" />
        <PhoneNumber title={basic_details?.phone_number}>
          {basic_details?.phone_number}
        </PhoneNumber>
      </ProfileWrapper>
      <PatientName title={basic_details?.name}>
        {basic_details?.name}
      </PatientName>
      <PatientEmail title={basic_details?.email}>
        {basic_details?.email}
      </PatientEmail>
      <PatientGender title={basic_details?.gender}>
        {basic_details?.gender}
      </PatientGender>
      <PatientDob>
        {basic_details?.dob && moment(basic_details.dob).format("DD-MMM-YYYY")}
      </PatientDob>
    </SideNavbar>
  );
};

export default SideNav;
