import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";

import axios from "axios";
import { API_URL, DEVICE } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import { useDispatch } from "react-redux";
import { setShowStatus } from "../../../store/slices/statusSlice";
import { useSearchParams } from "react-router-dom";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  reportId: string | null;
  reportName?: string | null | undefined;
  closeHandler: () => void;
  type?: string | null;
}
const getSearchParam = (paramName: string, searchParams: any) => {
  let paramValue = searchParams.get(paramName);
  return paramValue;
};

const WhatsappSure = ({
  open,
  closeHandler,
  reportId,
  reportName,
  type,
}: EditTestProps) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [sendToPatient, setSendToPatient] = useState<boolean>(
    getSearchParam("sendType", searchParams) &&
      (getSearchParam("sendType", searchParams) == "patient" ||
        getSearchParam("sendType", searchParams) == "both")
      ? true
      : false
  );
  const [sendToDoctor, setSendToDoctor] = useState<boolean>(
    getSearchParam("sendType", searchParams) &&
      (getSearchParam("sendType", searchParams) == "doctor" ||
        getSearchParam("sendType", searchParams) == "both")
      ? true
      : false
  );
  const dispatch = useDispatch();

  const handleSend = () => {
    if (!reportId) {
      return;
    }
    setIsLoading(true);

    let queryParams = `to_doctor=${sendToDoctor ? 1 : 0}&to_patient=${
      sendToPatient ? 1 : 0
    }`;

    axios
      .post(
        `${API_URL}/lab_portal/reports/${reportId}/whatsapp?${queryParams}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies["access_token"]}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        dispatch(
          setShowStatus({
            message: "Report sent via whatsapp successfully",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          setShowStatus({
            message: "Sending report via whatsapp failed",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
        closeHandler();
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          justifyContent: "flex-start",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <div style={{}}>
          {!isLoading ? (
            type == "both" ? (
              <div>
                <div>
                  Are you sure you want to send pdf file directly to the
                  following?
                </div>
                <div>
                  {/* <span style={{ marginRight: "10px" }}> sending to, </span> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendToPatient}
                        onChange={() => setSendToPatient(!sendToPatient)}
                      />
                    }
                    label="Patient"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendToDoctor}
                        onChange={() => setSendToDoctor(!sendToDoctor)}
                      />
                    }
                    label="Doctor"
                  />
                </div>
              </div>
            ) : type == "patient" ? (
              "Are you sure you want to send pdf file directly to the user?"
            ) : (
              "Are you sure you want to send pdf file directly to the doctor again?"
            )
          ) : (
            <SimpleLoader
              text={"Sending via whatsapp"}
              height="90px"
              size={20}
            />
          )}
        </div>
      </DialogContent>
      {!isLoading ? (
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            padding: "0px 20px 20px 20px",
            margin: "0px 30px",
            [`@media ${DEVICE.mobileL}`]: {
              margin: "0px 5px",
            },
          }}
        >
          <Button
            disabled={!sendToDoctor && !sendToPatient && type == "both"}
            onClick={handleSend}
            sx={{ color: "#FFF" }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={closeHandler}
            // sx={{ color: "#FFF" }}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      ) : null}
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default WhatsappSure;
