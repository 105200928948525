import {
  Box,
  CircularProgress,
  Modal,
  SelectChangeEvent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import addIcon from "../../../assets/add-icon.svg";
import ILogoWhite from "../../../assets/logo-white.svg";
import ScansIcon from "../../../assets/reports.svg";
import closeIcon from "../../../assets/small-close-icon.svg";
import { setShowStatus } from "../../../store/slices/statusSlice";
import {
  API_URL,
  API_URL_UPLOAD_REPORTS,
  COLOR_LINK,
  DEVICE,
  COOKIE_EXPIRY_DAYS,
} from "../../../utilities/constants";
import SimpleLoader from "../Loaders/SimpleLoader";
import AtlassianDropdown from "../SearchDropdown/AtlassianDropdown";
import patientReportIcon from "../../../assets/patient-report-icon-blue.svg";
import DoctorIcon from "../../../assets/doctor-icon_blue.svg";
import TextEditor from "../../../pages/textEditor";
import ViewIcons from "../../../assets/viewIcon-light.svg";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import IProfileImageSmall from "../../../assets/profile-image-small.svg";
import closeIconRed from "../../../assets/remove-icon-red.svg";
import groupIcon from "../../../assets/group-profile.svg";
import groupProfileIcon from "../../../assets/doctors-group.svg"

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px 28px;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;

const ModalBody = styled.div`
  margin: 24px;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-start;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f5fbfc;
  padding: 20px;
  margin-right: 20px;
  border: 1px solid #0099cc;
`;

const ListContainerReport = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f5fbfc;
  padding: 20px;
  border: 1px solid #0099cc;
`;

const ScansThumbnail = styled.img`
  width: 80px;
  height: 96px;
`;

const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  min-width: 200px;
  max-width: 200px;
  word-wrap: break-word;
`;

const PatientName = styled.div`
  font-size: 20px;
  color: #000000;
  word-break: break-all;
`;

const PlusSign = styled.div`
  font-size: 20px;
  color: #000000;
  word-break: break-all;
`;

const PatientGender = styled.div`
  font-size: 14px;
  color: #808080;
`;

const DoctorReviewer = styled.div`
  font-size: 14px;
  color: #808080;
`;

const StudyDate = styled.div`
  font-size: 14px;
  color: #808080;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const UidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ModalityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InstancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UidText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const SeriesText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const ModalityText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const InstancesText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
`;

const UIDValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  max-width: 120px;
  min-width: 120px;
  min-height: 20px;
  word-wrap: break-word;
`;

const SeriesValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 60px;
  min-height: 20px;
`;

const ModalityValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 120px;
  min-height: 20px;
`;

const InstancesValue = styled.div`
  background-color: #d7eff7;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 60px;
  min-height: 20px;
`;

const DescriptionValue = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #d7eff7;
  padding: 6px 12px;
  font-size: 14px;
  color: #000000;
  min-width: 298px;
  min-height: 20px;
`;

const FileName = styled.div`
  font-size: 16px;
  color: #0099cc;
  margin-bottom: 12px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  padding: 20px;
`;

const SkipReview = styled.div`
  font-size: 20px;
  text-decoration: underline;
  color: #808080;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const ReviewReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
`;

const ReviewReportHeader = styled.div`
  font-size: 28px;
  color: #0099cc;
  margin: 32px 0;
`;

const AddReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  background-color: #f5fbfc;
`;

const SendParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px;
  width: 100%;
  max-height: 700px;
  min-height: 300px;
  padding-top: 40px;
  background-color: #ebf7fb;
`;

const SendWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SendReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 24px;
  background-color: #d7eff7;
  border-radius: 8px 0 0 8px;
  min-height: 100%;
  flex-direction: column;
`;

const SwitchViewButton = styled.div<{ active: boolean }>`
  padding: 12px 28px;
  color: ${(props) => (props.active ? "#FFFFFF" : "#0099CC")};
  background-color: ${(props) => (props.active ? "#0099CC" : "#FFFFFF")};
  font-size: 16px;
  border-radius: 8px;
  width: 180px;
  cursor: pointer;
`;

const RightContainer = styled.div`
  flex: 1;
  width: 100%;
  margin: 12px 24px;
`;

const FileContainer = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ebf7fb;
  border: 1px solid #d7eff7;
  border-radius: 8px;
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`;

const FileTextArea = styled.textarea`
  width: 96%;
  border-radius: 12px;
  border: 1px solid #c1e7f3;
  padding: 12px 16px;
  height: 75px;
  resize: none;
  overflow-y: auto;
  margin-bottom: 24px;
`;

const AddIcon = styled.img`
  margin-bottom: 10px;
`;

const DragText = styled.div`
  font-size: 13px;
  color: #808080;
`;

const SaveNextButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  width: 176px;
  height: 47px;
  border: none;
  cursor: pointer;
  margin-right: 24px;
`;

const ReviewByText = styled.div`
  font-size: 14px;
  color: #808080;
`;

const SaveNextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StartTranscript = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 25px;
  margin-top: 20px;
  align-self: center;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const StartTranscriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectPatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 40px;
  width: 375px;
`;

const SelectGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 40px;
  width: 375px;
`;

const SelectDoctorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 40px;
  width: 375px;
`;

const DropdownLabel = styled.div`
  color: #0099cc;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const SendButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  width: 176px;
  height: 45px;
  border: none;
  cursor: pointer;
  margin-top: 80px;
  margin-bottom: 20px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ReportTabContainer = styled.div`
  padding: 10px 15px;
  background-color: #f5fbfc;
  margin: 20px 0px;
`;
const ReportShowContainer = styled.div`
  background-color: #fff;
  padding: 20px;
`;
const ReviewButtonContainer = styled.div`
  // margin-left: 10px;
  display: flex;
  gap: 4px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;
const AddTempWrapper = styled.div`
  // margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;
const ButtonBase = styled.button<{ active: boolean }>`
  border-radius: ${({ active }) =>
    active ? "8px 8px 0px 0px" : "8px 8px 8px 8px"};
  font-size: 16px;
  width: 145px;
  height: 46px;
  // border: ${({ active }) => (active ? "none" : "1px solid #7ABA56")};
  // background-color: ${({ active }) => (active ? "#7ABA56" : "#FFFFFF")};
  // color: ${({ active }) => (active ? "#FFFFFF" : "#7ABA56")};

  border: ${({ active }) => (active ? "none" : "1px solid #fff")};
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#d7eff7")};
  color: ${({ active }) => (active ? "#30aed7" : "#000")};
  cursor: pointer;
`;

const GroupInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #ebf7fb;
`;

const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

const GroupInfoDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const GroupTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #0099cc;
  padding-bottom: 5px;
`;

const GroupDoctors = styled.p`
  margin: 0;
  font-size: 14px;
  color: #808080;
`;

const DoctorCard = styled.div`
  margin: 10px 10px 0 10px;
`;

const SelectionDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #0099cc;
  border-radius: 8px;
  background-color: #ffffff;
  gap: 20px
`;

const SelectionDivDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const SelectionImage = styled.img`
  width: 50px;
  height: 50px;
`;

const SelectedName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #0099cc;
`;

const SelectedNumber = styled.div`
  font-size: 13px;
  color: #555;
`;

const RightActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const CloseButtonRed = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

type ViewType = "dragUpload" | "typeText" | "autoScribe";

interface Option {
  label: string;
  value: string;
}

const dropdownOptions: Option[] = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

interface Doctor {
  doctor_id: string;
  name: string;
  phone: string | null;
  email: string | null;
}

interface ReviewModalProps {
  open: boolean;
  handleClose: () => void;
  handleSave?: () => void;
  scanData: any;
  isSeriesReview?: boolean;
}

const ReviewModal: React.FC<ReviewModalProps> = ({
  open,
  handleClose: propHandleClose,
  handleSave,
  scanData,
  isSeriesReview,
}) => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const [activeView, setActiveView] = useState<ViewType>("dragUpload");
  const [audioUrl, setAudioUrl] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [isTranscriptionLoading, setTranscriptionLoading] =
    useState<boolean>(false);
  const [mediaObject, setMediaObject] = useState<any>({});
  const [previewReport, setPreviewReport] = useState<any>("");
  const [isPdfMode, setIsPdfMode] = useState(false);
  const reviewedReportRef = useRef<HTMLDivElement>(null);
  const [typedReview, setTypedReview] = useState("");
  const [isMediaObjectSet, setIsMediaObjectSet] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [activeButton, setActiveButton] = useState<any>("typeReport");
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [selectedDoctorItem, setSelectedDoctorItem] = useState<any>(null);

  const [selectedDoctorsList, setSelectedDoctorsList] = useState<Doctor[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [audioBlobWhisper, setAudioBlobWhisper] = useState<File | null>(null);
  const [transcriptData, setTranscriptData] = useState<any>([]);
  const [isTextEditor, setIsTextEditor] = useState(false);
  const templateDownloadRef: any = useRef<HTMLDivElement>();
  const [templateData, setTemplateData] = useState<string>("");

  const [isTemplate, setTemplate] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    dropdownOptions[0].value
  );

  const [checked, setChecked] = useState<boolean>(false);
  const directLink =
    cookies["direct_link"] && JSON.parse(cookies["direct_link"]);

  const [autoPopulateData, setautoPopulateData] = useState({});
  const [isAutoPopulate, setIsautoPopulate] = useState({
    patient: false,
    doctor: false,
  });
  const [isAutoPopulateLoading, setIsAutoPopulateLoading] = useState(false);
  const [groups, setGroups] = useState<{ label: string; value: string }[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupInfo, setGroupInfo] = useState<any>(null);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value);
  };

  const [isTranscriptionComplete, setIsTranscriptionComplete] =
    useState<boolean>(false);
  // const [whispherReview, setWhisperReview] = useState("");
  const [skip, setSkip] = useState<boolean>(false);

  useEffect(() => {
    setIsMediaObjectSet(Object.keys(mediaObject).length > 0);
  }, [mediaObject]);

  useEffect(() => {
    fetchGroups();
  }, []);

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "99%",
    height: "99vh",
    display: "flex",
    flexDirection: "column" as const,
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const contentStyle = {
    display: isPdfMode ? "block" : "none",
  };

  const styles = {
    mainContainer: {
      padding: "20px",
      maxWidth: "600px",
      margin: "auto",
    },
    header: {
      padding: "10px 20px",
      display: "flex",
      flexDirection: "row" as const,
      justifyContent: "center",
      alignItems: "center",
    },
    leftWrapper: {
      flex: 1,
    },
    title: {
      color: "#0099CC",
    },
    subTitle: {
      color: "#808080",
    },
    consultationSummary: {
      backgroundColor: "#F5FBFC",
      padding: "15px 20px",
      borderRadius: "5px",
      margin: "20px 0",
    },
    sectionTitle: {
      fontWeight: "bold",
      textAlign: "center" as const,
      backgroundColor: "#0099CC",
      color: "#ffffff",
      padding: "10px",
    },
    contentBlock: {
      margin: "10px 0",
    },
  };

  useEffect(() => {
    setTypedReview(transcriptData[0]);
  }, [transcriptData]);

  useEffect(() => {
    if (open) {
      setAudioUrl(null);
    }
  }, [open]);

  const renderCheckBox = () => (
    <>
      <div>
        <Checkbox
          checked={checked}
          sx={{ padding: 0 }}
          onChange={handleDirectLinkChange}
          disabled={isSendLoading}
        />
      </div>
      <Box ml={1}>Allow Direct Link</Box>
    </>
  );

  const formattedDate = (dateString: string) => {
    if (!dateString) {
      return "";
    }
    return moment(dateString).format("DD-MMM-YYYY hh:mm a");
  };

  const handleViewChange = (view: ViewType) => {
    setActiveView(view);
    setIsMediaObjectSet(false);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const addAudioElement = (blob: any) => {
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        uploadFile(files[i]);
      }
    }
    (event.target as HTMLInputElement).value = "";
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const fileItem = event.dataTransfer.items[i];
        if (fileItem.kind === "file") {
          const file = fileItem.getAsFile();
          if (file) {
            uploadFile(file);
          }
        }
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const uploadFile = (file: File) => {
    setIsLoading(true);
    const token = cookies["access_token"];
    var formData = new FormData();
    formData.append("files", file);
    formData.append("file_type", "report");
    axios
      .post(API_URL_UPLOAD_REPORTS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.data) {
          setMediaObject(response?.data?.result);
          setPreviewReport(file);
        } else {
          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const startTranscription = async () => {
    if (audioBlobWhisper) {
      setTranscriptionLoading(true);
      const token = cookies["access_token"];
      var formData = new FormData();
      formData.append("audio_file", audioBlobWhisper);
      axios
        .post(`${API_URL}/audio/whisper/summary`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          if (response && response.data.status === "success") {
            setTranscriptData(response?.data?.data?.transcript_data);
            setTranscriptionLoading(false);
            setIsTranscriptionComplete(true);
          } else {
            alert("Something went wrong!");
          }
        })
        .catch((error) => {
          setTranscriptionLoading(false);
          console.log(error);
        });
    }
  };

  const handleClose = () => {
    setIsMediaObjectSet(false);
    setSkip(false);
    setIsTranscriptionComplete(false);
    setSelectedPatient(null);
    setSelectedDoctor(null);
    setSelectedPatient(null);
    setSelectedDoctorItem(null);
    setMediaObject({});
    setTypedReview("");
    setIsTextEditor(false);
    setSelectedStatus(dropdownOptions[0].value);
    setChecked(false);
    setSelectedGroup(""); // Reset group selection
    setGroupInfo(null); // Clear group info
    setSelectedDoctorsList([]); // Clear doctors list
    if (propHandleClose) {
      propHandleClose();
    }
    setautoPopulateData({});
    setIsautoPopulate({ patient: false, doctor: false });
  };

  const handleSaveReview = async () => {
    if (reviewedReportRef.current) {
      const options = {
        margin: [10, 0],
        filename: "reviewed-report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      setIsPdfMode(true);
      const reviewReportBlob = await html2pdf()
        .from(reviewedReportRef.current)
        .set(options)
        .outputPdf("blob");
      setIsPdfMode(false);

      // Create a temporary link to trigger download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(reviewReportBlob);
      downloadLink.download = "reviewed-report.pdf";

      // Append to the body, click, and remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      const reviewedFile = new File([reviewReportBlob], "reviewed-report.pdf", {
        type: "application/pdf",
      });
      uploadFile(reviewedFile);
    } else {
      console.error("Reviewed report ref is null");
    }
  };

  const removeDoctorFromList = (index: number) => {
    let tempList = [...selectedDoctorsList];
    tempList.splice(index, 1);
    setSelectedDoctorsList((prevstate) => [...tempList]);
  };

  const removeSelectedPatient = () => {
    setSelectedPatient(null);
  };

  const getDoctorIds = () => {
    let doctorIds = selectedDoctorsList.map(doctor => doctor.doctor_id);
    if (selectedDoctor && selectedDoctor.id) {
      doctorIds.push(selectedDoctor.id);
    }
    return doctorIds;
  };  

  const handleSend = async (patientId: any) => {
const sendUrl = scanData?.dicom_path ? `${API_URL}/unsent/study/send` : `${API_URL}/add_data/scans/pacs`
    if (patientId) {
      setIsSendLoading(true);
      try {
        const token = cookies["access_token"];
        let payload_pacs;
        let payload_report;
  
        // Get the array of doctor IDs, remove duplicates using a Set
        const doctorIdsSet = new Set(getDoctorIds());
        const doctorIds = Array.from(doctorIdsSet);
  
        if (selectedGroup) {
          payload_pacs = {
            file_info: {
              study_id: scanData?.study_id,
              series_id: isSeriesReview ? scanData?.series_id : null,
              status: selectedStatus,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
            share_with: doctorIds.map(doctor_id => ({ doctor_id })),
          };
        } else if (selectedDoctor && selectedDoctor.id) {
          payload_pacs = {
            file_info: {
              study_id: scanData?.study_id,
              series_id: isSeriesReview ? scanData?.series_id : null,
              status: selectedStatus,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
            share_with: [
              {
                doctor_id: selectedDoctor.id,
              },
            ],
          };
        } else {
          payload_pacs = {
            file_info: {
              study_id: scanData?.study_id,
              status: selectedStatus,
              series_id: isSeriesReview ? scanData?.series_id : null,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
          };
        }

        let payload_pacs_with_diaco_path;
        if (selectedGroup) {
          payload_pacs_with_diaco_path = {
            file_info: {
              study_id: scanData?.study_id,
              study_path: scanData?.dicom_path,
              status: selectedStatus,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
            share_with: doctorIds.map(doctor_id => ({ doctor_id })),
          };
        } else if (selectedDoctor && selectedDoctor.id) {
          payload_pacs_with_diaco_path = {
            file_info: {
              study_id: scanData?.study_id,
              study_path: scanData?.dicom_path,
              status: selectedStatus,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
            share_with: [
              {
                doctor_id: selectedDoctor.id,
              },
            ],
          };
        } else {
          payload_pacs_with_diaco_path = {
            file_info: {
              study_id: scanData?.study_id,
              study_path: scanData?.dicom_path,
              status: selectedStatus,
              direct_viewer_link: checked,
            },
            patient_id: patientId,
          };
        }
  
        if (isMediaObjectSet) {
          mediaObject["0"]["status"] = selectedStatus;
          mediaObject["0"]["direct_viewer_link"] = checked;
          payload_report = {
            patient_id: patientId,
            doctor_ids: doctorIds,
            report_info: mediaObject,
          };
          const response = await axios.post(
            `${API_URL}/add_data/report/add`,
            payload_report,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response && response.data.status === "success") {
            if (selectedDoctorItem && Object.keys(selectedDoctorItem)?.length) {
              localStorage.setItem(
                "lastShareDoctorItem",
                JSON.stringify(selectedDoctorItem)
              );
            }
            setIsTextEditor(false);
            setSelectedStatus(dropdownOptions[0].value);
            setChecked(false);
            setSelectedGroup(""); // Reset group selection
            setGroupInfo(null); // Clear group info
            setSelectedDoctorsList([]); // Clear doctors list
            setIsMediaObjectSet(false);
            setSkip(false);
            setIsTranscriptionComplete(false);
            setSelectedPatient(null);
            setSelectedDoctor(null);
            setSelectedDoctorItem(null);
            setTypedReview("");
            setSelectedStatus(dropdownOptions[0].value);
            setMediaObject({});
            if (propHandleClose) {
              propHandleClose();
            }
            setIsSendLoading(false);
            dispatch(
              setShowStatus({
                message: "Scan review request accepted",
                severity: "success",
              })
            );
          } else {
            alert("Something went wrong!");
          }
        }
  
        const response = await axios.post(
          sendUrl,
          scanData?.dicom_path ? payload_pacs_with_diaco_path :payload_pacs,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.data.status === "success") {
          if (selectedDoctorItem && Object.keys(selectedDoctorItem)?.length) {
            localStorage.setItem(
              "lastShareDoctorItem",
              JSON.stringify(selectedDoctorItem)
            );
          }
          setIsTextEditor(false);
          setSelectedStatus(dropdownOptions[0].value);
          setChecked(false);
          setSelectedGroup(""); // Reset group selection
          setGroupInfo(null); // Clear group info
          setSelectedDoctorsList([]); // Clear doctors list
          setIsMediaObjectSet(false);
          setSkip(false);
          setIsTranscriptionComplete(false);
          setSelectedPatient(null);
          setSelectedDoctor(null);
          setSelectedDoctorItem(null);
          setTypedReview("");
          setSelectedStatus(dropdownOptions[0].value);
          setMediaObject({});
          if (propHandleClose) {
            propHandleClose();
          }
          setIsSendLoading(false);
          dispatch(
            setShowStatus({
              message: "Scan review request accepted",
              severity: "success",
            })
          );
        } else {
          setIsSendLoading(false);
          alert("Something went wrong!");
        }
      } catch (error) {
        console.error(error);
        setIsSendLoading(false);
      }
    } else {
      console.error("Patient ID is missing.");
    }
  };    

  const handleSelectPatient = (textValue: any) => {
    setSelectedPatient({
      contact_number: textValue.number,
      id: textValue?.id,
      name: textValue?.name,
    });
  };

  const setlastShareDoctor = (data: any) => {
    setSelectedDoctorItem(data);
  };

  const renderRightContainerContent = () => {
    return (
      <FileContainer>
        <AddIcon
          src={addIcon}
          onClick={openFilePicker}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          draggable
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept=".pdf"
          multiple
        />
        <DragText>Drag report to add</DragText>
      </FileContainer>
    );
  };

  /////generate template

  const formatTimestamp = (timestamp: any) => {
    return moment(timestamp).format("DD-MMM-YYYY, hh:mm a");
  };

  const saveTemplate = (content: string) => {
    setIsLoading(true);
    setTemplateData(content);
    setTemplate(true);

    setTimeout(() => {
      onDownload();
    }, 2000);
  };

  const onDownload = async () => {
    setIsLoading(true);

    templateDownloadRef.current.style.display = "block";
    const summaryBlob = await html2pdf()
      .from(templateDownloadRef.current)
      .set(optionsSummary)
      .outputPdf("blob");
    const summaryFile = new File([summaryBlob], "report.pdf", {
      type: "application/pdf",
    });
    uploadFile(summaryFile);
    templateDownloadRef.current.style.display = "none";
    setTemplate(false);

    // openPdfInNewTab(summaryFile);
  };

  const openPdfInNewTab = (file: any) => {
    const blobUrl = URL.createObjectURL(file);
    window.open(blobUrl, "_blank");
    templateDownloadRef.current.style.display = "none";
  };

  const optionsSummary = {
    margin: [5, 0],
    filename: "summary.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  const handleDirectLinkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
  };

  const onAutoPopulate = () => {
    fetchAutoPopulateData();
  };

  const fetchAutoPopulateData = async () => {
    setIsAutoPopulateLoading(true);
    const token = cookies["access_token"];
    const url = `${API_URL}/unsent/study/extract`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      study_url: scanData?.study_url,
    };
    const payloadDiacomPath ={
      dicom_path:scanData?.dicom_path,
      study_id:scanData?.study_id
    }
    try {
      const response = await axios.post(url, scanData?.study_url?payload:payloadDiacomPath, config);
      if (response?.data?.status === "success") {
        setautoPopulateData(response.data.data);

        setIsautoPopulate({
          patient: Object.keys(response.data.data?.patient_data)?.length
            ? true
            : false,
          doctor: response.data.data?.doctor_data?.id ? true : false,
        });
        setIsAutoPopulateLoading(false);
        if (response?.data?.data) {
          setSelectedDoctor({
            id: response.data.data?.doctor_data?.id || null,
          });
          setSelectedPatient({
            id: response.data.data?.patient_data?.patient_fhir_id || null,
          });
          setSelectedDoctorItem(
            (Object.keys(response.data.data?.doctor_data).length &&
              response.data.data?.doctor_data) ||
              null
          );
        }
      }
    } catch (error) {
      console.error(error);
      setautoPopulateData({});
      setIsautoPopulate({ patient: false, doctor: false });
      setIsAutoPopulateLoading(false);
    }
  };

  const fetchGroups = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (response.data.status === "success" && response.data.data) {
          const groupOptions = response.data.data.map((group: any) => ({
            label: group.group_name,
            value: group.group_id,
          }));
          setGroups(groupOptions);
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDoctors = async (groupId: string) => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group/${groupId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(endpointUrl, config);
      if (response.data.status === "success" && response.data.data) {
        setGroupInfo(response.data.data.group_info);
        setSelectedDoctorsList(response.data.data.doctors_list);
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGroupChange = async (event: SelectChangeEvent<string>) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    await fetchDoctors(groupId);
  };   

  useEffect(() => {
    if (isMediaObjectSet || skip) {
      onAutoPopulate();
    }
  }, [isMediaObjectSet, skip]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <HeaderWrapper>
            <CloseButton src={closeIcon} onClick={handleClose} />
          </HeaderWrapper>

          <ModalBody>
            <FileName>File: {scanData?.name}</FileName>
            {!isLoading ? (
              scanData &&
              mediaObject &&
              (isMediaObjectSet ? (
                <>
                  <MainContainer>
                    <ListContainer>
                      <ThumbnailContainer>
                        <ScansThumbnail src={scanData?.thumbnail} />
                        <PatientInfo>
                          <PatientName>{scanData?.patient_name}</PatientName>
                          <PatientGender>
                            {scanData?.patient_gender === "F"
                              ? "Female"
                              : scanData?.patient_gender === "M"
                              ? "Male"
                              : ""}
                            {scanData?.patient_age
                              ? `, ${scanData?.patient_age}`
                              : ""}
                          </PatientGender>
                          <StudyDate>
                            {formattedDate(scanData?.series_date)}
                          </StudyDate>
                        </PatientInfo>
                      </ThumbnailContainer>
                    </ListContainer>
                    {mediaObject[0]?.file_name && (
                      <Box display="flex" alignItems="center">
                        <PlusSign>+</PlusSign>
                        <Box ml={2}>
                          <ListContainerReport>
                            <ThumbnailContainer>
                              <ScansThumbnail src={ScansIcon} />
                              <PatientInfo>
                                <PatientName>
                                  {mediaObject[0]?.file_name}
                                </PatientName>
                                {/* <DoctorReviewer>Reviewed by: Dr Dindayal Updadhyay</DoctorReviewer> */}
                              </PatientInfo>
                            </ThumbnailContainer>
                            <SendButton
                              disabled={!previewReport}
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #0099cc",
                                color: "#0099cc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0 0 0 5px",
                                fontSize: "13px",
                                width: "93px",
                              }}
                              onClick={() => openPdfInNewTab(previewReport)}
                            >
                              <img
                                src={ViewIcons}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  width: "15px",
                                }}
                              />{" "}
                              <span>Preview</span>
                            </SendButton>
                          </ListContainerReport>
                        </Box>
                      </Box>
                    )}
                    <Box ml={4}>
                      <StatusDropdown
                        selectedStatus={selectedStatus}
                        handleChange={handleChange}
                        dropdownOptions={dropdownOptions}
                        disabled={isSendLoading}
                      />
                    </Box>
                  </MainContainer>
                  {directLink && (
                    <Box display="flex" alignItems="center" mt={2}>
                      {renderCheckBox()}
                    </Box>
                  )}
                </>
              ) : (
                <Box display="flex" flexDirection="column">
                  <ListContainer>
                    <ThumbnailContainer>
                      <ScansThumbnail src={scanData?.thumbnail} />
                      <PatientInfo>
                        <PatientName>{scanData?.patient_name}</PatientName>
                        <PatientGender>
                          {scanData?.patient_gender === "F"
                            ? "Female"
                            : scanData?.patient_gender === "M"
                            ? "Male"
                            : ""}
                          {scanData?.patient_age
                            ? `, ${scanData?.patient_age}`
                            : ""}
                        </PatientGender>
                        <StudyDate>
                          {formattedDate(scanData?.series_date)}
                        </StudyDate>
                      </PatientInfo>
                    </ThumbnailContainer>
                    <UidContainer>
                      <UidText>UID</UidText>
                      <UIDValue>
                        {isSeriesReview
                          ? scanData?.series_id
                          : scanData?.study_id}
                      </UIDValue>
                    </UidContainer>
                    {/* <SeriesContainer>
                                    <SeriesText>Series</SeriesText>
                                    <SeriesValue>{scanData?.series_number}</SeriesValue>
                                </SeriesContainer> */}
                    <ModalityContainer>
                      <ModalityText>Modality</ModalityText>
                      <ModalityValue>{scanData?.modality_list}</ModalityValue>
                    </ModalityContainer>
                    <InstancesContainer>
                      <InstancesText>Instances</InstancesText>
                      <InstancesValue>
                        {scanData?.instance_count}
                      </InstancesValue>
                    </InstancesContainer>
                    <DescriptionContainer>
                      <DescriptionText>Description</DescriptionText>
                      <DescriptionValue>
                        {scanData?.study_description}
                      </DescriptionValue>
                    </DescriptionContainer>
                  </ListContainer>
                  {skip && (
                    <Box display="flex" alignItems="start" mt={2}>
                      <StatusDropdown
                        selectedStatus={selectedStatus}
                        handleChange={handleChange}
                        dropdownOptions={dropdownOptions}
                      />
                      {directLink && (
                        <Box display="flex" alignItems="center" ml={6}>
                          {renderCheckBox()}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <SimpleLoader height="15" />
            )}

            {!isMediaObjectSet && !skip ? (
              <div>
                <ReportTabContainer>
                  <ReviewButtonContainer>
                    <AddTempWrapper>
                      <ButtonBase
                        active={activeButton === "dragupload"}
                        onClick={() => {
                          setActiveButton("dragupload");
                        }}
                      >
                        Drag & Upload
                      </ButtonBase>
                    </AddTempWrapper>
                    <AddTempWrapper>
                      <ButtonBase
                        active={activeButton === "typeReport"}
                        onClick={() => {
                          setActiveButton("typeReport");
                        }}
                      >
                        Type Report
                      </ButtonBase>
                    </AddTempWrapper>
                    <SkipReview onClick={() => setSkip(true)}>
                      SKIP →
                    </SkipReview>
                  </ReviewButtonContainer>
                  <ReportShowContainer>
                    {activeButton === "dragupload" ? (
                      <>
                        <ReviewReportContainer>
                          <HeaderContainer></HeaderContainer>
                          <AddReportContainer>
                            <RightContainer>
                              {renderRightContainerContent()}
                            </RightContainer>
                          </AddReportContainer>
                        </ReviewReportContainer>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "80%",
                            alignSelf: "center",
                            margin: "0 auto",
                          }}
                        >
                          <TextEditor
                            isReviewModal={true}
                            isLoadingData={isLoading}
                            scanData={scanData}
                            setContent={saveTemplate}
                          />
                        </div>
                        {isTemplate ? (
                          <div style={{ marginTop: "400px" }}>
                            <div
                              style={{
                                padding: "5px",
                                transform: "scale(0.98)",
                              }}
                              ref={templateDownloadRef}
                              dangerouslySetInnerHTML={{
                                __html: templateData,
                              }}
                            ></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </ReportShowContainer>
                </ReportTabContainer>
              </div>
            ) : !isAutoPopulateLoading ? (
              <ReviewReportContainer>
                <ReviewReportHeader>Send Report</ReviewReportHeader>
                <SendParentContainer>
                  <SendWrapperContainer>
                    <SendReportContainer>
                      <SelectPatientContainer>
                        <DropdownLabel>
                          <img
                            src={patientReportIcon}
                            style={{ width: "16px" }}
                          />{" "}
                          Patient
                        </DropdownLabel>
                        <AtlassianDropdown
                          type={"patient"}
                          isAutoPopulate={isAutoPopulate}
                          autoPopulateData={autoPopulateData}
                          setIsautoPopulate={setIsautoPopulate}
                          setautoPopulateData={setautoPopulateData}
                          setSelectedPatient={setSelectedPatient}
                          setSelectedDoctor={setSelectedDoctor}
                        />
                      </SelectPatientContainer>
                      <SelectPatientContainer>
                        <DropdownLabel>
                          <img src={DoctorIcon} style={{ width: "16px" }} />{" "}
                          Doctor (optional)
                        </DropdownLabel>
                        <AtlassianDropdown
                          type={"doctor"}
                          isAutoPopulate={isAutoPopulate}
                          autoPopulateData={autoPopulateData}
                          setIsautoPopulate={setIsautoPopulate}
                          setautoPopulateData={setautoPopulateData}
                          setSelectedPatient={setSelectedPatient}
                          setSelectedDoctor={setSelectedDoctor}
                          setSelectedDoctorItem={(data) =>
                            setlastShareDoctor(data)
                          }
                        />
                      </SelectPatientContainer>
                      <SelectGroupsContainer>
                        <DropdownLabel>
                          <img src={groupProfileIcon} style={{ width: "16px", height: "20px" }} /> Group (optional)
                        </DropdownLabel>
                        {selectedGroup === "" || selectedDoctorsList.length === 0 ? (
                          <>
                          <FormControl fullWidth>
                            <Select
                              labelId="group-select-label"
                              id="group-select"
                              value={selectedGroup}
                              onChange={handleGroupChange}
                              displayEmpty
                              renderValue={selectedGroup !== "" ? undefined : () => <span>Select a group</span>}
                            >
                              <MenuItem value="">
                                <span>Select a group</span>
                              </MenuItem>
                              {groups.map((group) => (
                                <MenuItem key={group.value} value={group.value}>
                                  {group.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {selectedGroup === "" || selectedDoctorsList.length === 0 && (
                            <p style={{ marginLeft: "20px" }}>No doctors present in this group</p>      
                          )}
                          </>
                        ) : (
                          groupInfo && (
                            <GroupInfoCard>
                              <GroupHeader>
                                <GroupInfoDetails>
                                  <SelectionImage src={groupIcon} alt="Doctor" />
                                  <SelectionDivDetails>
                                    <GroupTitle>{groupInfo.group_name}</GroupTitle>
                                    <GroupDoctors>{groupInfo.number_of_doctors} Doctors</GroupDoctors>
                                  </SelectionDivDetails>
                                </GroupInfoDetails>
                                <RightActions>
                                  <CloseButtonRed
                                    src={closeIconRed}
                                    onClick={() => {
                                      setSelectedGroup("");
                                      setGroupInfo(null);
                                      setSelectedDoctorsList([]);
                                    }}
                                  />
                                </RightActions>
                              </GroupHeader>
                              {selectedDoctorsList.length === 0 ? (
                                <p>No doctors present in this group</p>
                              ) : (
                                selectedDoctorsList.map((doctor) => (
                                  <DoctorCard key={doctor.doctor_id}>
                                    <SelectionDiv>
                                      <SelectionImage src={IProfileImageSmall} alt="Doctor" />
                                      <SelectionDivDetails>
                                        <SelectedName>{doctor.name}</SelectedName>
                                        <SelectedNumber>{doctor.phone}</SelectedNumber>
                                        <SelectedNumber>{doctor.email}</SelectedNumber>
                                      </SelectionDivDetails>
                                      <RightActions>
                                        <CloseButtonRed
                                          src={closeIconRed}
                                          onClick={() =>
                                            removeDoctorFromList(selectedDoctorsList.indexOf(doctor))
                                          }
                                        />
                                      </RightActions>
                                    </SelectionDiv>
                                  </DoctorCard>
                                ))
                              )}
                            </GroupInfoCard>
                          )
                        )}
                      </SelectGroupsContainer>
                    </SendReportContainer>
                  </SendWrapperContainer>
                  <div style={{ marginLeft: "120px" }}>
                    <SendButton
                      disabled={!selectedPatient}
                      onClick={() => handleSend(selectedPatient?.id)}
                    >
                      {isSendLoading ? (
                        <CircularProgress size={26} color="inherit" />
                      ) : (
                        "Send"
                      )}
                    </SendButton>
                  </div>
                </SendParentContainer>
              </ReviewReportContainer>
            ) : (
              <div style={{marginTop:'120px'}}>
              <SimpleLoader height="15"  text={"Populating patient and doctor data"}/>
              </div>
            )}
          </ModalBody>
        </Box>
      </Modal>
      <div ref={reviewedReportRef} style={contentStyle}>
        <header style={styles.header}>
          <div style={styles.leftWrapper}>
            <h1 style={styles.title}>Dr. HemeHealth</h1>
            <p style={styles.subTitle}>MBBS, MD, General Physician</p>
          </div>
          <img src={ILogoWhite} />
        </header>
        <h2 style={styles.sectionTitle}>Scans Report Review</h2>
        <div style={styles.consultationSummary}>
          <div style={styles.contentBlock}>{typedReview}</div>
        </div>
      </div>
    </>
  );
};

export default ReviewModal;
