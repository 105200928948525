import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import UploadFiles from "../UploadFiles/UploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL, DEVICE } from "../../../utilities/constants";
import SimpleLoader from "../Loaders/SimpleLoader";
import { VerticalSep } from "../../styled/Gaps";
import { setShowStatus } from "../../../store/slices/statusSlice";
import { incrementCount } from "../../../store/slices/reportsReloadSlice";
import SelectField from "../SelectField/SelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { removeCountryCode91 } from "../../../utilities/helper";
import closeIcon from "../../../assets/close-icon.png"
import PhoneNumberValidation from "../PhoneNumberValidation/PhoneNumberValidation";

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
];

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
const CustomSelect = styled.select({
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "45px",
  flexGrow: 1,
  paddingLeft: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});
const MobileNumWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
`;
const CustomInput = styled(Input)`
  flex-grow: 1;
  margin-left: 10px;
  &.MuiInputBase-root {
    width: calc(100% - 60px);
  }
`;

export interface PatientAddEditProps {
  open: boolean;
  testId: string | null;
  testName?: string | null | undefined;
  testPrice?: string | null | undefined;
  closeHandler: () => void;
}

const AddEditPatient = ({
  open,
  closeHandler,
  testId,
  testName,
  testPrice,
}: PatientAddEditProps) => {
  const maxNameLength = 35;
  const namePrefix = "";
  const [cookies, setCookie, removeCookie] = useCookies();

  const [notValidInput, setNotValidInput] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string>("");
  const [patientEmail, setPatientEmail] = useState<string>("");

  const [patientNumber, setPatientNumber] = useState<any| null>();
  const [selectedGender, setSelectedGender] = useState('');
  const [userDob, setUserDob] = useState("")
  const [patientProfileData, setPatientProfileData] = useState<any>()
  const [selectedCountry, setSelectedCountry] = useState(""); 
  const [isFormEdited, setIsFormEdited] = useState(false);

  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (getSearchParam("patient_fhir_id")) {
      fetchPatientData()
    }
  }, [searchParams])

  useEffect(() => {
    console.log('Raw countryData cookie value:', cookies.countryData);
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
      }
    } catch (error) {
        console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  const handlePhoneNumberChange = (newPhoneNumber: any) => {
    setPatientNumber(newPhoneNumber);
  };

  // useEffect(() => {
  //   if (testPrice != patientNumber?.toString()) {
  //   //   if (patientNumber) {
        
  //   //     if (
  //   //       patientNumber.toString().length >= 12 
  //   //     ) {
  //   //     console.log("sdmfbjhsgfjsgfjsgdj",patientNumber);

  //   //       setNotValidInput(false);
  //   //       setErrorMsg("")
  //   //     } else {
  //   //       setNotValidInput(true);
  //   //       setErrorMsg("Please enter a valid mobile number")
  //   //     }
  //   //   } else {
  //   //     setNotValidInput(true);
  //   //     setErrorMsg("*Required a valid mobile number")
  //   //   }
  //   // } else {
  //   //   setNotValidInput(true);
  //   // }
  // }, [patientNumber]);

  const checkFormEdited = (name: any, gender: any, dob: any) => {
    const edited = name !== patientProfileData?.name || gender !== patientProfileData?.gender || dob !== patientProfileData?.dob;
    setIsFormEdited(edited);
  };
  
  const handleNameChange = (event: any) => {
    setPatientName(event.target.value);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleEmailChange = (event: any) => {
    setPatientEmail(event.target.value);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleGenderChange = (selectedValue: string) => {
    setSelectedGender(selectedValue);
    setIsFormEdited(true);
    setNotValidInput(false);
  };

  const handleDobChange = (newValue: any) => {
    setUserDob(newValue);
    setIsFormEdited(true);
    setNotValidInput(false);
  };
  
  const handleSave = () => {
    // if (notValidInput) {
    //   return;
    // }
    const isEmail=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(patientEmail)
    const isNumber = patientNumber && patientNumber.toString().length >= 12 


    if((patientEmail  && isEmail && (patientNumber?.toString().length < 3 || !patientNumber )) || (!patientEmail && patientNumber && isNumber) || (patientEmail  && isEmail && patientNumber && isNumber) ){
    setErrorMsg("")

      setIsLoading(true);

      var jsonData: any = {
        name: namePrefix + patientName,
      };

      if(!!patientNumber && patientNumber.toString().length >= 12){
        jsonData.contact_number=  patientNumber && patientNumber.toString().length < 3 ?"" :"+"+patientNumber
      }
  
      if(!!selectedGender){
        jsonData.gender = selectedGender 
      }
      if(!!userDob){
        jsonData.date_of_birth = moment(userDob).format("YYYY-MM-DD") 
      }
      if(!!patientEmail){
        jsonData.email = patientEmail 
  
      }
  
      axios
        .post(`${API_URL}/patient`, jsonData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies["access_token"]}`,
          },
        })
        .then((response) => {
          console.log(response);
          // dispatch(incrementCount());
          closeHandler();
          if(getSearchParam("fromSendReport")){
            // searchParams.delete("fromSendReport");
            // setSearchParams(searchParams);
          } else {
            window.location.reload();
          }
          dispatch(
            setShowStatus({
              message: "Patient added successfully",
              severity: "success",
            })
          );
  
        })
        .catch((err) => {
          // Handle success - report not sent, and something else
          let message = "patient creation failed";
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            message = "Failure. " + err.response.data.message;
          }
          setIsLoading(false);
        setErrorMsg("")

          dispatch(
            setShowStatus({
              message: message,
              severity: "error",
              autoHide: "no",
            })

          );
        })
        .finally(() => {
          setIsLoading(false);
        setErrorMsg("")

        });

    }
    else{

      if (patientNumber && !isNumber ) {
          setErrorMsg("Please enter a valid  mobile number")
        } 
      
      if(patientEmail && !isEmail) {
        setErrorMsg("Please enter a valid email")

      }
      if(!patientEmail && !patientNumber){
          setErrorMsg("*Required a valid mobile number or Email")
  
      }
      }
   
  };

  const handleEdit = () => {
    
    setIsLoading(true);

    var jsonData: any =
{
      "id": getSearchParam("patient_fhir_id"),
      "name": patientName,
    }

    if(!!selectedGender){
      jsonData.gender = selectedGender 
    }
    if(!!userDob){
      jsonData.date_of_birth = moment(userDob).format("YYYY-MM-DD") 
    }
    if(!!patientEmail){
      jsonData.email = patientEmail 

    }

    axios
      .patch(`${API_URL}/patient`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies["access_token"]}`,
        },
      })
      .then((response) => {
        console.log(response);
        // dispatch(incrementCount());
        closeHandler();
        window.location.reload();
        dispatch(
          setShowStatus({
            message: "Patient updated successfully",
            severity: "success",
          })
        );

      })
      .catch((err) => {
        // Handle success - report not sent, and something else
        let message = "patient updation failed";
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          message = "Failure. " + err.response.data.message;
        }
        setIsLoading(false);
        dispatch(
          setShowStatus({
            message: message,
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const fetchPatientData = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/patient/${getSearchParam("patient_fhir_id")}/profile`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (
          response.data &&
          response.data?.status == "success" &&
          response.data?.data
        ) {
          setPatientNumber(response.data?.data?.phone_number)
          setPatientName(response.data?.data?.name || "")
          setPatientEmail(response.data?.data?.email || "");
          setPatientProfileData(response.data?.data);
          setIsFormEdited(false);
          if (response.data?.data?.gender)
            setSelectedGender(response.data?.data?.gender)
          if (response.data?.data?.dob)
            // setUserDob(moment(response.data?.data?.dob).format("YYYY-MM-DD"))
            // setUserDob(moment(response.data?.data?.dob).format("DD-MM-YYYY"))
            setUserDob(response.data?.data?.dob)
        } else {
          alert("Something went wrong!!")
        }
      })
      .catch((error) => {
        console.log(error);
      })
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle
        sx={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        {getSearchParam("patient_fhir_id") ? "Edit" : "Add"} Patient
      </DialogTitle>
      <DialogContent
        sx={{
          justifyContent: "flex-start",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={()=>{
            if(getSearchParam("fromSendReport")){
              // searchParams.delete("fromSendReport");
              // setSearchParams(searchParams);
            } 
            closeHandler()
          }}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <img height={40} width={40} src={closeIcon} style={{}} />
        </IconButton>
        <div style={{}}>
          {!isLoading ? (
            <TestForm>
              <VerticalSep size={10} />
              <TextFieldLabel>Mobile No.</TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <MobileNumWrapper>
                  <PhoneNumberValidation
                    onPhoneNumberChange={handlePhoneNumberChange}
                    selectedCountry={selectedCountry}
                    initialPhoneNumber={patientNumber}
                    disabled={getSearchParam("patient_fhir_id")?true:false}
                    
                  />
                </MobileNumWrapper>
              </FormControl>
              <VerticalSep size={20} />
              <TextFieldLabel>
                Patient Name{" "}
                <span style={{ fontSize: "12px" }}>
                  (max {maxNameLength} characters)
                </span>{" "}
                
              </TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <div style={{ marginRight: "10px" }}>{namePrefix}</div>
                <Input
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                    flexGrow: 1,
                  }}
                  onChange={handleNameChange}
                  value={patientName}
                  placeholder={""}
                />
              </FormControl>
              <VerticalSep size={20} />

              <TextFieldLabel>
                Email{" "}
               
              </TextFieldLabel>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="outlined"
              >
                <div style={{ marginRight: "10px" }}>{namePrefix}</div>
                <Input
                  disableUnderline={true}
                  sx={{
                    height: "45px",
                    backgroundColor: "#EBF7FB",
                    paddingLeft: "10px",
                    borderRadius: "8px",
                    flexGrow: 1,
                  }}
                  onChange={handleEmailChange}
                  value={patientEmail}
                  placeholder={""}
                  disabled={getSearchParam("patient_fhir_id")?true:false}

                />
              </FormControl>

              <VerticalSep size={20} />

              <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <TextFieldLabel>
                    Gender (Optional){" "}
                  </TextFieldLabel>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    variant="standard"
                  >
                    <div style={{
                      overflow: 'hidden',
                      backgroundColor: "#EBF7FB",
                      flexGrow: 1,
                    }}>
                      <SelectField
                        options={genderOptions}
                        onChange={handleGenderChange}
                        selectedValue={selectedGender}
                        label=""
                        
                      />
                    </div>
                  </FormControl>
                </div>
                <div style={{ width: 20 }}></div>
                <div style={{ flexGrow: 1, }}>
                  <TextFieldLabel>
                    Date of Birth (Optional){" "}
                  </TextFieldLabel>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <div style={{ backgroundColor: "#EBF7FB" }}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          inputFormat="DD-MMM-YYYY"
                          disableFuture
                          label=""
                          value={userDob || null}
                          onChange={handleDobChange} 
                          InputProps={{
                            placeholder: "DD-MMM-YYYY"
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                svg: { color: '#7BBA56' },
                                ".MuiInputBase-input": {
                                  backgroundColor: '#EBF7FB',
                                  borderColor: '#fff',
                                },

                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#fff",
                                },
                                "&:hover > .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#fff"
                                },

                              }}
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </FormControl>
                </div>
              </div>


              <VerticalSep size={10} />
            </TestForm>
          ) : (
            <SimpleLoader height="100%" size={20} />
          )}
        </div>
        <span style={{ fontSize: "10px", color: 'red' }}>
          {errorMsg}
        </span>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "flex-start",
          padding: "0 20px 20px 20px",
          margin: "0px 30px",
          [`@media ${DEVICE.mobileL}`]: {
            margin: "0px 5px",
          },
        }}
      >
        <Button
          onClick={() => {
            if (getSearchParam("patient_fhir_id")) {
              handleEdit()
            }
            else handleSave()
          }}
          disabled={((!patientEmail && !patientNumber) )?true:false}
          sx={{ color: "#FFF", width: 150 }}
          variant="contained"
        >
          {getSearchParam("patient_fhir_id") ? "Update" : "Save"}
        </Button>
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default AddEditPatient;