import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReportsList, getThumbnails } from "../../apis/report";
import { setShowStatus, unsetShowStatus } from "../slices/statusSlice";

interface GetRecordsData {
  patient_id: string;
  report_type: string;
  limit: number;
  page_token?: string;
}

interface GetRecordsPayload {
  status: string;
  data: [];
}

interface GetRecordsResponse {
  type: string;
  payload: GetRecordsPayload;
}

export const getRecordsAction = createAsyncThunk<
  GetRecordsResponse,
  GetRecordsData,
  { rejectValue: string }
>("get-records", async (data, thunkAPI) => {
  try {
    thunkAPI.dispatch(
      setShowStatus({
        message: `Fetching records`,
        severity: "info",
        autoHide: "no",
      })
    );
    const response = await getReportsList(data);
    thunkAPI.dispatch(unsetShowStatus());
    return response;
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      thunkAPI.dispatch(
        setShowStatus({
          message: error.message || `Error while fetching ${data.report_type}`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error.message || `Error while fetching ${data.report_type}`
      );
    }
  }
});

interface ThumbnailResponse { 
  file_objects: {
    fhir_id: string | null;
    file_path: string | null;
  }[];
}

export const getThumbnailURL = createAsyncThunk(
  "get_thumbnail_image",
  async (data: ThumbnailResponse, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching thumbnail",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getThumbnails(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while get thumbnail",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while get thumbnail"
      );
    }
  }
);