import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useDispatch } from 'react-redux'

import reportsReloadReducer from "./slices/reportsReloadSlice";
import statusReducer from "./slices/statusSlice";
import recordsSlice from "./slices/records.slice";
import patientSlice from "./slices/patient.slice";
import thumbnailsSlice from "./slices/thumbanils.slice";
// Combine reducers
const rootReducer = combineReducers({
  reportsReload: reportsReloadReducer,
  status: statusReducer,
  record: recordsSlice,
  patient: patientSlice,
  thumbnail: thumbnailsSlice,

});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["status"],
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with typed reducers
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false,
    }),
});

const useAppDispatch = () => useDispatch<typeof store.dispatch>();

const persistor = persistStore(store);

export { persistor, store, useAppDispatch };
