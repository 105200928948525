import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import { API_URL } from "../../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { setShowStatus } from "../../../store/slices/statusSlice";
import { debounce } from "lodash";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import patientReportIcon from "../../../assets/patient-report-icon-blue.svg";
import Close from "../../../assets/remove-icon-red.svg";
import IProfileImageSmall from "../../../assets/profile-image-small.svg";
import moment from "moment";
import { CloseButton } from "../AddRecordsModal/AddRecords";
import closeIcon from "../../../assets/small-close-icon.svg";

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;
const ListView = styled.div`
  position: absolute;
  top: 100%;
  left: 76px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: calc(100% - 27px);
`;

const SelectedItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
  .close-icon {
    position: absolute;
    right: 25px;
  }
`;
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border-bottom: none;
  font-size: 13px;
  &:hover {
    background-color: rgb(235, 247, 251);
  }
`;

const ListItemPersonLogo = styled.div`
  font-size: 13px;
`;
const ListItemPersonDetail = styled.div`
  align-items: center;
  font-size: 13px;
  margin-left: 8px;
`;
const ListItemValue = styled.div`
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 238px;
`;

const CreateNewButton = styled.button`
  width: 50%;
  align-self: center;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  background-color: rgb(122, 186, 86);
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
`;
const SubmitButton = styled.button`
  width: 50%;
  margin: 10px auto;
  align-self: center;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  background-color: rgb(122, 186, 86);
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 14px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SelectedCard = styled.div`
  width: 100%;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  border: 1px solid #0099cc;
`;

const PatientCard = styled.div`
  padding: 20px 15px;
  box-shadow: 5px 1px 18px #ddd;
  width: 100%;
  align-self: center;
  min-height: 35px;
  align-items: center;
  background-color: #fff;
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
`;

const TextInput = styled.input`
  padding: 0 10px;
  width: calc(100% - 80px);
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  height: 45px;
  outline: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" id="search-icon" width="24" height="24" viewBox="0 0 24 24"><path id="Path_547" data-name="Path 547" d="M0,0H24V24H0Z" fill="none"/><path id="Path_548" data-name="Path 548" d="M18.031,16.617,22.314,20.9,20.9,22.314l-4.282-4.283a9,9,0,1,1,1.414-1.414Zm-2.006-.742a7,7,0,1,0-.15.15l.15-.15Z" fill="%23a6b8bb"/></svg>');
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 24px;
  padding-left: 43px;
`;
const TextInputFeild = styled.input`
  padding: 0 10px;
  width: calc(100% - 20px);
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  height: 38px;
  outline: none;
  margin-bottom: 10px;
  padding-left: 10px;
  margin-top: -10px;
`;

const LoaderIcon = styled.div`
  position: absolute;
  right: 31px;
  height: 20px;
  width: 20px;
`;

const Name = styled.div`
  font-size: 16px;
  color: #0099cc;
  margin-bottom: 4px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select,
  MuiInputBase-root {
    padding: 2px 10px 1px 10px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #0099cc;
    border-radius: 8px;
    height: 36px !important;
  }
  fieldset {
    border: none;
  }
`;

const AtlassianDropdown = ({
  type,
  setSelectedPatient,
  setSelectedDoctor,
  setSelectedDoctorItem = (data) => {
    return;
  },
  isAutoPopulate = { patient: false, doctor: false },
  autoPopulateData = {},
  setIsautoPopulate = (data) => {
    return;
  },
  setautoPopulateData = (data) => {
    return;
  },
}) => {
  const dispatch = useDispatch();
  const statusReducer = useSelector((state) => state.status);
  const [cookies] = useCookies();
  const [inputValue, setInputValue] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [showListView, setShowListView] = useState(false);
  const [inputError, setInputError] = useState({ mobile: "", email: "" });
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCodesArray, setCountryCodesArray] = useState([]);
  const [inputPatientItem, setInputPatientItem] = useState(null);
  const [inputDoctorItem, setInputDoctorItem] = useState(null);
  const [isDoctorItemSelected, setIsDoctorItemSelected] = useState(false);
  const [showAddPatientCard, setShowAddPatientCard] = useState(false);
  const [gender, setGender] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [apiError, setApiError] = useState("");

  const [isPatientAdded, setIsPatientAdded] = useState(false);

  useEffect(() => {
    const lastShareDoctore = localStorage.getItem("lastShareDoctorItem");
    const item = (lastShareDoctore && JSON.parse(lastShareDoctore)) || false;
    if (item) {
      setSelectedDoctor({ id: item?.patient_fhir_id });
      setSelectedDoctorItem(item);
      setInputDoctorItem(item);
      setIsDoctorItemSelected(true);
      setInputValue("");
    }
  }, []);

  useEffect(() => {
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
      }
    } catch (error) {
      console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  useEffect(() => {
    if (selectedCountry === "IN") {
      setCountryCode("+91");
      setCountryCodesArray([
        { code: "US", dial_code: "+1" },
        { code: "IN", dial_code: "+91" },
        { code: "GB", dial_code: "+44" },
      ]);
    } else if (selectedCountry === "GB") {
      setCountryCode("+44");
      setCountryCodesArray([{ code: "GB", dial_code: "+44" }]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (
      isAutoPopulate.patient &&
      autoPopulateData?.patient_data?.patient_fhir_id
    ) {
      setInputError("");
      const patientData = {
        value: autoPopulateData?.patient_data.phone_number,
        label: autoPopulateData?.patient_data.name
          ? autoPopulateData?.patient_data?.phone_number &&
            autoPopulateData?.patient_data.phone_number.slice(-10) +
              " (" +
              autoPopulateData?.patient_data.name +
              ")"
          : autoPopulateData?.patient_data.phone_number &&
            autoPopulateData?.patient_data.phone_number.slice(-10),
        name: autoPopulateData?.patient_data.name,
        email: autoPopulateData?.patient_data?.email,
        gender: autoPopulateData?.patient_data?.gender,
        dob: autoPopulateData?.patient_data?.dob,
        patient_fhir_id: autoPopulateData?.patient_data.patient_fhir_id,
      };
      setSelectedPatient({
        id: autoPopulateData?.patient_data.patient_fhir_id,
      });
      setInputPatientItem(patientData);
      setIsItemSelected(true);
      setInputValue("");
    } 
    else if(isAutoPopulate?.patient) {
      setInputError("");
      setPatientEmail(autoPopulateData?.patient_data?.email || patientEmail);
      setPatientMobile(autoPopulateData?.patient_data?.phone_number || patientMobile);
      setPatientName(autoPopulateData?.patient_data?.name);
      setGender(
        autoPopulateData?.patient_data?.gender?.charAt(0)?.toUpperCase() +
          autoPopulateData?.patient_data?.gender?.slice(1)
      );
    }
    if (isAutoPopulate.doctor && autoPopulateData?.doctor_data?.id) {
      const doctorData = {
        value: autoPopulateData?.doctor_data.phone,
        label: autoPopulateData?.doctor_data.name
          ? autoPopulateData?.doctor_data?.phone?.slice(-10) +
            " (" +
            autoPopulateData?.doctor_data.name +
            ")"
          : autoPopulateData?.doctor_data?.phone?.slice(-10),
        name: autoPopulateData?.doctor_data.name,
        email: autoPopulateData?.doctor_data?.email,
        gender: autoPopulateData?.doctor_data?.gender,
        dob: autoPopulateData?.doctor_data?.dob,
        patient_fhir_id: autoPopulateData?.doctor_data.id,
      };
      setSelectedDoctor({ id: autoPopulateData?.doctor_data.id });
      setSelectedDoctorItem(doctorData);
      setInputDoctorItem(doctorData);
      setIsDoctorItemSelected(true);
      setInputValue("");
    }
  }, [isAutoPopulate, autoPopulateData]);

  const closeAutoPopulate = () => {
    setautoPopulateData({ ...autoPopulateData, patient_data: {} });
    setIsautoPopulate({ ...isAutoPopulate, patient: false });
    setSelectedPatient(null);
    setInputError("");
  };

  const allCountryCodes = [
    { code: "IN", dial_code: "+91" },
    { code: "US", dial_code: "+1" },
    { code: "GB", dial_code: "+44" },
  ];

  const loadNumbers = useCallback(
    async (inputValue) => {
      if (!inputValue || inputValue.length <= 3 || isItemSelected) {
        setIsSearching(false);
        return;
      }
      setIsSearching(true);
      const isNumericInput = /^\d+$/.test(inputValue);
      const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
      const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);
      try {
        const url =
          type === "patient"
            ? `/patient?search=${encodeURIComponent(
                `${isNumericInput ? "" : ""}${inputValue}`
              )}`
            : isNumericInput && inputValue.length === 10
            ? `/search/practitioner?phone=${encodeURIComponent(inputValue)}`
            : (isAlphabeticInput && inputValue.length > 3) || isEmail
            ? `/search/practitioner?name=${encodeURIComponent(inputValue)}`
            : null;

        if (!url) return;

        const token = cookies["access_token"];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const method =
          url.includes("phone") || url.includes("name") ? "POST" : "GET";
        const payload =
          method === "POST"
            ? {
                search: isNumericInput ? `${""}${inputValue}` : inputValue,
              }
            : undefined;

        const response = await axios({
          method,
          url: `${API_URL}${url}`,
          data: payload,
          headers: config.headers,
        });

        let formattedData = [];
        if (type === "patient" && response?.data?.data?.length > 0) {
          console.log("response.data.data", response.data.data);
          formattedData = response.data.data.map((item) => ({
            value: item.phone_number,
            label: item.name
              ? item?.phone_number &&
                item.phone_number.slice(-10) + " (" + item.name + ")"
              : item.phone_number && item.phone_number.slice(-10),
            name: item.name,
            email: item?.email,
            gender: item?.gender,
            dob: item?.dob,
            patient_fhir_id: item.patient_fhir_id,
          }));
        } else if (type === "doctor" && response?.data?.result?.length > 0) {
          formattedData = response.data.result.map((item) => ({
            value: item.phone,
            label: item.name
              ? item?.phone?.slice(-10) + " (" + item.name + ")"
              : item?.phone?.slice(-10),
            name: item.name,
            email: item?.email,
            gender: item?.gender,
            dob: item?.dob,
            patient_fhir_id: item.id,
          }));
        }
        console.log("setSearchedData", formattedData);
        setSearchedData(formattedData);
      } catch (error) {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "An unexpected error occurred";
        dispatch(
          setShowStatus({
            message: errorMessage,
            severity: "error",
            autoHide: true,
            autoHideIn: 6000,
          })
        );
      } finally {
        setIsSearching(false);
        setShowListView(true);
      }
    },
    [type, cookies, isItemSelected, dispatch, setShowStatus, countryCode]
  );

  const debouncedLoadNumbers = useCallback(debounce(loadNumbers, 500), [
    loadNumbers,
  ]);

  useEffect(() => {
    if (inputValue.length > 3 && !isItemSelected) {
      debouncedLoadNumbers(inputValue);
    } else {
      setIsSearching(false);
    }
  }, [inputValue, debouncedLoadNumbers, isItemSelected]);

  const handleChange = (e) => {
    const value = e.target.value.trim(" ");
    setInputValue(value);
    setIsItemSelected(false);
    setIsDoctorItemSelected(false);
    setInputError("");
    if (!value) {
      setSearchedData(null);
      setIsSearching(false);
    }
  };

  const renderLoaderIcon = () => {
    return isSearching ? (
      <LoaderIcon>
        <ThreeDots
          height="20"
          width="20"
          color="#4fa94d"
          ariaLabel="loading-indicator"
        />
      </LoaderIcon>
    ) : null;
  };

  const onListItemSelected = (item) => {
    console.log("item", item);
    setShowListView(false);
    setInputValue(item.value);
    if (type === "patient") {
      setSelectedPatient({ id: item.patient_fhir_id });
      setInputPatientItem(item);
      setIsItemSelected(true);
      setInputValue("");
    } else {
      setSelectedDoctor({ id: item.patient_fhir_id });
      setSelectedDoctorItem(item);
      setInputDoctorItem(item);
      setIsDoctorItemSelected(true);
      setInputValue("");
    }
  };

  const renderSlectedtem = (item) => {
    return (
      <SelectedItem>
        <ListItemPersonLogo>
          <img src={IProfileImageSmall} height={40} />
        </ListItemPersonLogo>
        <ListItemPersonDetail>
          <Name>{item.name}</Name>
          <ListItemValue> {item.value}</ListItemValue>
          <ListItemValue> {item?.email}</ListItemValue>
          <span
            style={{
              fontSize: "14px",
              marginTop: "10px",
              textTransform: "capitalize",
            }}
          >
            {item?.gender}{" "}
            {item.dob &&
              ", " +
                moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                " Years"}
          </span>
        </ListItemPersonDetail>
        <img
          className="close-icon"
          src={Close}
          height={18}
          onClick={() => {
            if (type === "patient") {
              setInputPatientItem(null);
              setIsItemSelected(false);
              setInputValue("");
              setSelectedPatient(null);
              setSearchedData(null);
              setautoPopulateData({ ...autoPopulateData, patient_data: {} });
              setIsautoPopulate({ ...isAutoPopulate, patient: false });
            } else {
              setInputDoctorItem(null);
              setIsDoctorItemSelected(false);
              setSelectedDoctor(null);
              setSearchedData(null);
              setIsautoPopulate({ ...isAutoPopulate, doctor: false });
              setautoPopulateData({ ...autoPopulateData, doctor_data: {} });
            }
          }}
        />
      </SelectedItem>
    );
  };
  const renderListItem = () => {
    return searchedData?.map((item) => {
      console.log("seracgh ", item);
      return (
        <ListItem onClick={() => onListItemSelected(item)}>
          <ListItemPersonLogo>
            <img src={IProfileImageSmall} height={32} />
          </ListItemPersonLogo>
          <ListItemPersonDetail>
            <Name>{item.name}</Name>
            <ListItemValue> {item.value}</ListItemValue>
            <ListItemValue> {item?.email}</ListItemValue>

            <span
              style={{
                fontSize: "14px",
                marginTop: "10px",
                textTransform: "capitalize",
              }}
            >
              {item?.gender}
              {item.dob &&
                ", " +
                  moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                  " Years"}
            </span>
          </ListItemPersonDetail>
        </ListItem>
      );
    });
  };

  const handleCreate = async () => {
    const isNumericInput = /^\d+$/.test(patientMobile);
    const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(patientEmail);
    const numberReg = /^\d{10}$/;

    // if ( isNumericInput && !numberReg.test(patientMobile) && patientMobile.length !== 10) {
    //   setInputError({...inputError,mobile:"Invalid mobile number"});
    //   return;

    // }else{
    //   setInputError("")
    // }
    let isCountryCode =
      patientMobile && !/^\+[1-9]{1}[0-9]{3,14}$/.test(patientMobile);
    let isMobileNoLengthValid = patientMobile && patientMobile.length <= 11;

    if (
      (!isNumericInput || patientMobile.length !== 10) &&
      (isCountryCode || isMobileNoLengthValid)
    ) {
      setInputError({
        ...inputError,
        mobile: !/^\+[1-9]{1}[0-9]{3,14}$/.test(patientMobile)
          ? "Please include country code in mobile number"
          : "Invalid mobile number---",
      });
      return;
    } else {
      setInputError("");
    }

    if (patientEmail && !isEmail) {
      setInputError({ ...inputError, email: "Invalid email address" });
      return;
    } else {
      setInputError("");
    }
    try {
      setApiError("");
      const data = {
        name: patientName,
      };

      if (patientMobile) {
        data["contact_number"] = patientMobile
          ? `${
              isNumericInput && patientMobile.length == 10 ? "+91" : ""
            }${patientMobile}`
          : "";
      }

      if (patientEmail) {
        data["email"] = patientEmail;
      }
      if (gender) {
        data["gender"] = gender?.toLocaleLowerCase();
      }
      const token = cookies["access_token"];
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setIsSearching(true);
      const response = await axios.post(`${API_URL}/patient`, data, config);

      if (response?.data?.data?.patient_id) {
        dispatch(
          setShowStatus({
            message: "Patient added successfully",
            severity: "success",
          })
        );
        const patientId = response?.data?.data?.patient_id;
        const addedPatientData = {
          value: response?.data?.data?.phone_number,
          label: response?.data?.data?.name
            ? response?.data?.data?.phone_number &&
              response?.data?.data?.phone_number.slice(-10) +
                " (" +
                response?.data?.data?.name +
                ")"
            : response?.data?.data?.phone_number &&
              response?.data?.data?.phone_number.slice(-10),
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          gender: response?.data?.data?.gender,
          dob: response?.data?.data?.dob,
          patient_fhir_id: response?.data?.data?.patient_id,
        };

        onListItemSelected(addedPatientData);
        // setSelectedPatientId(patientId);
        // setSelectedPatient({ id: patientId });
        setPatientEmail("");
        setPatientMobile("");
        setPatientName("");
        setGender("");
        setShowListView(false);
        setShowAddPatientCard(false);
        // setIsPatientAdded(true);
      }
    } catch (error) {
      console.log(error);
      setApiError(error?.response?.data?.message || "somthing went wrong");
    } finally {
      setIsSearching(false);
    }
  };

  const handleChangeCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  const renderCountryCodeDropdown = () => {
    return (
      <StyledSelect
        value={countryCode}
        onChange={handleChangeCountryCode}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          width: "80px",
          marginRight: "8px",
        }}
      >
        {type === "patient" &&
          countryCodesArray.map((item) => (
            <MenuItem key={item.code} value={item.dial_code}>
              {item.dial_code}
            </MenuItem>
          ))}
        {type === "doctor" &&
          allCountryCodes.map((item) => (
            <MenuItem key={item.code} value={item.dial_code}>
              {item.dial_code}
            </MenuItem>
          ))}
      </StyledSelect>
    );
  };

  const renderCreateButton = () => {
    const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);
    const isNumericInput = /^\d+$/.test(inputValue);
    const isMobileNo = inputValue && /^\+[\d]+$/.test(inputValue);
    console.log("kjhjkhk", isNumericInput, isMobileNo);

    const onClickCreateBtn = () => {
      let result = isEmail
        ? setPatientEmail(inputValue)
        : isAlphabeticInput
        ? setPatientName(inputValue)
        : isNumericInput || isMobileNo
        ? setPatientMobile(inputValue)
        : setPatientName(inputValue);
      setShowAddPatientCard(true);
      return;
    };
    return (
      <CreateNewButton onClick={() => onClickCreateBtn()}>
        + Add New Patient
      </CreateNewButton>
    );
  };

  const renderList = () => {
    const isNumericInput = /^\d+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);

    if (isSearching) {
      return renderLoaderIcon();
    } else if (!isSearching && searchedData?.length > 0) {
      return renderListItem();
    } else if (
      inputValue &&
      !isSearching &&
      searchedData?.length <= 0 &&
      type !== "doctor"
    ) {
      return renderCreateButton();
    }
  };

  const renderPatientInfo = () => {
    return (
      <PatientCard>
        {patientMobile && (
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <strong>Mobile No:</strong> {countryCode} {patientMobile}{" "}
          </div>
        )}
        {patientEmail && (
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <strong>Email</strong> {patientEmail}{" "}
          </div>
        )}

        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <strong>Patient Name:</strong> {patientName}
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <strong>Patient Gender:</strong> {gender}
        </div>
      </PatientCard>
    );
  };

  return (
    <Container>
      <InputContainer>
        {type === "patient" &&
          (!isItemSelected ? (
            <>
              {/* {!showAddPatientCard && renderCountryCodeDropdown()} */}
              <TextInput
                type="text"
                onChange={handleChange}
                placeholder="Name, mobile number or e-mail"
                value={inputValue}
                style={
                  // inputError
                  //   ? { border: "1px solid red" }
                  {
                    display:
                      showAddPatientCard || isAutoPopulate.patient
                        ? "none"
                        : "block",
                  }
                }
              />
              <PatientCard
                style={{ display: !showAddPatientCard ? "none" : "block" }}
              >
                <strong>{/* {countryCode} {inputValue} */}</strong>
                <p>Patient Email</p>
                <TextInputFeild
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value.trim(" "))}
                  style={
                    inputError && inputError["email"]
                      ? { border: "1px solid red" }
                      : {
                          display: "block",
                        }
                  }
                />
                {inputError && inputError["email"] && (
                  <ErrorMessage>{inputError["email"]}</ErrorMessage>
                )}
                <p>
                  Mobile No.{" "}
                  <span style={{ color: "grey" }}>
                    {"(Please include country code)"}
                  </span>
                </p>

                <div style={{ display: "flex" }}>
                  {/* {renderCountryCodeDropdown()} */}

                  <TextInputFeild
                    value={patientMobile}
                    onChange={(e) => setPatientMobile(e.target.value.trim(" "))}
                    style={
                      inputError && inputError["mobile"]
                        ? { border: "1px solid red" }
                        : {
                            display: "block",
                            marginTop: "10px",
                          }
                    }
                    type="text"
                  />
                </div>

                {inputError && inputError["mobile"] && (
                  <ErrorMessage>{inputError["mobile"]}</ErrorMessage>
                )}

                <p>Patient Name</p>
                <TextInputFeild
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                />
                <p>Select Gender</p>
                <StyledSelect
                  sx={{ width: "100%" }}
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                  placeholder="Select Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </StyledSelect>
                <SubmitButton
                  onClick={handleCreate}
                  disabled={
                    (!patientEmail && !patientMobile) ||
                    (patientMobile && patientMobile.length < 10)
                      ? true
                      : false
                  }
                >
                  Submit
                </SubmitButton>
                {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
              </PatientCard>
              {/* || patientMobile && patientMobile.length < 10  */}
              {/* {isPatientAdded && <ListView>{renderPatientInfo()}</ListView>} */}
              {isAutoPopulate.patient && (
                <PatientCard style={{ display: "block" }}>
                  <div>
                    <CloseButton
                      src={closeIcon}
                      onClick={closeAutoPopulate}
                      style={{ position: "absolute", top: "5px", right: "1px" }}
                    />
                  </div>
                  <p>Patient Email</p>
                  <TextInputFeild
                    value={patientEmail}
                    onChange={(e) => setPatientEmail(e.target.value.trim(" "))}
                    style={
                      inputError && inputError["email"]
                        ? { border: "1px solid red" }
                        : {
                            display: "block",
                          }
                    }
                    disabled={autoPopulateData?.patient_data?.id}
                  />
                  {inputError && inputError["email"] && (
                    <ErrorMessage>{inputError["email"]}</ErrorMessage>
                  )}
                  <p>
                    Mobile No.{" "}
                    <span style={{ color: "grey" }}>
                      {"(Please include country code)"}
                    </span>
                  </p>
                  <TextInputFeild
                    value={patientMobile}
                    onChange={(e) => setPatientMobile(e.target.value.trim(" "))}
                    style={
                      inputError && inputError["mobile"]
                        ? { border: "1px solid red" }
                        : {
                            display: "block",
                          }
                    }
                    type="text"
                    disabled={autoPopulateData?.patient_data?.id}
                  />

                  {inputError && inputError["mobile"] && (
                    <ErrorMessage>{inputError["mobile"]}</ErrorMessage>
                  )}

                  <p>Patient Name</p>
                  <TextInputFeild
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                    disabled={autoPopulateData?.patient_data?.id}
                  />
                  <p>Select Gender</p>
                  <StyledSelect
                    sx={{ width: "100%" }}
                    value={gender}
                    onChange={(event) => setGender(event.target.value)}
                    placeholder="Select Gender"
                    disabled={autoPopulateData?.patient_data?.id}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </StyledSelect>
                  {!autoPopulateData?.patient_data?.id && (
                    <SubmitButton
                      onClick={handleCreate}
                      disabled={
                        (!patientEmail && !patientMobile) ||
                        (patientMobile && patientMobile.length < 10)
                          ? true
                          : false
                      }
                    >
                      Submit
                    </SubmitButton>
                  )}
                  {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
                </PatientCard>
              )}
            </>
          ) : (
            <SelectedCard>{renderSlectedtem(inputPatientItem)}</SelectedCard>
          ))}
        {type === "doctor" &&
          (!isDoctorItemSelected ? (
            <>
              {/* {renderCountryCodeDropdown()} */}
              <TextInput
                type="text"
                onChange={handleChange}
                placeholder="Name, mobile number or e-mail"
                value={inputValue}
                style={{
                  display:
                    isAutoPopulate.doctor && autoPopulateData?.doctor_data?.id
                      ? "none"
                      : "block",
                }}
              />

              {isAutoPopulate.doctor && autoPopulateData?.doctor_data?.id && (
                <PatientCard style={{ display: "block" }}>
                  <p>Doctor Email</p>
                  <TextInputFeild
                    value={autoPopulateData?.doctor_data?.email}
                    onChange={(e) => {
                      return;
                    }}
                    disabled
                    style={{
                      display: "block",
                    }}
                  />

                  <p>Mobile No.</p>
                  <TextInputFeild
                    value={autoPopulateData?.doctor_data?.phone}
                    onChange={(e) => {
                      return;
                    }}
                    disabled
                    style={{
                      display: "block",
                    }}
                    type="text"
                  />

                  <p>Doctor Name</p>
                  <TextInputFeild
                    value={autoPopulateData?.doctor_data?.name}
                    onChange={(e) => {
                      return;
                    }}
                    disabled
                    style={{
                      display: "block",
                    }}
                  />
                  {/* <p>Gender</p>
                  <StyledSelect
                    sx={{ width: "100%" }}
                    value={autoPopulateData?.doctor_data?.gender?.toUpperCase()}
                    onChange={(event) => {return;}}
                    placeholder="Select Gender"
                    disabled
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </StyledSelect> */}
                </PatientCard>
              )}
            </>
          ) : (
            <SelectedCard>{renderSlectedtem(inputDoctorItem)}</SelectedCard>
          ))}
        {isSearching && (
          <LoaderIcon>
            <ThreeDots
              height="20"
              width="20"
              color="#4fa94d"
              ariaLabel="loading-indicator"
            />
          </LoaderIcon>
        )}
      </InputContainer>
      {showListView && !showAddPatientCard && (
        <ListView>{renderList()}</ListView>
      )}
    </Container>
  );
};

export default AtlassianDropdown;
