import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import axios from "axios";

import { API_ENDPOINT, API_URL, COLOR_VARIANT, DEVICE, COOKIE_EXPIRY_DAYS } from "../../../utilities/constants";
import { HorizontalSep } from "../../styled/Gaps";
import profileIcon from "../../../assets/profile-icon.svg";
import downArrow from "../../../assets/down-arrow.svg";
import logoutIcon from "../../../assets/logout-icon.svg";
import { Box, Popover, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  setShowStatus,
  unsetShowStatus,
} from "../../../store/slices/statusSlice";

interface LoggedInUserProps { }

const UserDetails = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  @media ${DEVICE.laptop} {
    display: none;
  }
`;

const LoggedInUser: FunctionComponent<LoggedInUserProps> = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState(""); 

  useEffect(() => {
    if (!cookies["access_token"]) {
      navigate("/login");
    } else {
      fetchUserDetails();
    }
  }, []);

  useEffect(() => {
    console.log('Raw countryData cookie value:', cookies.countryData);
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.icon);
      }
    } catch (error) {
        console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  const fetchUserDetails = () => {
    setIsLoading(true);

    const token = cookies["access_token"];
    const url = API_ENDPOINT.PRACTITIONER_ROLE_INFO;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        // alert(JSON.stringify(response, null, 2));

        if (response && response.data && response.data.data) {
          setUserDetails(response.data.data);
          const maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
          setCookie("lab_id", response?.data?.data?.id, {
            path: "/",
            maxAge: maxAge,
          });
        } else {
          dispatch(
            setShowStatus({
              message: "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          setShowStatus({
            message: JSON.stringify(error,null,2) || "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const logoutHandler = () => {
    handleClose();
    dispatch(
      setShowStatus({
        message: "Logging you out...",
        severity: "warning",
      })
    );

    fetch(API_ENDPOINT.LOGOUT, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${cookies["access_token"]}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('Response data: ', JSON.stringify(response, null, 2));

        if (response.status == "success") {
          console.log("Logged out!");
          removeCookie("access_token");
          removeCookie("lab_id");
          removeCookie("countryData");
          removeCookie("direct_link");
          dispatch(unsetShowStatus());
          navigate("/login");
        } else {
          dispatch(
            setShowStatus({
              message: "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
        }
      })
      .catch((err) => {
        console.warn(err);
        dispatch(
          setShowStatus({
            message: "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
      });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <UserDetails onClick={handleClick}>
        <img
          src={selectedCountry}
          alt="selected_country"
          style={{ height: 20, marginRight: 20 }}
        />
        <img src={profileIcon} />
        <div
          style={{
            color: COLOR_VARIANT,
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              color: "#000000",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span>
              {userDetails && userDetails.lab ? userDetails.lab : "Lab"}
            </span>{" "}
            <HorizontalSep size={10} /> <img src={downArrow} height={5} />
          </div>
          {/* <div style={{ fontSize: "13px", color: "#000000" }}>
            {userDetails && userDetails?.name

              || "Lab User"}
          </div> */}
        </div>
      </UserDetails>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ color: "#808080", fontSize: "16px", borderRadius: "8px" }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${COLOR_VARIANT}`,
            padding: "5px 15px",
            cursor: "pointer",
          }}
          onClick={logoutHandler}
        >
          <img width={20} src={logoutIcon} />
          <Typography sx={{ p: 1, color: "#808080", fontSize: "16px" }}>
            Logout / Change Country
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default LoggedInUser;
