import React, { useRef, useState, useEffect } from "react";
import { Box, Modal, SelectChangeEvent } from "@mui/material";
import styled from "styled-components";
import closeIcon from "../../../assets/small-close-icon.svg";
import fileIcon from "../../../assets/file-icon.svg";
import addIcon from "../../../assets/add-icon.svg";
import UppyUploader from "../UppyUploadBox/UppyUploadBox";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { API_URL_UPLOAD_REPORTS } from "../../../utilities/constants";
import axios from "axios";
import SimpleLoader from "../../../components/common/Loaders/SimpleLoader";
import StatusDropdown, {
  Option,
} from "../../../components/common/StatusDropdown/StatusDropdown";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px;
  align-items: center;
  justify-content: flex-start;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.div`
  font-size: 24px;
  color: #0099cc;
  flex: 1;
  margin-left: 20px;
`;

export const AddButton = styled.button<{ disabledState: boolean }>`
  width: 148px;
  height: 44px;
  background: ${(props) => (props.disabledState ? "#A6B8BB" : "#7ABA56")};
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: ${(props) => (props.disabledState ? "inherit" : "pointer")};
  margin-right: 20px;
`;

export const ModalBody = styled.div`
  margin: 24px;
  margin-bottom: 0;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

const Section = styled.div`
  padding: 24px;
  margin-bottom: 30px;
  background-color: #f5fbfc;
  border-radius: 8px;
`;

const UploadBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const DragText = styled.div`
  font-size: 13px;
  color: #a6b8bb;
`;

const PdfText = styled.div`
  font-size: 20px;
  color: #0099cc;
`;

const FileName = styled.div`
  font-size: 16px;
  color: #000000;
  text-align: left;
  padding: 0 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  color: #000000;
  margin-bottom: 26px;
  flex: 1;
`;

const UploadBox = styled.div`
  min-width: 214px;
  max-width: 214px;
  height: 272px;
  border: 1px solid #d7eff7;
  background-color: #ebf7fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #a6b8bb;
  margin: 0 24px;
  cursor: pointer;
`;

const InnerUploadContainer = styled.div`
  width: 182px;
  height: 182px;
  border: 1px solid #d7eff7;
  background-color: #ebf7fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FileUploadBox = styled.div`
  position: relative;
  width: 214px;
  height: 272px;
  border: 1px solid #d7eff7;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 13px;
  margin-right: 24px;
  color: #000000;
`;

const CrossIcon = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: #cc0000;
  color: #ffffff;
  font-weight: 600;
  text-transform: lowercase;
  font-size: 16px;
  text-align: center;
  border-radius: 12px;
`;

const UploadButton = styled.button`
  width: 162px;
  height: 35px;
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 14px;
  cursor: pointer;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;

const dropdownOptions: Option[] = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];
interface AddRecordsProps {
  open: boolean;
  isAddRecordLoading: boolean;
  handleClose: () => void;
  handleSave?: (
    payload_scans: any,
    payload_report: any,
    token: string,
    mediaArray: any,
    uppyResponse: string
  ) => void;
}

const AddRecords: React.FC<AddRecordsProps> = ({
  open,
  handleClose,
  handleSave,
  isAddRecordLoading,
}) => {
  const [cookies] = useCookies();
  const [mediaArray, setMediaArray] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uppyResponse, setUppyResponse] = useState<any>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [patientId, setPatientId] = useState<any>("");
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    dropdownOptions[0].value
  );
  const [checked, setChecked] = useState<boolean>(false);
  const [isDocxFile, setIsDocxFile] = useState<boolean>(false);
  const directLink =
    cookies["direct_link"] && JSON.parse(cookies["direct_link"]);

  const handleScanChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent<string>, fileId?: number) => {
    const index = mediaArray.findIndex((item) => item.file_id === fileId);
    if (index !== -1) {
      const updatedItem = {
        ...mediaArray[index],
        status: event.target.value,
      };
      const updatedArray = [
        ...mediaArray.slice(0, index),
        updatedItem,
        ...mediaArray.slice(index + 1),
      ];
      setMediaArray([...updatedArray]);
    }
  };

  const handleDirectLinkChangeForScan = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
  };

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  useEffect(() => {
    if (open) {
      setMediaArray([]);
      setUppyResponse(null);
    }
  }, [open]);

  useEffect(() => {
    if (mediaArray) {
      const updatedMediaArray = mediaArray.map((item) => ({
        ...item,
      }));

      const isDifferent =
        JSON.stringify(updatedMediaArray) !== JSON.stringify(mediaArray);

      if (isDifferent) {
        setMediaArray(updatedMediaArray);
      }
    }
  }, [mediaArray]);

  useEffect(() => {
    if (mediaArray.length > 0 || uppyResponse) {
      setIsUploadDisabled(false);
    } else {
      setIsUploadDisabled(true);
    }
  }, [mediaArray, uppyResponse]);

  useEffect(() => {
    const id = getSearchParam("name");
    setPatientId(id);
  }, [searchParams]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const uploadFile = (file: File) => {
    setIsLoading(true);
    const token = cookies["access_token"];
    var formData = new FormData();
    formData.append("files", file);
    formData.append("file_type", "report");
    axios
      .post(API_URL_UPLOAD_REPORTS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response && response.data) {
          const data = response?.data?.result.map((item: any) => {
            return {
              ...item,
              status: "AVAILABLE",
            };
          });
          setMediaArray((prevMediaArray) => [...prevMediaArray, ...data]);
        } else {
          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const docxFormat= "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    const rtfFormat= "text/rtf"
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let type = files[i].type
        console.log("type",type);
        
        if(type === docxFormat || type === rtfFormat){
          setIsDocxFile(true)
        }else{
          setIsDocxFile(false)
        }
        uploadFile(files[i]);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const fileItem = event.dataTransfer.items[i];
        if (fileItem.kind === "file") {
          const file = fileItem.getAsFile();
          if (file) {
            uploadFile(file);
          }
        }
      }
    }
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSend = () => {
    if (isUploadDisabled) return;
    const token = cookies["access_token"];
    const lab_id = cookies["lab_id"];

    const payload_scans = {
      file_info: {
        folder_id: uppyResponse,
        status: selectedStatus,
        direct_viewer_link: checked,
      },
      patient_id: patientId,
    };

    const payload_report = {
      patient_id: patientId,
      report_info: mediaArray,
    };
    handleSave &&
      handleSave(
        payload_scans,
        payload_report,
        token,
        mediaArray,
        uppyResponse
      );
    setSelectedStatus(dropdownOptions[0].value);
    setChecked(false);
  };

  const handleCloseModal = () => {
    setSelectedStatus(dropdownOptions[0].value);
    setChecked(false);
    handleClose();
    setIsDocxFile(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <HeaderWrapper>
          <Header>Add Records</Header>
          <AddButton
            onClick={handleSend}
            disabled={isUploadDisabled || isAddRecordLoading}
            disabledState={isUploadDisabled || isAddRecordLoading}
          >
            Send All
          </AddButton>
          <CloseButton src={closeIcon} onClick={handleCloseModal} />
        </HeaderWrapper>
        <ModalBody>
          {isAddRecordLoading ? (
            <SimpleLoader text={"Adding record......"} />
          ) : (
            <>
              <Section>
                <SectionTitle>Reports</SectionTitle>
                <UploadBoxWrapper>
                  <UploadBox
                    onClick={openFilePicker}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    draggable
                  >
                    <img src={addIcon}></img>
                    <DragText>Drag files to add</DragText>
                  </UploadBox>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    accept=".pdf, .png, .jpg, .docx, .rtf"
                    multiple
                  />
                  {isLoading ? (
                    <SimpleLoader text={ !isDocxFile ? "": "Converting and uploading"} />
                  ) : mediaArray && mediaArray.length > 0 ? (
                    mediaArray?.map((row) => (
                      <Box display="flex" flexDirection="column">
                        <FileUploadBox key={row?.file_id}>
                          <CrossIcon
                            onClick={() => {
                              setMediaArray((prevArray) =>
                                prevArray.filter(
                                  (item) => item.file_id !== row.file_id
                                )
                              );
                            }}
                          >
                            X
                          </CrossIcon>
                          <InnerUploadContainer>
                            <img src={fileIcon}></img>
                            <PdfText>PDF</PdfText>
                          </InnerUploadContainer>
                          <FileName>{row.file_name}</FileName>
                        </FileUploadBox>
                        <Box mt={1} mb={1}>
                          <StatusDropdown
                            selectedStatus={row?.status}
                            handleChange={(e) => handleChange(e, row.file_id)}
                            dropdownOptions={dropdownOptions}
                          />
                        </Box>
                      </Box>
                    ))
                  ) : null}
                </UploadBoxWrapper>
              </Section>

              <Section>
                <SectionWrapper>
                  <SectionTitle>Scans</SectionTitle>
                  {/* <UploadButton>Add via Google Drive</UploadButton> */}
                </SectionWrapper>
                {/* <UploadBox>
                            <span>Drag files to add</span>
                        </UploadBox> */}
                <UppyUploader
                  onUploadComplete={setUppyResponse}
                  selectedStatus={selectedStatus}
                  handleChange={handleScanChange}
                  dropdownOptions={dropdownOptions}
                  handleDirectLinkChangeForScan={handleDirectLinkChangeForScan}
                  directLink={directLink}
                  checked={checked}
                />
              </Section>
            </>
          )}
        </ModalBody>
      </Box>
    </Modal>
  );
};

export default AddRecords;
